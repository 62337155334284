import { BsInstagram, BsTwitter, BsYoutube } from "react-icons/bs";
import { FaFacebook } from "react-icons/fa";
import { LiaLinkedin } from "react-icons/lia";
import { Link } from "react-router-dom";

export const Footer2 = () => {
  const items = [
    { label: "Features", link: "/", active: true },
    { label: "How It Works", link: "/" },
    { label: "Pricing", link: "/" },
    { label: "Testimonials", link: "/" },
    { label: "Contact Us", link: "/" },
  ];
  return (
    <div className="w-full bg-[#000] text-[#fff] md:px-24 px-4 py-8 gap-y-6 flex flex-col">
      <div className="flex w-full justify-between ">
        <div className="flex gap-x-4 items-center w-1/2">
          <img alt="logo" className="h-7 w-7 rounded-2xl" src={"/logo.svg"} />
          <span className="uppercase font-bold">Alertsify</span>
        </div>
        <div className="flex justify-end gap-x-2 gap-y-1 lg:gap-x-4 w-3/4 md:flex-row flex-col">
          {items.map((item) => (
            <Link
              className={`${
                item.active ? "text-[#FFFFFF]" : "text-[#FFFFFFB2]"
              } font-light md:text-base text-xs text-right md:text-center`}
              to={item.link}
            >
              {item.label}
            </Link>
          ))}
        </div>
      </div>
      <div className="pt-6 border-t border-[#292929] flex md:flex-row flex-col gap-y-4 justify-between">
        <p className="text-[#FFFFFFB2] text-xs md:flex-row flex-col gap-y-2 flex gap-x-4">
          <span className="">© 2024 Alertsify. All rights reserved.</span>
          <span className="flex gap-x-4">
            <span className="underline">Privacy Policy</span>
            <span className="underline">Terms of Service</span>
            <span className="underline">Cookies Settings</span>
          </span>
        </p>
        <div className="flex gap-x-4">
          <FaFacebook />
          <BsInstagram />
          <BsTwitter />
          <LiaLinkedin />
          <BsYoutube />
        </div>
      </div>
    </div>
  );
};
