// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slick-prev,
.slick-next {
  width: 50px; /* Adjust size */
  height: 50px; /* Adjust size */
}

.slick-prev:before,
.slick-next:before {
  font-size: 30px; /* Increase arrow icon size */
}

.slick-dots li button::before {
  color: #a4f720;
  width: 25%;
}

.slick-dots li.slick-active button:before {
  color: #a4f720;
}

.slick-dots {
  bottom: -55px;
}
`, "",{"version":3,"sources":["webpack://./src/customStyles/carousel.css"],"names":[],"mappings":"AAAA;;EAEE,WAAW,EAAE,gBAAgB;EAC7B,YAAY,EAAE,gBAAgB;AAChC;;AAEA;;EAEE,eAAe,EAAE,6BAA6B;AAChD;;AAEA;EACE,cAAc;EACd,UAAU;AACZ;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;AACf","sourcesContent":[".slick-prev,\n.slick-next {\n  width: 50px; /* Adjust size */\n  height: 50px; /* Adjust size */\n}\n\n.slick-prev:before,\n.slick-next:before {\n  font-size: 30px; /* Increase arrow icon size */\n}\n\n.slick-dots li button::before {\n  color: #a4f720;\n  width: 25%;\n}\n\n.slick-dots li.slick-active button:before {\n  color: #a4f720;\n}\n\n.slick-dots {\n  bottom: -55px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
