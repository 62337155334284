import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaDiscord } from "react-icons/fa";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import bgTop from "../../../assets/bgTopRectangle.png";
import flower from "../../../assets/flower.png";
import phoneFlower from "../../../assets/phoneFlower.png";
import { toast } from "react-toastify";
import axios from "axios";
import { userStore } from "../../../store/user";

const Index = () => {
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.hash);
  const { setUserData, loading, setLoading, userData } = userStore(
    (state) => state
  );
  const queryParamsObject = {};
  const gentradeToken = localStorage.getItem("gentradeToken");

  for (const [key, value] of params) {
    queryParamsObject[key] = value;
  }

  const handleDiscordAuthentication = async (access_token) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/discord/user/auth?serverId=${process.env.REACT_APP_DISCORD_SERVER_ID}`,
        { access_token }
      );
      localStorage.clear();
      setLoading(false);
      setUserData(response.data.user);
      localStorage.setItem("userId", response.data?.user?.id);
      toast(response.data.message);
      navigate("/dashboard");

      localStorage.setItem("gentradeToken", response.data?.user?.gentradeToken);
    } catch (err) {
      console.log(err);
      setLoading(false);
      toast(err.response.data, {
        type: "error",
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    if (queryParamsObject?.access_token) {
      setLoading(true);
      handleDiscordAuthentication(queryParamsObject?.access_token);
    }
  }, []);

  useEffect(() => {
    if (userData?.accessToken || gentradeToken) {
      navigate("/dashboard");
    }
  }, [userData]);

  return (
    <div
      className="absolute top-0 w-full h-screen max-h-screen bg-cover bg-center text-[14.74px] font-medium"
      style={{ backgroundImage: `url(${bgTop})` }}
    >
      <div
        className="
          pt-12 absolute top-1/2 transform -translate-y-[55%] left-1/2 -translate-x-1/2  z-30
          w-full px-2
          min-[340px]:w-[300px] min-[340px]:px-0
          sm:w-[50%]
          md:w-[42%]
          lg:w-[32%]
          xl:w-[30%] xl:ml-[12% xl:left- xl:-translate-x-
          min-[1470px]:w-[28%] min-[1470px]:ml-[15% 
          bg-[#232323] rounded-xl pb-12
        "
      >
        <div className="text-heading text-[42px] font-medium text-center">
          Welcome!
        </div>
        <div className="text-tertiary mt- ml-1 text-center">
          Log in to proceed further
        </div>
        <div className="flex flex-col-reverse justify-center gap-4 items-center mt-4">
          <div className="flex justify-center">
            <button 
                  onClick={() =>  window.open("https://whop.com/tradeproelite/?%3Fpass=prod_oP4t99lFAfErW")}
                  className="cursor-pointer flex gap-2 px-4 py-3 border border-tertiary text-tertiary justify-center w-[170px] mx-auto rounded-xl"
                >
                  Join Our Discord
                </button>
          </div>
          <div
            onClick={() =>
              (window.location.href = `https://discord.com/oauth2/authorize?client_id=${process.env.REACT_APP_DISCORD_CLIENT_ID}&response_type=token&redirect_uri=${window.location.href}&scope=identify+email+guilds`)
            }
            className="cursor-pointer flex gap-2 px-4 py-3 bg-[#282828 bg-tertiary justify-center w-[170px] mx-auto rounded-xl"
          >
            {loading ? (
              <div className="flex items-center justify-center gap-1 text-black">
                <div className="font-bold">Connecting..</div>
                <AiOutlineLoading3Quarters className="animate-spin" />{" "}
              </div>
            ) : (
              <>
                <FaDiscord
                  size={20}
                  className="text-[#5865F2 text-black my-auto"
                />  
                <div className="text-[#686B6E  text-black font-bold">Discord Login</div>
              </>
            )}
          </div>
        </div>
      </div>

      <img
        src={flower}
        alt="flower"
        className="
          absolute max-h-screen top-1/2 transform -translate-y-[45%] pointer-events-none
          hidden
          xl:bloc xl:right-[4%] xl:h-[80%]
          min-[1470px]:right-[7%] min-[1470px]:h-[85%] 
        "
      />

      <div
        className="
        fixed xl:hidde inset-0 z-10 w-full
        top-0 right-0 
      "
      >
        <img
          src={phoneFlower}
          alt="flower"
          fill
          className="
            absolute transform overflow-hidden 
            top-0 right-0 translate-x-1/2 -translate-y-1/2
          "
        />
      </div>

      <div
        className="
        fixed xl:hidde z-10 w-full
        bottom-0 left-0 
      "
      >
        <img
          src={phoneFlower}
          alt="flower"
          fill
          className="
            absolute transform overflow-hidden 
            bottom-0 left-0 -translate-x-1/2 translate-y-1/2
          "
        />
      </div>
    </div>
  );
};

export default Index;
