import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { userStore } from "../../../store/user";
import axios from "axios";

import tradier from "../../../assets/icons/dashboard/tradier.png";
import eTrade from "../../../assets/icons/dashboard/etrade.png";
import weBull from "../../../assets/icons/dashboard/webullLogo.png";
import info from "../../../assets/icons/info.png";
import close from "../../../assets/icons/cross.png";

import getToken from "../../../assets/Images/Dashboard/getToken.png";
import auth from "../../../assets/Images/Dashboard/auth.png";
import permission from "../../../assets/Images/Dashboard/permission.png";
import code from "../../../assets/Images/Dashboard/code.png";
import enterToken from "../../../assets/Images/Dashboard/enterToken.png";

import weBullDid from "../../../assets/Images/Dashboard/weBullDid.png"
import weBullManual from "../../../assets/Images/Dashboard/weBullManual.png"
import weBullDetails from "../../../assets/Images/Dashboard/weBullDetails.png"
import weBullLogin from "../../../assets/Images/Dashboard/weBullLogin.png"

import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

import Loader from "../../../components/Loader";

const OrderAuth = () => {
  let state = "";

  // TODO: Needs to come from server - oauth has implementation
  const scopes = "read, write, trade, market, stream";

  const [selectedService, setSelectedService] = useState("weBull");
  const [isLoading, setIsLoading] = useState(false);

  const [eTradeDialog, setETradeDialog] = useState(false);
  const [eTradeLoading, setETradeLoading] = useState(false);
  const [eTradeAccessToken, setETradeAccessToken] = useState("");

  const [weBullDialog, setWeBullDialog] = useState(false);
  const [weBullLoading, setWeBullLoading] = useState(false);
  const [instructionWeBull, setInstructionWeBull] = useState(false)
  const [weBullAccessToken, setWeBullAccessToken] = useState("");

  const [authConfig, setAuthConfig] = useState({});

  const [instruction, setInstruction] = useState(false);

  const {
    userData,
    btoData,
    hasBrokerTokens,
    setHasBrokerTokens,
    authBroker,
    setAuthBroker,
    savedConfig,
    setSavedConfig,
    selectedAccount,
    setSelectedAccount,
    accountList,
    setAccountList,
    setBrokerBalance,
    setIsPaperTrade,
    brokerBalance,
  } = userStore((state) => state);

  const updateAuthConfig = (e, fieldName) => {
    setAuthConfig({
      ...authConfig,
      [fieldName]: e.target.value,
    });
  };

  const handleTradierAuth = async () => {
    state = uuidv4();
    localStorage.setItem("oauth-state", state);
    console.log(state);
    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/oauth/state`, {
      apiSource: "Tradier", // TODO: make dynamic
      state: state,
      userId: localStorage.getItem("userId"),
    });
    const callbackUrl = `${window.location.href}`;
    const target = `https://api.tradier.com/v1/oauth/authorize?client_id=${process.env.REACT_APP_TRADIER_CLIENT_ID}&scope=${scopes}&state=${state}&redirect_uri=${callbackUrl}`;
    window.location.href = target;
  };

  const handleRequestToken = async (e) => {
    setIsLoading(true)
    let config;

    if(!savedConfig?.eTrade) {
      if(!authConfig?.consumerKey){
        setIsLoading(false)
        return toast.error("Enter consumer key")
      } else if(!authConfig?.consumerKey){
        setIsLoading(false)
        return toast.error("Enter consumer sercret")
      }
      config = {
        consumerKey: authConfig?.consumerKey,
        consumerSecret: authConfig?.consumerSecret
      }
    }
    
    await axios.get(`${process.env.REACT_APP_API_BASE_URL}/oauth/request_token`, {
      params: {
        userId: localStorage.getItem("userId"),
        ...config
      }
    })
      .then((res) => {
        console.log(res.data)
        window.open(res.data?.redirectUrl, "_blank")
      })
      .catch((error) => {
        console.log(error)
        
        if(error.respon.status === 417){
          return toast.error("Invalid Credentials")
        } else {
          return toast.error("Something went wrong. Please try again later.")
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleEtradeAuth = async (e) => {
    if (eTradeAccessToken.length == 0) {
      return toast.error("Enter Access Token!");
    }
    setETradeLoading(true);

    await axios
      .get(
        `${
          process.env.REACT_APP_API_BASE_URL
        }/oauth/verifier?code=${eTradeAccessToken}&userId=${localStorage.getItem(
          "userId"
        )}`
      )
      .then(async (res) => {
        if (res.status === 200) {
          setHasBrokerTokens(true);
          setAuthBroker("Etrade");
          toast("Successfully authenticated", { type: "success" });
          setETradeDialog(false);
          setAuthConfig({});
          setETradeLoading(false);

          await axios
            .get(
              `${process.env.REACT_APP_API_BASE_URL}/broker/accounts/` +
                localStorage.getItem("userId")
            )
            .then((res) => {
              console.log(res.data);
              setAccountList(res.data.accountList);
              setSelectedAccount(res.data.accountList[0]?.accountIdKey);
            })
            .catch((err) => console.error(err));
        } else {
          toast("Authentication Failed", { type: "error" });
        }
      })
      .catch((error) => {
        console.log(error);
        if(error.response.status === 401){
          return toast.error("Invalid Access Token")
        } else {
          return toast.error("Something went wrong. Please try again later")
        }
      });

    // const target = `${process.env.REACT_APP_API_BASE_URL}/oauth/request_token`;

    // window.open(target, "_blank");
  };

  const checkUserConfig = async (serviceName) => {
    if(savedConfig?.[serviceName] !== undefined){
      return;
    }

    setIsLoading(true);

    let brokerId;

    if (serviceName === "weBull") {
      brokerId = "00acd39f-c7f5-402e-92fd-a62175e6d696";
      setWeBullDialog(true);
    } else if (serviceName === "eTrade"){
      brokerId = "ab5ccb68-310c-4475-9631-97bfe4e1448e"
      setETradeDialog(true);
    } else if (serviceName === "tradier"){
      brokerId = "ef6e8698-e271-49b8-ad71-edb50a359c1d"
    }
    // await axios
    //     .get(
    //       `https://backend-ums-stage-797b8d335ec5.herokuapp.com/broker/all`
    //     )
    //     .then((response) => {
    //       console.log(response.data);
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });

    if (savedConfig?.[serviceName] === undefined) {
      await axios
        .get(
          `https://backend-ums-stage-797b8d335ec5.herokuapp.com/broker/userConfig/${localStorage.getItem(
            "userId"
          )}/${brokerId}`
        )
        .then((response) => {
          console.log(response, "auth success");
          setSavedConfig({ ...savedConfig, [serviceName]: true });
        })
        .catch((error) => {
          console.log(error);
          setSavedConfig({ ...savedConfig, [serviceName]: false });
        });
    }
    setIsLoading(false);
  };

  const handleWeBullAuth = async (e) => {
    let config; 

    if(authConfig?.weBull){
      // config = {
      //   securityDid: authConfig?.security,
      // };
    } else {
      config = {
        loginEmail: authConfig?.email,
        loginPassword: authConfig?.password,
        tradingPin: authConfig?.pin,
        securityDid: authConfig?.security,
      };
    }

    if (
      !authConfig?.weBull &&
      (config?.loginEmail?.length === 0 ||
        config?.loginPassword?.length === 0 ||
        config?.tradingPin?.length === 0 ||
        config?.securityDid?.length === 0)
    ) {
      return toast.error("Enter all the details to proceed!");
    } 
    // else if (config?.securityDid?.length === 0) {
    //   return toast.error("Enter Security DID to proceed!");
    // }

    setWeBullLoading(true);

    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/oauth/webull/login`, {
        userId: localStorage.getItem("userId"),
        ...config,
      })
      .then(async (res) => {
        if (res.status === 200) {
          setHasBrokerTokens(true);
          setAuthBroker("WeBull");
          toast("Successfully authenticated", { type: "success" });
          setWeBullDialog(false);
          setAuthConfig({});
          setWeBullLoading(false);
          setSavedConfig({...savedConfig, weBull: true})
          await axios
            .get(
              `${process.env.REACT_APP_API_BASE_URL}/broker/accounts/` +
                localStorage.getItem("userId")
            )
            .then((res) => {
              console.log(res.data);
              setAccountList(res.data.accountList);
              setSelectedAccount(res.data.accountList[0]?.accountIdKey);
            })
            .catch((err) => console.error(err));
        } else {
          toast("Authentication Failed", { type: "error" });
        }
      })
      .catch((error) => {
        console.log(error);
        
        if(error.response.status === 417){
          return toast.error("Invalid Security DID")
        }
        toast.error("Something went wrong. Please try again later")
        setWeBullLoading(false);
      });

    // const target = `${process.env.REACT_APP_API_BASE_URL}/oauth/request_token`;

    // window.open(target, "_blank");
  };

  const handleBrokerLogout = async () => {
    setIsLoading(true);

    await axios
      .delete(
        "https://backend-ums-stage-797b8d335ec5.herokuapp.com/oauth/order_auth/" +
          localStorage.getItem("userId")
      )
      .then((response) => {
        console.log(response.data);
        setHasBrokerTokens(false);
        setAuthBroker(null);
        setAccountList(null);
        setSelectedAccount(null);
        setBrokerBalance(null);
        setIsPaperTrade(true);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error.data);
        setIsLoading(false);
      });
  };

  return (
    <>
      {(eTradeDialog || weBullDialog || instruction) && (
        <div
          onClick={() => {
            setETradeDialog(false);
            setWeBullDialog(false);
            setInstruction(false);
            setInstructionWeBull(false);
            setAuthConfig({});
          }}
          className={`fixed h-screen w-full bg-black bg-opacity-60 ${
            instruction ? "z-[45]" : "z-30"
          }  top-0 left-0
            ${eTradeLoading ? "pointer-events-none" : ""}
          `}
        />
      )}

      {eTradeDialog && (
        <div className="w-[40%] fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-40 bg-[#191919] rounded-lg py-6 px-6">
          <div className="flex justify-between items-center text-2xl font-semibold text-white">
            ETrade Login
            <img
              src={info}
              onClick={() => setInstruction(true)}
              className="size-5 cursor-pointer"
            />
          </div>
          <div className="w-full h-[1px] mt-4 bg-white opacity-20" />
          <div className="text-white mt-4 text-center">
            Login on the ETrade platform to obtain the access token{" "}
          </div>

          {!savedConfig?.eTrade && (
                <div className="flex mt-8 lg:mt-6 text-white flex-col md:flex-ro w gap-4">
                  <div className="text-white text-semibold text-center underline underline-offset-4">
                    Set One Time Config for ETrade
                  </div>

                  <div className="flex items-center gap-2 mt-2">
                    <label className="w-20">Consumer Key:</label>
                    <input
                      type="text"
                      value={authConfig?.consumerKey || ""}
                      onChange={(e) => updateAuthConfig(e, "consumerKey")}
                      className="bg-[#424242] px-2 py-1 rounded-md w-[40%]"
                    />
                  </div>

                  <div className="flex items-center gap-2 mt-2">
                    <label className="w-20">Consumer Secret:</label>
                    <input
                      type="text"
                      value={authConfig?.consumerSecret || ""}
                      onChange={(e) => updateAuthConfig(e, "consumerSecret")}
                      className="bg-[#424242] px-2 py-1 rounded-md w-[40%]"
                    />
                  </div>

                  <div className="text-tertiary mt-4 text-sm">
                    *The above details will be stored for future use. You can
                    change them fron the profile page{" "}
                  </div>
                  
                </div>
              )}

              <div className="w-full flex justify-center mt-6">
                <button
                  className="border-2 border-tertiary border-opacity- 20 rounded-md mx-auto px-4 py-2 text-white font-semibold text-center flex items-center gap-2"
                  onClick={() => handleRequestToken()}
                >
                  <img src={eTrade} className="size-6"/>
                  Get Access Token
                </button> 
              </div>

          <div className="w-full h-[1px] mt-6 bg-white opacity-15" />
          <div className="flex mt-8 lg:mt-6 text-white flex-col md:flex-ro w gap-4">
            <div className="flex items-center gap-2">
              <label className="w-36">Enter Access Token:</label>
              <input
                value={eTradeAccessToken}
                onChange={(e) => setETradeAccessToken(e.target.value)}
                className="bg-[#424242] px-2 py-1 rounded-md"
              />
            </div>
            <div className="text-red-600 text-sm">
              NOTE: Logging in will automatically log you out of other broker
              services
            </div>
          </div>
          <div className="flex justify-end gap-4 mt-6">
            <button
              disabled={eTradeLoading}
              onClick={() => {
                setETradeDialog(false);

                setAuthConfig({});
              }}
              className="disabled:bg-[#737B7B]  bg-red-700 text-white py-1 w-28 rounded-md "
            >
              Cancel
            </button>
            <button
              disabled={eTradeLoading}
              onClick={handleEtradeAuth}
              className="border border-tertiary [#737B7B] py-1 w-28 text-black bg-tertiary [#5F6868] rounded-md font-semibold"
            >
              Submit
            </button>
          </div>
        </div>
      )}

      {instruction && (
        <div className="w-[60%] fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[46] text-[#9b9b9b] bg-[#191919] rounded-lg py-6 px-6 h-[750px]">
          <div className="flex justify-between items-center text-2xl font-semibold text-white">
            How to Login to ETrade?
            <img
              src={close}
              onClick={() => setInstruction(false)}
              className="size-5 cursor-pointer"
            />
          </div>
          <div className="w-full h-[1px] mt-4 bg-white opacity-20" />

          <div className="overflow-y-scroll scrollbar h-[650px] mt-4">
            <div className="">
              <div className="text-xl font-semibold">Step 1</div>
              <img
                src={getToken}
                className="mt-4 mx-auto border border-[#6E6E6E] rounded-xl"
              />
              <div className="mt-4">
                Select the "Get Access Token" option and head over to ETrade.
              </div>
            </div>

            <div className="w-full h-[1px] mt-4 bg-white opacity-20" />

            <div className="mt-4">
              <div className="text-xl font-semibold">Step 2</div>
              <img src={auth} className="mt-4 h-64 mx-auto" />
              <div className="mt-4">
                Log in to ETrades with the required account and once logged in,
                close this tab and head back to Alertisfy.
              </div>
            </div>

            <div className="w-full h-[1px] mt-4 bg-white opacity-20" />

            <div className="mt-4">
              <div className="text-xl font-semibold">Step 3</div>
              <img
                src={getToken}
                className="mt-4 mx-auto border border-[#6E6E6E] rounded-xl"
              />
              <div className="mt-2">
                Select the "Get Access Token" option again.
              </div>
            </div>

            <div className="w-full h-[1px] mt-4 bg-white opacity-20" />

            <div className="mt-4">
              <div className="text-xl font-semibold">Step 4</div>
              <img src={permission} className="mt-4 h-64 mx-auto" />
              <img src={code} className="mt-4 h-64 mx-auto" />
              <div className="mt-2">
                Accept the platform conditions, copy the code which comes after
                and head back over to Alertsify.
              </div>
            </div>

            <div className="w-full h-[1px] mt-4 bg-white opacity-20" />

            <div className="mt-4">
              <div className="text-xl font-semibold">Step 5</div>
              <img
                src={enterToken}
                className="mt-4 h-36 mx-auto border border-[#6E6E6E] rounded-xl"
              />
              <div className="mt-2">
                Enter the access token and submit auth request.
              </div>
            </div>
          </div>
        </div>
      )}

      
      {instructionWeBull && (
        <div className="w-[60%] fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[46] text-[#9b9b9b] bg-[#191919] rounded-lg py-6 px-6 h-[750px]">
          <div className="flex justify-between items-center text-2xl font-semibold text-white">
            How to Login to WeBull?
            <img src={close} onClick={() => setInstructionWeBull(false)} className="size-5 cursor-pointer"/>
          </div>
          <div className="w-full h-[1px] mt-4 bg-white opacity-20" />

          <div className="overflow-y-scroll scrollbar h-[650px] mt-4  pb-8">

            <div className="">
              <div className="text-xl font-semibold">Step 1</div>
              {/* <img src={getToken} className="mt-4 mx-auto border border-[#6E6E6E] rounded-xl" />  */}
              <div className="mt-4">Visit <a href="https://app.webull.com/" target="_blank" className="text-blue-500 hover:underline underline-offset-2"> https://app.webull.com/ </a> and log in.</div>
            </div>

            <div className="w-full h-[1px] mt-4 bg-white opacity-20" />

            <div className="mt-4">
              <div className="text-xl font-semibold">Step 2: Obtain your Refresh Token/DID</div>
            </div>

            {/* <div className="w-full h-[1px] mt-4 bg-white opacity-20" /> */}
            
            <div className="mt-4">
              <div className="text-[17px] underline underline-offset-2 font-semibold">Google Chrome or Microsoft Edge via Javascript</div>
              <div className="pl-4">
                {/* <img src={getToken} className="mt-4 mx-auto border border-[#6E6E6E] rounded-xl" />  */}
                <div className="mt-2">a{") "} 
                  Copy the following into your browser address bar 
                  <div className="bg-black my-2 p-2 w-[80%] mx-auto text-center rounded-lg text-white">
                    javascript:alert( document.cookie.match(new RegExp('(^| )web_did=([^;]+)'))[2] );
                  </div>
                  Note that your browser may remove the javascript: prefix. You may have to manually re-type that part.</div>
                <div className="mt-2">b{") "}Copy the string that's displayed. That is your 'Refresh Token' or 'did</div>
                <img src={weBullDid} className="mt-4 h-32 mx-auto"/>
              </div>
            </div>

            
            <div className="mt-4">
              <div className="text-[17px] underline underline-offset-2 font-semibold">Other Browsers or manually</div>
              <div className="pl-4">
                <div className="mt-2">a{") "}Click on the "trade" tab in the top menu. It should open up a new page.</div>
                <div className="mt-2">b{") "}Login from this new page. You should see an image verification and may need to verify with a phone number.</div>
                <div className="mt-2">c{") "}Open "Inspector", go to "Network". You should refresh the page once you are done logging in.</div>
                <div className="mt-2">d{") "}You can find your Access Token and Refresh Token in many of files listed. Just click on one (such as 'v2', 'get', 'all', or 'config')</div>
                <img src={weBullManual} className="mt-4 h-32 mx-auto"/>
                <div className="mt-2">e{") "}Look in the "Headers" tab under the "Request Headers" heading for the "did" entry:</div>
                <img src={weBullDetails} className="mt-4 h-96 mx-auto"/>
              </div>
            </div>

            <div className="w-full h-[1px] mt-4 bg-white opacity-20" />
            
            <div className="mt-4">
              <div className="text-xl font-semibold">Step 3</div>
            </div>

            <div className="mt-4">
              <img src={weBullLogin} className="mt-4 h-52 mx-auto border border-[#6E6E6E] rounded-xl" /> 
              <div className="mt-2">
                Head back to Alertsify and submit the did to complete the authentication process
              </div>
            </div>

            {/* <div className="w-full h-[1px] mt-4 bg-white opacity-20" /> */}
            
          </div>
        </div>
      )}

      {weBullDialog && (
        <div className="w-[40%] fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-40 bg-[#191919] rounded-lg py-6 px-6">
          <div className="flex justify-between items-center text-2xl font-semibold text-white">
            <div className="flex gap-2 items-center">
              {/* <img src={weBull} className="size-8" /> */}
              WeBull Login
            </div>
            <img
              src={info}
              onClick={() => setInstructionWeBull(true)}
              className="size-5 cursor-pointer"
            />
          </div>

          <div className="w-full h-[1px] mt-6 bg-white opacity-15" />

          {isLoading ? (
            <div className="flex items-center justify-center min-h-[435px]">
              <Loader />
            </div>
          ) : (
            <>
              {!savedConfig?.weBull && (
                <div className="flex mt-8 lg:mt-6 text-white flex-col md:flex-ro w gap-4">
                  <div className="text-white text-semibold text-center underline underline-offset-4">
                    Set One Time Config for WeBull
                  </div>

                  <div className="flex items-center gap-2 mt-2">
                    <label className="w-20">Email:</label>
                    <input
                      type="email"
                      value={authConfig?.email || ""}
                      onChange={(e) => updateAuthConfig(e, "email")}
                      className="bg-[#424242] px-2 py-1 rounded-md w-[40%]"
                    />
                  </div>

                  <div className="flex items-center gap-2 mt-2">
                    <label className="w-20">Password:</label>
                    <input
                      type="password"
                      value={authConfig?.password || ""}
                      onChange={(e) => updateAuthConfig(e, "password")}
                      className="bg-[#424242] px-2 py-1 rounded-md w-[40%]"
                    />
                  </div>

                  <div className="flex items-center gap-2 mt-2">
                    <label className="w-20">Trading Pin:</label>
                    <input
                      value={authConfig?.pin || ""}
                      onChange={(e) => updateAuthConfig(e, "pin")}
                      className="bg-[#424242] px-2 py-1 rounded-md w-[40%]"
                    />
                  </div>

                  <div className="text-tertiary mt-4 text-sm">
                    *The above details will be stored for future use. You can
                    change them fron the profile page{" "}
                  </div>
                  
                  <div className="w-full h-[1px] mt-2 bg-white opacity-15 " />

                  <div className="flex items-center gap-2 mt-6 text-white">
                    <label className="w-24">Security DID:</label>
                    <input
                      value={authConfig?.security || ""}
                      onChange={(e) => updateAuthConfig(e, "security")}
                      className="bg-[#424242] px-2 py-1 rounded-md w-[40%]"
                    />
                  </div>
                </div>
              )}

              <div className="text-white text-lg mt-4">
                Are you sure you want to login to WeBull?{" "}
              </div>
              <div className="text-red-600 text-sm mt-4">
                NOTE: Logging in will automatically log you out of other
                broker services
              </div>
              <div className="flex justify-end gap-4 mt-6">
                <button
                  disabled={eTradeLoading}
                  onClick={() => {
                    setWeBullDialog(false);
                    setAuthConfig({});
                  }}
                  className="disabled:bg-[#737B7B]  bg-red-700 text-white py-1 w-28 rounded-md "
                >
                  Cancel
                </button>
                <button
                  disabled={weBullLoading}
                  onClick={handleWeBullAuth}
                  className="border border-tertiary [#737B7B] py-1 w-28 text-black bg-tertiary [#5F6868] rounded-md font-semibold"
                >
                  Login
                </button>
              </div>
            </>
          )}
        </div>
      )}

      {hasBrokerTokens ? (
        <div className="bg-[#202020] p-6 rounded-[32px] md:h-1/2 border border-[#6c6c6c]">
          <div className="flex gap-2 justify-between items-center text-[#A1A1A1] w-full">
            <div className="text-opacity-20 text-xl ">Order Placement</div>
            <div className="flex items-center h-[23px]">
              <div className="">{authBroker ? authBroker : ""}:</div>
              <div className="ml-2 mr-1 mt-[2px] rounded-full size-2 bg-[#28A263]" />
              <div className="text-[#28A263]">Connected</div>
            </div>
          </div>
          <div className="mt-10 flex items-center justify-between">
            <div className="text-[#9b9b9b] text-center">
              <div className="text-[32px] md:text-[42px] leading-none font-bold">
                {brokerBalance?.totalEquity !== undefined ? (
                  "$" + Number(brokerBalance?.totalEquity)?.toFixed(2)
                ) : (
                  <Skeleton
                    baseColor="#424242"
                    highlightColor="#202020"
                    width="80px"
                  />
                )}
              </div>
              <div className="text-sm md:text-base mt-1">Total Value</div>
            </div>
            <div className="text-[#9b9b9b] text-center">
              <div className="text-[32px] md:text-[42px] leading-none font-bold">
                {brokerBalance?.availableFunds !== undefined ? (
                  "$" + Number(brokerBalance?.availableFunds)?.toFixed(2)
                ) : (
                  <Skeleton
                    baseColor="#424242"
                    highlightColor="#202020"
                    width="80px"
                  />
                )}
              </div>
              <div className="text-sm md:text-base mt-1">Available Funds</div>
            </div>
            <div className="text-[#9b9b9b] text-center">
              <div className="text-[32px] md:text-[42px] leading-none font-bold">
                {brokerBalance?.unsettledFunds !== undefined ? (
                  "$" + Number(brokerBalance?.unsettledFunds)?.toFixed(2)
                ) : (
                  <Skeleton
                    baseColor="#424242"
                    highlightColor="#202020"
                    width="80px"
                  />
                )}
              </div>
              <div className="text-sm md:text-base mt-1">Unsettled Funds</div>
            </div>
          </div>
          <div className="flex justify-end mt-4 md:mt-6">
            <button
              disabled={isLoading}
              className="px-4 py-2 bg-red-600 text-white font-medium rounded-lg"
              onClick={handleBrokerLogout}
            >
              Logout
            </button>
          </div>
        </div>
      ) : (
        <div className="flex gap-2 bg-[#202020] p-6 rounded-[32px] h-1/2">
          <div className="w-fit h-full scroll-y-auto scrollbar">
            <img
              src={weBull}
              onClick={() => {
                setSelectedService("weBull");
              }}
              className={`size-10 p-1 mt-1 rounded-lg cursor-pointer ${
                selectedService === "weBull"
                  ? "border-2 border-tertiary [#424242]"
                  : ""
              }`}
            />
            {/* <div className="w-full h-[2px] px-2 rounded-full bg-[#424242]" />
            <img
              src={eTrade}
              onClick={() => setSelectedService("eTrade")}
              className={`size-10 p-1 mt-1 rounded-lg cursor-pointer ${
                selectedService === "eTrade"
                  ? "border-2 border-tertiary [#424242]"
                  : ""
              }`}
            />
            <div className="w-full h-[2px] px-2 rounded-full bg-[#424242]" />
            <img
              src={tradier}
              onClick={() => setSelectedService("tradier")}
              className={`size-10 p-1 rounded-lg cursor-pointer ${
                selectedService === "tradier"
                  ? "border-2 border-tertiary [#424242]"
                  : ""
              }`}
            /> */}
          </div>
          <div className="w-[2px] bg-[#424242] h-full" />
          <div className="w-full">
            <div className="flex justify-between items-center">
              <div className="text-[#A1A1A1] font-semibold text-xl ml-2">
                Order Placement
              </div>
              <div className="flex items-center h-[23px]">
                <div className="text-[#A1A1A1]">Brokerage Account :</div>
                <div className="ml-2 mr-1 mt-[2px] rounded-full size-2 bg-[#F24E1E]" />
                <div className="text-[#F24E1E]">Not Connected</div>
              </div>
            </div>
            <div className="flex flex-grow h-full justify-center items-center pt-5 pb-6">
              {(() => {
                switch (selectedService) {
                  case "tradier":
                    return (
                      <>
                        {/* Tradier Auth */}
                        <div className="flex items-center gap-2 border border-[#6E6E6E] rounded-lg py-2 px-3">
                          <img src={tradier} className="size-6" />
                          <button
                            onClick={handleTradierAuth}
                            className="text-tertiary"
                          >
                            Sign In with Tradier
                          </button>
                        </div>
                      </>
                    );
                  case "eTrade":
                    return (
                      <>
                        {/* eTrade Auth */}
                        <div className="flex items-center gap-2 border border-[#6E6E6E] rounded-lg py-2 px-3">
                          <img src={eTrade} className="size-6" />
                          <button
                            onClick={() => {
                              checkUserConfig("eTrade");
                              setETradeDialog(true);
                              setAuthConfig({});
                            }}
                            className="text-tertiary"
                          >
                            Sign In with ETrade
                          </button>
                        </div>
                      </>
                    );

                  default:
                    return (
                      <>
                        {/* weBull Auth */}
                        <div className="flex items-center gap-2 border border-[#6E6E6E] rounded-lg py-2 px-3">
                          <img src={weBull} className="size-6" />
                          <button
                            onClick={() => {
                              checkUserConfig("weBull");
                              setWeBullDialog(true);
                              setAuthConfig({});
                            }}
                            className="text-tertiary"
                          >
                            Sign In with WeBull
                          </button>
                        </div>
                      </>
                    );
                }
              })()}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OrderAuth;
