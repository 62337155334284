import gold from "../../assets/Images/Medals/gold.png";
import bronze from "../../assets/Images/Medals/bronze.png";
import silver from "../../assets/Images/Medals/silver.png";
import honour from "../../assets/Images/Medals/honour.png";
import Loader from "../../components/Loader";

export const LandingLeaderBoard = ({
  leaderboardCategory,
  paperMonthLeaderboard,
  paperDayLeaderboard,
  realMonthLeaderboard,
  realDayLeaderboard,
  tradeType,
  setTradeType,
  setLeaderBoardCategory,
  isLoading,
}) => {
  return (
    <div
      id="leaderboard"
      className="bg-[#0A0A0A] rounded-r-[24px] text-[16px] md:text-base"
    >
      <div className="bg- tertiary pt-2 md:pt-4">
        <div className="text-[14px] md:text-base border-2 border-tertiary rounded-full w-fit mx-auto flex gap- 2 justify-center bg- tertiary px- 4 font-semibold">
          <div
            className={`w- [80px] text-center py-1  px-4 rounded-l-full  ${
              tradeType === "realTrades"
                ? "text-black bg-tertiary"
                : " text-white"
            }  cursor-pointer`}
            onClick={() => setTradeType("realTrades")}
          >
            Real Trades
          </div>
          <div
            className={`w- [80px] text-center py-1  px-3 rounded-r-full ${
              tradeType === "paperTrades"
                ? "text-black bg-tertiary"
                : " text-white"
            } cursor-pointer`}
            onClick={() => setTradeType("paperTrades")}
          >
            Paper Trades
          </div>
        </div>
      </div>
      <div className="text-[14px] md:text-base flex gap-2 justify-center bg- tertiary p-2 md:p-4 font-semibold">
        <div
          className={`w-[80px] text-center py-[2px] ${
            leaderboardCategory === "monthly"
              ? "bg-tertiary text-black"
              : "border-2 border-tertiary text-white"
          } rounded-full cursor-pointer`}
          onClick={() => setLeaderBoardCategory("monthly")}
        >
          Monthly
        </div>
        <div
          className={`w-[80px] text-center py-[2px] ${
            leaderboardCategory === "daily"
              ? "bg-tertiary text-black"
              : "border-2 border-tertiary text-white"
          } rounded-full cursor-pointer`}
          onClick={() => setLeaderBoardCategory("daily")}
        >
          Daily
        </div>
      </div>
      {isLoading ? (
        <div className="text-4xl text-white pt-8 flex items-center justify-center h-[300px]">
          <Loader />
        </div>
      ) : (
        <div className="mt-2 px-4 ">
          <div className="text-[12px] md:text-base flex font-semibold justify-between mb-2 py-2 px-2 bg-[#111111] rounded-full text-[#C4C4C4  text-tertiary border- border-b-[#939393]">
            <div className="w-[20%] md:w-[12%] pl-4">#</div>
            <div className="w-[40%] md:w-[28%]">Name</div>
            <div className="hidden md:block w-[15%]">Age</div>
            <div className="w-[25%] md:w-[15%]">PnL</div>
            <div className="hidden md:block w-[15%]">Percentage</div>
          </div>
          <div className="h-[450px] min-[380px]:h-[250px] overflow-y-auto scrollbar text-[10px] md:text-base">
            {tradeType === "realTrades"
              ? leaderboardCategory === "monthly"
                ? realMonthLeaderboard?.map((lbd, i) => (
                    <div
                      className="flex justify-between items-center py-1 px-2 text-[#C4C4C4]"
                      key={i}
                    >
                      <div className="w-[20%] md:w-[12%]">
                        <img
                          src={
                            i === 0
                              ? gold
                              : i === 1
                              ? silver
                              : i === 2
                              ? bronze
                              : honour
                          }
                          className="size-12"
                          alt="medal"
                        />
                      </div>
                      <div className="w-[40%] md:w-[28%] pb-1">
                        {lbd.user.name}
                      </div>
                      <div className="hidden md:block w-[15%] pb-1">
                        {lbd.user.age} days
                      </div>
                      <div className="hidden md:block md:w-[15%] pb-1 text-tertiar text-white font-semibold">
                        {lbd?.month?.pnl}
                      </div>
                      <div className="w-[25%] md:w-[15%] pb-1 text-tertiar text-white font-semibold">
                        {lbd?.month?.pnlPercentage}
                      </div>
                    </div>
                  ))
                : realDayLeaderboard.map((lbd, i) => (
                    <div
                      className="flex justify-between items-center py-1 px-2 text-[#C4C4C4]"
                      key={i}
                    >
                      <div className="w-[20%] md:w-[12%]">
                        <img
                          src={
                            i === 0
                              ? gold
                              : i === 1
                              ? silver
                              : i === 2
                              ? bronze
                              : honour
                          }
                          className="size-12"
                          alt="medal"
                        />
                      </div>
                      <div className="w-[40%] md:w-[28%] pb-1">
                        {lbd.user.name}
                      </div>
                      <div className="hidden md:block w-[15%] pb-1">
                        {lbd.user.age} days
                      </div>
                      <div className="hidden md:block w-[15%] pb-1 text-tertiar text-white font-semibold">
                        {lbd?.dayPnl?.pnl}
                      </div>
                      <div className="w-[25%] md:w-[15%] pb-1 text-tertiar text-white font-semibold">
                        {lbd?.dayPnl?.pnlPercentage}
                      </div>
                    </div>
                  ))
              : leaderboardCategory === "monthly"
              ? paperMonthLeaderboard?.map((lbd, i) => (
                  <div
                    className="flex justify-between items-center py-1 px-2 text-[#C4C4C4]"
                    key={i}
                  >
                    <div className="w-[20%] md:w-[12%]">
                      <img
                        src={
                          i === 0
                            ? gold
                            : i === 1
                            ? silver
                            : i === 2
                            ? bronze
                            : honour
                        }
                        className="size-12"
                        alt="medal"
                      />
                    </div>
                    <div className="w-[40%] md:w-[28%] pb-1">
                      {lbd.user.name}
                    </div>
                    <div className="hidden md:block w-[15%] pb-1">
                      {lbd.user.age} days
                    </div>
                    <div className="hidden md:block md:w-[15%] pb-1 text-tertiar text-white font-semibold">
                      {lbd?.month?.pnl}
                    </div>
                    <div className="w-[25%] md:w-[15%] pb-1 text-tertiar text-white font-semibold">
                      {lbd?.month?.pnlPercentage}
                    </div>
                  </div>
                ))
              : paperDayLeaderboard.map((lbd, i) => (
                  <div
                    className="flex justify-between items-center py-1 px-2 text-[#C4C4C4]"
                    key={i}
                  >
                    <div className="w-[20%] md:w-[12%]">
                      <img
                        src={
                          i === 0
                            ? gold
                            : i === 1
                            ? silver
                            : i === 2
                            ? bronze
                            : honour
                        }
                        className="size-12"
                        alt="medal"
                      />
                    </div>
                    <div className="w-[40%] md:w-[28%] pb-1">
                      {lbd.user.name}
                    </div>
                    <div className="hidden md:block w-[15%] pb-1">
                      {lbd.user.age} days
                    </div>
                    <div className="hidden md:block w-[15%] pb-1 text-tertiar text-white font-semibold">
                      {lbd?.dayPnl?.pnl}
                    </div>
                    <div className="w-[25%] md:w-[15%] pb-1 text-tertiar text-white font-semibold">
                      {lbd?.dayPnl?.pnlPercentage}
                    </div>
                  </div>
                ))}
          </div>
        </div>
      )}
    </div>
  );
};
