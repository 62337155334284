import { Collapse } from "antd";

export const Faq = () => {
  const items = [
    {
      key: "1",
      label: "What is included in the white-label solution?",
      children: (
        <p className="text-[12px] md:text-base">
          {
            "Our white-label solution allows you to customize the Alertsify platform with your branding, including logo, colors, and alert styles. This provides a seamless experience for your community members, as if the system was built specifically for your server."
          }
        </p>
      ),
    },
    {
      key: "2",
      label: "Can I upgrade my plan as my community grows?",
      children: (
        <p className="text-[12px] md:text-base">
          {
            "Absolutely! Alertsify is designed to scale with your needs. You can easily upgrade your plan at any time to accommodate a growing community, ensuring uninterrupted access to all the features you need."
          }
        </p>
      ),
    },
    {
      key: "3",
      label: "How do I integrate Alertsify into my Discord server?",
      children: (
        <p className="text-[12px] md:text-base">
          {
            "Integrating Alertsify with your Discord server is simple. After signing up, follow our step-by-step guide to connect your server, set up roles, and configure alerts. Our intuitive interface ensures you’ll be up and running in no time."
          }
        </p>
      ),
    },
    {
      key: "4",
      label:
        "What security measures are in place to protect my community’s data?",
      children: (
        <p className="text-[12px] md:text-base">
          {
            "We take data security very seriously. Alertsify uses end-to-end encryption, secure API connections, and regular security audits to protect your community’s data and ensure compliance with industry standards."
          }
        </p>
      ),
    },
    {
      key: "5",
      label: "Can I get a demo before purchasing?",
      children: (
        <p className="text-[12px] md:text-base">
          {
            "Yes, we offer a free demo to showcase how Alertsify works and how it can benefit your community. Contact us to schedule a personalized walkthrough and see the platform in action."
          }
        </p>
      ),
    },
  ];

  return (
    <Collapse size="large" items={items} defaultActiveKey={["1"]} accordion />
  );
};
