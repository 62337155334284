import React from 'react';
import PropTypes from 'prop-types';
import PnLTileMap from './PnLTileMap';

const PnlHistoryTiledPanelSmall = (props) => {
  const { pnlMeterType, setPnlMeterType } = props;
  return (
    <div className="mt-4 bg-[#202020] px-8 py-4 rounded-3xl" >
      <div className="flex justify-between items-start mb-2">
        <div className="flex flex-col justify-start gap-4 text-[#A1A1A1] pl-4 text-lg">
          <div>Daily PnL (small)</div>
          <div className="flex gap- text-base items-center">
            <div
              onClick={() => setPnlMeterType("percentage")}
              className={` rounded-l-md border w-16 text-center border-[#A1A1A1] cursor-pointer px-3 ${pnlMeterType === "percentage"
                ? "bg-tertiary text-black font-bold border-tertiary drop-shadow-xl"
                : "bg-[#]"
                }`}
            >
              PnL%
            </div>
            <div
              onClick={() => setPnlMeterType("pnl")}
              className={` rounded-r-md border w-16 text-center border-[#A1A1A1] cursor-pointer px-3 ${pnlMeterType === "pnl"
                ? "bg-tertiary text-black font-bold border-tertiary drop-shadow-xl"
                : "bg-[#]"
                }`}
            >
              PnL
            </div>
          </div>
        </div>
      </div>
      <div className="flex min-[450px]:hidden justify-center bg-[#202020] rounded-xl px-4 pt-4 ">{/* TODO: this seems to be always hidden */}
        <div>
          <PnLTileMap {...props} />
        </div>
      </div>
      <div className="hidden min-[450px]:flex justify-center bg-[#202020] rounded-xl px-4 pt-4 ">
        <div className='flex flex-1'>
          <PnLTileMap {...props} />
        </div>
      </div>
    </div >
  )
}

PnlHistoryTiledPanelSmall.propTypes = {
  pnlMeterType: PropTypes.oneOf(['percentage', 'pnl']).isRequired,
  setPnlMeterType: PropTypes.func.isRequired,
}

export default PnlHistoryTiledPanelSmall