import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import PnlHistoryTiledPanelLarge from './PnlHistoryTiledPanelLarge';
import PnlHistoryTiledPanelSmall from './PnlHistoryTiledPanelSmall';
import moment from 'moment';

const constructTileMatrix = (btoApiData) => {
    const tileMatrix = [];

    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    const now = moment();
    const formatedTodaysDate = now.format('YYYY-MM-DD');
    const formatedTodaysDay = now.format('ddd');

    let lastTradingDateOffset = 0;
    if (formatedTodaysDay === 'Sun') lastTradingDateOffset = 2;
    else if (formatedTodaysDay === 'Sat') lastTradingDateOffset = 1;

    const lastTradingDate = now.subtract(lastTradingDateOffset, 'days').format('YYYY-MM-DD');
    const lastTradingDay = moment(lastTradingDate).format('ddd');

    const blankTileCountForLastWeek = 5 - daysOfWeek.indexOf(lastTradingDay);

    let tradingWeek = 5;
    let tradingDay = lastTradingDay;
    let tradingDate = lastTradingDate;
    let tradingWeekTiles = [];
    let needBlanks = lastTradingDateOffset === 0;
    while (tradingWeek > 0) {
        if (tradingWeek === 5 && needBlanks) {
            for (let i = 0; i < blankTileCountForLastWeek; i++) {
                tradingWeekTiles.push(null);
            }
            needBlanks = false;
        }
        if (tradingDay === 'Sun') {
            tradingWeek = tradingWeek - 1;
            tileMatrix.unshift(tradingWeekTiles);
            tradingWeekTiles = [];
        } else if (!['Sun', 'Sat'].includes(tradingDay)) {
            tradingWeekTiles.unshift({
                isToday: tradingDate === formatedTodaysDate,
                date: tradingDate,
                day: tradingDay,
                paperTrades: {
                    pnl: btoApiData.paperTrades[tradingDate]?.pnl || 0,
                    pnlPercentage: btoApiData.paperTrades[tradingDate]?.pnlPercentage || 0,
                },
                realTrades: {
                    pnl: btoApiData.realTrades[tradingDate]?.pnl || 0,
                    pnlPercentage: btoApiData.realTrades[tradingDate]?.pnlPercentage || 0,
                },
            });
        }
        tradingDate = moment(tradingDate).subtract(1, 'days').format('YYYY-MM-DD');
        tradingDay = moment(tradingDate).format('ddd');
    }

    return tileMatrix;
}

const PnLHistoryTiledPanel = (props) => {
    const { size, btoApiData } = props;

    const computedTileMatrix = useMemo(() => {
        const tileMatrix = constructTileMatrix(btoApiData);
        return tileMatrix;
    }, [btoApiData]);

    if (size === 'small') return <PnlHistoryTiledPanelSmall {...props} computedTileMatrix={computedTileMatrix} />;
    else return <PnlHistoryTiledPanelLarge {...props} computedTileMatrix={computedTileMatrix} />;
}

PnLHistoryTiledPanel.propTypes = {
    size: PropTypes.oneOf(['small', 'large']).isRequired,
    btoApiData: PropTypes.object.isRequired,
}

export default PnLHistoryTiledPanel;