import React, { useEffect, useState, memo } from "react";
import { sampleData } from "./sampleModelData";
import tickers from "./popularTickers";
import bgLeft from "../../assets/bgLeftRectangle.png";
import bgPhoneTop from "../../assets/bgPhoneTopRectangle.png";
import profile from "../../assets/defaultPicture.png";
import Loader from "../../components/Loader";
import { Link, useNavigate } from "react-router-dom";
import { userStore } from "../../store/user";
import { getUser } from "../../API/user";
import { getUserBtoData } from "../../API/bto";
import { toast } from "react-toastify";
import { sendMessage } from "../../API/discord";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { Input, Tooltip } from "antd";
import { IoIosSend } from "react-icons/io";
import { IoSearch, IoClose } from "react-icons/io5";
import "../../customStyles/model.css";
import BtoData from "../../components/BtoData";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { getOptionContracts } from "../../API/user";
import { removeDuplicatesByKey } from "../../helper";
import moment from "moment";
import Sidebar from "../../components/Sidebar/Sidebar";
import DiscordInputs from "../../components/DiscordInputs";

import {v4 as uuidv4} from "uuid"
import Skeleton from "react-loading-skeleton";
import DashModal from "../../components/DashModal";
import DashHeader from "../../components/DashHeader/DashHeader";

let subDataForBtoCreate = {};
const Dashboard = () => {
  const [responseData, setResponseData] = useState();
  //const [wishlistData, setWishListData] = useState();
  const [tickerList, setTickerList] = useState(tickers);
  const [quantities, setQuantities] = useState({});
  const [selectedContract, setSelectedContract] = useState({});
  const [dataLoading, setDataLoading] = useState(true);
  const [bigNavToggle, setbigNavToggle] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const {
    userData,
    setUserData,
    setBtoData,
    setBtoLoading,
    expirationDatesOfTicker,
    setExpirationDatesOfTicker,
    btoData: { active = [] },
    socket,
    wishlistData,
    setWishListData,
    setUniqueString,
    selectedOptionArray,
    setSelectedOptionArray,
    hasBrokerTokens,
    hasDataBrokerTokens,
    selectedAccount,
    isPaperTrade
  } = userStore((state) => state);
  const {
    avatarUrl,
    discordServers = [],
    name,
    id: userId,
    role,
    currentBalance
  } = userData || {};
  const [messageLoading, setMessageLoading] = useState({});
  const [contractType, setContractType] = useState({});
  const [appendText, setAppendText] = useState("");
  function changeText(text) {
    setAppendText(text);
  }

  // console.log(userData)
  // const [selectedOptionArray, setSelectedOptionArray] = useState([
  //   tickerList[0],
  //   tickerList[1],
  //   tickerList[2],
  //   tickerList[3],
  //   tickerList[4],
  // ]);
  const [tickerSelection, setTickerSelection] = useState(false);
  const [tickerChangeIndex, setTickerChangeIndex] = useState(0);
  const [tickerSuggestions, setTickerSuggestions] = useState([]);
  const [suggestionLoading, setSuggestionLoading] = useState(false);
  const [suggestionInput, setSuggestionInput] = useState("");
  const [generateLoading, setGenerateLoading] = useState(false);
  const gentradeToken = localStorage.getItem("gentradeToken");
  const subDomainUrl = localStorage.getItem('baseURL')
  const [wishlistLoading, setWishListLoading] = useState(false)
  const [tickerLoading, setTickerLoading] = useState(false)
  const [rowLoading, setRowLoading] = useState(new Array(5).fill(false))
  const [rowLoadingIndex, setRowLoadingIndex] = useState(0)
  const [balanceLoading, setBalanceLoading] = useState(false)

  const [tempBalance, setTempBalance] = useState(1000)

  function generateUniqueString() {
    return uuidv4();
  }

  const getESTOffset = (date) => {
    // Check if the date falls within daylight saving time
    // Daylight saving time starts on the second Sunday in March and ends on the first Sunday in November
    const year = date.year();
    const dstStart = moment([year, 2, (14 - new Date(year, 2, 1).getDay())]); // Second Sunday in March
    const dstEnd = moment([year, 10, (7 - new Date(year, 10, 1).getDay())]); // First Sunday in November
  
    return date.isBetween(dstStart, dstEnd, null, '[]') ? -4 * 60 : -5 * 60; // EDT is UTC-4, EST is UTC-5
  };
  
  const getESTMoment = () => {
    const now = moment();
    const estOffset = getESTOffset(now);
    return now.utcOffset(estOffset);
  };
  
  const tradingStartTime = moment().utcOffset(getESTOffset(moment())).set({ hour: 9, minute: 30, second: 0, millisecond: 0 });
  const tradingEndTime = moment().utcOffset(getESTOffset(moment())).set({ hour: 16, minute: 0, second: 0, millisecond: 0 });
  
  const isWithinTradingHours = () => {
    const now = getESTMoment();
    const dayOfWeek = now.day();
    const isWeekday = dayOfWeek >= 1 && dayOfWeek <= 5; // Monday to Friday
    return isWeekday && now.isBetween(tradingStartTime, tradingEndTime);
  };

  // console.log("check time zone", isWithinTradingHours())

  const updateUserInfo = async () => {
    try {
      const response = await getUser(gentradeToken);
      setUserData(response?.data);
      // localStorage.removeItem("btoMetaData");
      // localStorage.removeItem("cachedDiscordChannels");
    } catch (err) {
      console.log(err);
      toast(
        err?.response?.data ||
          "Something went wrong, please try again later.",
        { type: "error" }
      );
      localStorage.clear();
      if(subDomainUrl && gentradeToken){
        localStorage.clear();
        window.location.href = `${subDomainUrl}/signIn`
      }
      else{
        localStorage.clear();
        navigate("/signIn");
      }
    }
  }

  useEffect(() => {
    if (!gentradeToken) {
      if(subDomainUrl && gentradeToken){
        localStorage.clear();
        window.location.href = `${subDomainUrl}/signIn`
      }
      else{
        localStorage.clear();
        navigate("/signIn");
      }
    }
  }, []);

  useEffect(() => {
    if (!socket) return;

    socket.on("ticker_stream", (message, unique) => {
      try {
        const state = userStore.getState();

        // console.log(typeof(message));
        
        setResponseData(message);
        setDataLoading(false);
        if(unique == state.uniqueString){
          setWishListLoading(false)
          const newArray = [...rowLoading]; // Create a copy of the array
          newArray[rowLoadingIndex] = false; // Update the specific index
          setRowLoading(newArray); // Set the state with the new array
          setBalanceLoading(false)
          
          setTickerLoading(false)
        }
      } catch (error) {
        console.error("Error parsing JSON or processing data:", error);
      }
    });

    // setInterval(() => {
    //   socket.emit("ping", {
    //     registerData,
    //     userId: userId || localStorage.getItem("userId"),
    //   });
    // }, 15000);
  }, [socket]);


  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isOpen]);

  useEffect(() => {
    if (wishlistData && !dataLoading) {
      // console.log("change", wishlistData);

      
    const randomString = generateUniqueString()
    setUniqueString(randomString)
    
    console.log('Updated uniqueString:', userStore.getState().uniqueString);
    
      socket.emit("wishlist", {
        userId,
        wishlistData,
        changeId: randomString
      });
    }
  }, [wishlistData]);

  useEffect(() => {
    //updating selected contract values from the responseData
    if (responseData) {
      const newSelectedContract = { ...selectedContract };
      const keys = Object.keys(newSelectedContract);
      if (keys.length) {
        keys.forEach((key) => {
          if (selectedContract[key]) {
            const columnKey = Object.keys(selectedContract[key])?.[0];
            const valueArr =
              responseData?.[selectedOptionArray[key]][columnKey];
            if (valueArr?.length) {
              newSelectedContract[key][columnKey] = {
                strike: valueArr[0],
                quote: valueArr[1],
                qty: valueArr[2],
              };
            }
          }
        });
        setSelectedContract(newSelectedContract);
      }
    }
  }, [responseData]);
  //Ticker Selection

  const handleNearChange = (e, index) => {
    setWishListLoading(true)
    const newArray = [...rowLoading]; // Create a copy of the array
    newArray[index] = true; // Update the specific index
    setRowLoading(newArray); // Set the state with the new array
    setRowLoadingIndex(index)

    //Clear the selected contract of the particular index
    setSelectedContract((prevValues) => {
      const newValues = { ...prevValues };
      newValues[index] = null;

      return newValues;
    });

    let input = e.target.value.toString();
    if (input === "") return;
    const newWishListData = {
      ...wishlistData,
      [selectedOptionArray[index]]: [
        input,
        ...wishlistData[selectedOptionArray[index]].slice(1),
      ],
    };
    setWishListData(newWishListData);
  };

  const handleCallPutChange = (e, index) => {
    setWishListLoading(true)
    const newArray = [...rowLoading]; // Create a copy of the array
    newArray[index] = true; // Update the specific index
    setRowLoading(newArray); // Set the state with the new array
    setRowLoadingIndex(index)

    //Clear the selected contract of the particular index
    setSelectedContract((prevValues) => {
      const newValues = { ...prevValues };
      newValues[index] = null;

      return newValues;
    });

    let input = e.target.checked;
    if (input === true) {
      input = "put";
    } else {
      input = "call";
    }
    const newWishListData = {
      ...wishlistData,
      [selectedOptionArray[index]]: [
        ...wishlistData[selectedOptionArray[index]].slice(0, 2),
        input,
      ],
    };
    setWishListData(newWishListData);
    setContractType((prevData) => ({
      ...prevData,
      [index]: input[0].toUpperCase(),
    }));
  };

  const handleDateChange = (date, index) => {
    setWishListLoading(true)
    const newArray = [...rowLoading]; // Create a copy of the array
    newArray[index] = true; // Update the specific index
    setRowLoading(newArray); // Set the state with the new array
    setRowLoadingIndex(index)
    
    //Clear the selected contract of the particular index
    setSelectedContract((prevValues) => {
      const newValues = { ...prevValues };
      newValues[index] = null;

      return newValues;
    });

    const oldWishList = { ...wishlistData };
    let input = date.toString();
    if (input === "") return;
    oldWishList[selectedOptionArray[index]][1] = input;
    setWishListData(oldWishList);
  };

  const handleBalanceChange = (e) => {
    if(Number(e.target.value) > currentBalance){
      setTempBalance(wishlistData?.balance)
      return toast.error("Insufficient Balance")
    }
    setWishListLoading(true)
    setBalanceLoading(true)
    //Clear the selected contract of the particular index
    setSelectedContract({});
    const tempArray = selectedOptionArray || [];
    let tempData = { ...wishlistData };

    delete tempData["balance"];

    tempData["balance"] = Number(e.target.value);

    setWishListData(tempData);
  };

  const handleSendMessage = async (message, index, key) => {
    const indexVal = !message ? subDataForBtoCreate.index : index;
    const keyVal = !message ? subDataForBtoCreate.key : key;
    const btoQuantityInput = document.getElementById("bto-quantity");
    const btoQuantities = btoQuantityInput?.value;
    const {
      discordServerId,
      discordChannelId,
      appendText = "",
    } = JSON.parse(localStorage.getItem("btoMetaData") || `{}`);
    const cachedDiscordChannels = JSON.parse(
      localStorage.getItem("cachedDiscordChannels") || `{}`
    );
    // if (!isWithinTradingHours()){
    //   return toast.error("Market closed right now")
    // }
    if (message && !discordServerId)
      return toast("Please select the discord server and channel");
    const discordChannels = cachedDiscordChannels[discordServerId];

    try {
      message
        ? setMessageLoading((prevData) => ({ ...prevData, [indexVal]: true }))
        : setGenerateLoading(true);
      const response = await sendMessage(
        {
          ...(message
            ? {
                serverId: discordServerId,
                channelId: discordChannelId,
                message: `${message} ${appendText}`,
              }
            : { alertDiscord: false }),
          orderType: "bto",
          ticker: selectedOptionArray[indexVal],
          qty: quantities[indexVal]
            ? parseInt(quantities[indexVal])
            : btoQuantities
            ? parseInt(btoQuantities)
            : parseInt(selectedContract[indexVal][keyVal].qty),
          quote: parseFloat(selectedContract[indexVal][keyVal].quote),
          strikePrice: parseFloat(selectedContract[indexVal][keyVal].strike),
          contractType: contractType[indexVal] == "P" ? "put" : "call",
          expirationDate: wishlistData?.[selectedOptionArray[indexVal]]?.[1],
          appendText: appendText,
          ...(message && {
            discordInfo: {
              server: discordServers.find((ds) => ds.id == discordServerId),
              channel: discordChannels.find((ds) => ds.id == discordChannelId),
            },
          }),
          forcePaperMoneyTrade: isPaperTrade,
          brokerAccountId: selectedAccount
        },
        gentradeToken
      );
      if (response?.data) {
        toast(response.data.msg, { type: "success" });
        (async () => {
          try {
            setBtoLoading(true);
            const btoResponse = await getUserBtoData(gentradeToken);
            const { activeBtos, expiredBtos, soldBtos } = btoResponse.data || {};
            let expiredValues = [];
            if (expiredBtos?.length) {
              expiredValues = expiredBtos.map((d) => ({
                ...d,
                pnlText: "BTO has expired",
              }));
            }
  
            setBtoData({
              expired: expiredValues,
              active: activeBtos,
              sold: soldBtos?.length
                ? soldBtos.map((d) => ({
                    ...d,
                    pnlText: "BTO has been sold out",
                  }))
                : [],
            });
            
            const btoMetaData = JSON.parse(localStorage.getItem('BTOmetaData') || '{}');
            if (btoMetaData.appendText) {
              btoMetaData.appendText = ''; // Clear the appendText property
              localStorage.setItem('BTOmetaData', JSON.stringify(btoMetaData));
            }
            setAppendText("")
            setBtoLoading(false);
          } catch (err) {
            console.log(err);
            toast(
              err?.response?.data || "Something went wrong, please try again later",
              { type: "error" }
            );
            setBtoLoading(false);
          }
        })();
        message
          ? setMessageLoading((prevData) => ({
              ...prevData,
              [indexVal]: false,
            }))
          : setGenerateLoading(false);

          if (gentradeToken) {
            (async () => {
              try {
                const response = await getUser(gentradeToken);
                setUserData(response?.data);
                localStorage.removeItem("btoMetaData");
                localStorage.removeItem("cachedDiscordChannels");
              } catch (err) {
                console.log(err);
                toast(
                  err?.response?.data ||
                    "Something went wrong, please try again later.",
                  { type: "error" }
                );
                localStorage.clear();
                if(subDomainUrl && gentradeToken){
                  localStorage.clear();
                  window.location.href = `${subDomainUrl}/signIn`
                }
                else{
                  localStorage.clear();
                  navigate("/signIn");
                }
              }
            })();
          }
        
        setBtoData({
          active: [
            {
              ...response.data.newData,
              pnlData: response.data.newData?.lastPnlData,
            },
            ...active,
          ],
        });
        setSelectedContract((prevData) => ({ ...prevData, [indexVal]: null }));
        setQuantities((prevData) => ({ ...prevData, [indexVal]: "" }));
        btoQuantities && (btoQuantityInput.value = "");
        updateUserInfo();
      }
    } catch (err) {
      console.log(err);
      message
        ? setMessageLoading((prevData) => ({ ...prevData, [indexVal]: false }))
        : setGenerateLoading(false);
      toast(
        err.response?.status == 400
          ? "please give all the necessary fields"
          : err?.response?.data,
        { type: "error" }
      );
    }
  };

  useEffect(() => {
    const handleTickerSuggestion = async (suggestionInput) => {
      setSuggestionLoading(true);
      let tempArray = [];
      const input = suggestionInput.toUpperCase();
      try {
        await axios({
          method: "get",
          url: `https://api.polygon.io/v3/reference/tickers?ticker.gte=${input}&active=true&apiKey=KxL8LahsGefgAQCNphXDCcLBM0BIJM1Q&limit=10`,
        }).then(function (response) {
          const responseData = response.data;
          responseData?.results?.map((item, index) => {
            tempArray = [...tempArray, item?.ticker];
          });
          setTickerSuggestions(tempArray);
          setSuggestionLoading(false);
        });
      } catch (error) {
        toast(error, {
          type: "error",
        });
        console.error("Error fetching ticker suggestions:", error);

        setTickerSuggestions([]);
        setSuggestionLoading(false);
      }
    };
    handleTickerSuggestion(suggestionInput);
  }, [suggestionInput]);

  const handleTickerSelection = async (ticker) => {
    if (ticker in wishlistData) {
      // console.log("already exists");
      toast("Ticker already selected", {
        type: "error",
      });
      return;
    }
    // setWishListLoading(true)
    setWishListLoading(true)
     // Set the state with the new array
    // setRowLoadingIndex(index)

    let tempArray = selectedOptionArray;

    let tempData = { ...wishlistData };

    if (tempArray[tickerChangeIndex] !== undefined) {
      let expiryDate = moment().add(2, "days").format("YYYY-MM-DD");
      let current;
      try {
        const response = await axios({
          method: "get",
          url: `https://finnhub.io/api/v1/quote?symbol=${ticker}&token=ch1arbhr01qn6tg71ijgch1arbhr01qn6tg71ik0`,
        });
        current = response?.data?.c?.toString();

        // fetching expiration date for the ticker
        // checking for the expirationDates present or not
        if (!expirationDatesOfTicker[ticker]) {
          try {
            const response = await getOptionContracts(
              {
                ticker,
                contractType:
                  contractType[tickerChangeIndex] == "P" ? "put" : "call",
              },
              process.env.REACT_APP_POLYGON_API_KEY
            );
            const expiryDates = removeDuplicatesByKey(
              response.data.results,
              "expiration_date"
            )?.map((ob) => ob.expiration_date);
            setExpirationDatesOfTicker({
              [ticker]: expiryDates?.length ? expiryDates : [expiryDate],
            });

            if (expiryDates?.length) {
              expiryDate = expiryDates[0];
            }
          } catch (err) {
            console.log(err);
          }
        }
      } catch (error) {
        toast(error, {
          type: "error",
        });
        console.error("Error fetching ticker's current price':", error);
      }
      tempData[ticker] = [current, expiryDate, "call"];

      delete tempData[tempArray[tickerChangeIndex]];
    }

    const inputElement = document.getElementById("near-price"); // Get the input element by its ID
    inputElement.value = "";

    setWishListData(tempData);

    tempArray[tickerChangeIndex] = ticker;
    setSelectedOptionArray(tempArray);
    setTickerSelection(false);
    setTickerLoading(true)
    const newArray = [...rowLoading]; // Create a copy of the array
    newArray[rowLoadingIndex] = true; // Update the specific index
    setRowLoading(newArray);
  };

  const handleTickerChange = (index) => {
    setRowLoadingIndex(index)
    //Clear the selected contract of the particular index
    setSelectedContract((prevValues) => {
      const newValues = { ...prevValues };
      newValues[index] = null;

      return newValues;
    });
    setTickerChangeIndex(index);
    setTickerSelection(true);
  };

  return (
    <div
      className="lg:h-screen flex flex-col lg:flex-row bg bg-cover bg-center"
      // style={{backgroundImage: `url(${bgLeft})`}}
    >
      <DashModal isOpen={hasDataBrokerTokens} />
      <Sidebar
        bigNavToggle={bigNavToggle}
        setbigNavToggle={setbigNavToggle}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        route={"model"}
      />
      {tickerSelection && (
        <div
          onClick={() => setTickerSelection(false)}
          className="fixed lg:absolute w-full h-full z-40 bg-black opacity-40 "
        />
      )}
      {/* Ticker Selection Box  */}
      {tickerSelection && (
        <div
          className="fixed lg:absolute border border-[#7d7d7d] z-50 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#111111] drop-shadow-2xl rounded-xl
          w-[90%] h-[550px] px-4
          sm:w-[450px]
      "
        >
          <div className="flex justify-end mt-2">
            <IoClose
              size={25}
              className="text-white text-right mb-2 cursor-pointer hover:text-[#DAFE6A]"
              onClick={() => setTickerSelection(false)}
            />
          </div>
          <div className="flex gap-2 border border-[#7D7D7D] py-2 px-2 rounded-xl mb-4">
            {/* Search Bar  */}

            <IoSearch size={25} className="text-[#7D7D7D]" />

                  <input
                    placeholder="Search Tickers"
                    onChange={(e) => setSuggestionInput(e.target.value)}
                    // onChange={handleTickerSuggestion}
                    className="text-[#7d7d7d] placeholder:text-[#7d7d7d] bg-transparent outline-none"
                  />
                </div>
                <div>
                  {!suggestionLoading ? (
                    <div>
                      {!suggestionLoading &&
                        tickerSuggestions &&
                        tickerSuggestions?.map((ticker, index) => (
                          <>
                            <div
                              className="text-[#7d7d7d] cursor-pointer hover:bg-[#DAFE6A] hover:font-semibold hover:text-black rounded-xl transition-colors text-[18px] px-8 py-2 border- border-[#7d7d7d"
                              onClick={() => handleTickerSelection(ticker)}
                            >
                              {ticker}
                            </div>
                            <div className="w-[96%] mx-auto h-[0.1px] bg-[#232323]" />
                          </>
                        ))}
                    </div>
                  ) : (
                    <div className="text-4xl text-white pt-8 flex items-center justify-center">
                      {/* Loading... */}
                      <Loader />
                    </div>
                  )}
                </div>
              </div>
            )}
            {/* Body for larger screens  */}
            <div
              className={`hidden lg:block ${
                bigNavToggle ? "lg:w-[80%]" : "lg:w-[96%]"
              } bg-cover bg-no-repeat bg-left px-12 py-8 text-[15px]`}
              style={{ backgroundImage: `url(${bgLeft})` }}
            >
              {/* <div className="flex justify-end gap-4">
                <div className="flex gap-2 text-tertiary [#C4C4C4]  border border-[#646464] rounded-lg text-center px-2 py-1">
                  Current balance:
                  <div className="text-white">{currentBalance}</div>
                </div>
                <div className="flex">
                {role == "admin" ? (
                  <Link
                    to={"/admin/userInfo"}
                    className="text-tertiary px-2 py-1 transition-all border border-[#646464] hover:border-tertiary text-center rounded-lg"
                  >
                    Admin Portal
                  </Link>
                ) : (
                  ""
                )}
                <img
                  src={avatarUrl || profile}
                  alt="profile"
                  className="ml-6 border border-white rounded-full size-[34px]"
                />
                </div>
              </div> */}

              <DashHeader />

        <div className="text-[42px] font-semibold text-heading">Model </div>

        <div className="bg-[#202020] h-[75vh] max-h-[75vh] overflow-y-auto scrollbar mt-2 rounded-3xl p-4 pb-12">
          {/* Checkbox and account section */}
          <div className="flex flex-col-revers gap-4 min-[1380px]:flex-row justify-betwee items-center pb-2">
            <div className="flex gap-2 font-medium items-center text-[#7D7D7D]">
              <div>Trade $ : </div>
              <input
                type="number"
                className="bg-[#555555] px-2 py-[2px] outline-none text-white w-[130px] rounded-lg"
                defaultValue={wishlistData?.balance}
                value={tempBalance}
                onChange={(e) => setTempBalance(e.target.value)}
                onBlur={(e) => handleBalanceChange(e)}
                onKeyDown={(e) => e.key === "Enter" && e.target.blur()}
              />
            </div>

            <DiscordInputs appendText={appendText} setAppendText={changeText}/>
          </div>

          {/* <div className="text-tertiary font-medium mt-6 text-[22.6px]">
            CURRENTLY IN CHALLENGE
          </div> */}

          {/* Main model */}
          {!dataLoading ? (
            <table className="w-full mt-4 text-[14px]">
              <thead className="text-[#7D7D7D] border-b border-b-[#7D7D7D]">
                <th className="pb-2 pl-2 w-[8%]">Ticker</th>
                <th className="pb-2 w-[8%]">Curr. Price</th>
                <th className="pb-2 w-[11%]">Exp. Date</th>
                <th className="pb-2 w-[4%]">Call | Put</th>
                <th className="pb-2 w-[8%]">Near</th>
                <th className="border-l border-l-[#7D7D7D] text-tertiary items-center gap- pb-2 px-2 w-[14%]">
                  <div className="flex gap-2">
                    <th className="w-full ">Strike</th>
                    <th className="w-full ">Quote</th>
                    <th className="w-full ">Qty</th>
                  </div>
                </th>
                {/* <th className="text-tertiary pb-2 w-[14%]">OTM</th> */}
                <th className="text-tertiary pb-2 w-[14%]">
                  <div className="flex gap-2 ">
                    <th className="w-full  ">Strike</th>
                    <th className="w-full ">Quote</th>
                    <th className="w-full ">Qty</th>
                  </div>
                </th>
                <th className="text-tertiary pb-2 w-[14%]">
                  <div className="flex  gap-2">
                    <th className="w-full ">Strike</th>
                    <th className="w-full ">Quote</th>
                    <th className="w-full ">Qty</th>
                  </div>
                </th>
                <th className="text-tertiary pb-2 pr-2 w-[14%]">
                  <div className="flex gap-2">
                    <th className="w-full  ">Strike</th>
                    <th className="w-full ">Quote</th>
                    <th className="w-full ">Qty</th>
                  </div>
                </th>
                <th className="text-tertiary pb-2 pr-2 w-[14%]">
                  <div className="flex gap-1">
                    <th className="w-full ">Qty</th>
                  </div>
                </th>
              </thead>
              <tbody className="text-[#7D7D7D]">
                {sampleData.map((item, index) => {
                  const key = selectedContract[index]
                    ? Object.keys(selectedContract[index])[0]
                    : null;
                  const message = selectedContract[index]
                    ? `BTO ${
                        quantities[index] || selectedContract[index][key].qty
                      } ${selectedOptionArray[index]} ${
                        selectedContract[index][key].strike
                      }${contractType[index] || "C"} ${moment(
                        wishlistData?.[selectedOptionArray[index]]?.[1],
                        "YYYY-MM-DD"
                      ).format("MM-DD-YYYY")} @${
                        selectedContract[index][key].quote
                      }`
                    : "";

                  return (
                    <tr className="text-white" key={index}>
                      {/* Ticker  */}
                      <td className="pb-2 pl-2 w-[6%] p-2">
                        <div
                          className="bg-[#424242] cursor-pointer text-center w-full text-white py-[2px] rounded-md"
                          // value={selectedOptionArray[index]}
                          onClick={() => handleTickerChange(index)}
                        >
                          {selectedOptionArray[index]}
                          {/* {tickerList?.map((value, tickerIndex) => (
                            <option value={value} key={tickerIndex}>
                              {value}
                            </option>
                          ))} */}
                        </div>
                      </td>
                      {/* Current Price */}
                      <td className="py-1 w-[8%] p-2">
                      {((rowLoading[index] && tickerLoading) || balanceLoading)
                          ? (
                            <Skeleton baseColor="#424242" highlightColor="#202020" className="h-[30.6px] py-1 rounded-md" />
                          )
                          : (
                        <div
                          type="text"
                          // value={responseData?.[selectedOptionArray[index]] && responseData?.[selectedOptionArray[index]]?.[3] ?("$" + responseData?.[selectedOptionArray[index]]?.[0][0]) : "$  -"}
                          className={`px-2 w-full bg-[#424242] py-1 rounded-md text-center ${wishlistLoading ? "pointer-events-none" : ""}`}
                        >
                          {responseData?.[selectedOptionArray[index]] &&
                          responseData?.[selectedOptionArray[index]]?.[0]
                            ? responseData?.[selectedOptionArray[index]]?.[0][0]
                            : "-"}
                        </div>)}
                      </td>
                      {/* Exp Date  */}
                      <td className="py-1 p-2">
                        {((rowLoading[index] && tickerLoading) || balanceLoading)
                          ? (
                            <Skeleton baseColor="#424242" highlightColor="#202020" className="h-[30.6px] py-1 rounded-md" />
                          )
                          : (
                        <select
                          onChange={(e) =>
                            handleDateChange(e.target.value, index)
                          }
                          onKeyDown={(e) =>
                            e.key === "Enter" && e.target.blur()
                          }
                          className={`w-full bg-[#424242] text-white text-center py-1 rounded-md ${wishlistLoading ? "pointer-events-none" : ""}`}
                        >
                          {expirationDatesOfTicker[selectedOptionArray[index]]
                            ?.filter(
                              (d) =>
                                new Date(d).getFullYear() ==
                                new Date().getFullYear()
                            )
                            .map((d, i) => (
                              <option value={d} key={i}>
                                {d}
                              </option>
                            ))}
                        </select>)}
                      </td>
                      {/* Call|Put  */}
                      <td className="py-1 text-center w-[4%] p-2">
                      {((rowLoading[index] && tickerLoading) || balanceLoading)
                          ? (
                            <Skeleton baseColor="#424242" highlightColor="#202020" className="h-[30.6px] py-1 rounded-md" />
                          )
                          : (
                        <label className={`inline-flex items-center cursor-pointer mx-auto ${wishlistLoading ? "pointer-events-none" : ""}`}>
                          <input
                            type="checkbox"
                            className="sr-only peer"
                            onChange={(e) => handleCallPutChange(e, index)}
                          />
                          <div className="relative w-11 h-6 bg-[#5DBC55] peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-white rounded-full peer dark:bg-[#5DBC55] peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#E83838]"></div>
                        </label>)}
                      </td>
                      {/* Near  */}
                      <td className={`py-1 w-[8%] p-2 ${wishlistLoading ? "pointer-events-none" : ""}`}>
                        {((rowLoading[index] && tickerLoading) || balanceLoading)
                          ? (
                            <Skeleton baseColor="#424242" highlightColor="#202020" className="h-[30.6px] py-1 rounded-md" />
                          )
                          : (
                          <input
                          type="number"
                          id="near-price"
                          placeholder={
                            wishlistData?.[selectedOptionArray[index]]?.[0]
                          }
                          className={`px-2 w-full bg-[#424242] py-1 rounded-md `}
                          step="any"
                          onBlur={(e) => handleNearChange(e, index)}
                          onKeyDown={(e) =>
                            e.key === "Enter" && e.target.blur()
                          }
                          onInput={(e) => {
                            const inputValue = e.target.value;
                            const parsedValue = parseFloat(inputValue);
                            if (isNaN(parsedValue)) {
                              console.error(
                                "Invalid input. Please enter a valid number."
                              );
                            } else {
                            }
                          }}
                        />)}
                      </td>
                      {/* Strike Quote Qty  */}
                      <td className="border-l border-l-[#7D7D7D] gap- py-2  p-2 text-center">
                        {(rowLoading[index] || balanceLoading)
                          ? (
                            <Skeleton baseColor="#424242" highlightColor="#202020" className="h-[30.6px] py-1 rounded-md" />
                          )
                          : (
                            <div className="flex h-[30.6px]">
                          <td className="w-full bg-[#424242] py-1 px-2 rounded-l-md">
                            {/* {item.strike} */}
                            {responseData?.[
                              selectedOptionArray[index]
                            ]?.[1]?.[0] ?? " -"}
                          </td>
                          <td
                            id={`${index}-first-column`}
                            className={`w-full py-1 px-2 
                                                    
                          ${
                            responseData?.[selectedOptionArray[index]]?.[1]?.[2]
                              ? "cursor-pointer"
                              : "cursor-not-allowed"
                          } ${
                              selectedContract[index]?.[1]
                                ? "text-tertiary bg-black"
                                : ""
                            } 
                          ${
                            wishlistData?.[selectedOptionArray[index]]?.[2] ===
                            "call"
                              ? responseData?.[
                                  selectedOptionArray[index]
                                ]?.[1]?.[0] <
                                responseData?.[
                                  selectedOptionArray[index]
                                ]?.[0]?.[0]
                                ? " bg-[#00F4FF] border border-[#00F4FF]  text-black font-semibold"
                                : responseData?.[
                                    selectedOptionArray[index]
                                  ]?.[1]?.[0] >
                                  responseData?.[
                                    selectedOptionArray[index]
                                  ]?.[0]?.[0]
                                ? " bg-[#FFE664] border border-[#FFE664]  text-black font-semibold"
                                : "bg-[#424242] border  border-[#424242]  text-white"
                              : responseData?.[
                                  selectedOptionArray[index]
                                ]?.[1]?.[0] >
                                responseData?.[
                                  selectedOptionArray[index]
                                ]?.[0]?.[0]
                              ? " bg-[#00F4FF] border border-[#00F4FF]  text-black font-semibold"
                              : responseData?.[
                                  selectedOptionArray[index]
                                ]?.[1]?.[0] <
                                responseData?.[
                                  selectedOptionArray[index]
                                ]?.[0]?.[0]
                              ? " bg-[#FFE664] border border-[#FFE664]  text-black font-semibold"
                              : "bg-[#424242] border border-[#424242]   text-white"
                          }
                          `}
                            title={`${
                              !responseData?.[
                                selectedOptionArray[index]
                              ]?.[1]?.[2]
                                ? "Quantity is 0"
                                : ""
                            }`}
                            onClick={() => {
                              if (
                                responseData?.[
                                  selectedOptionArray[index]
                                ]?.[1]?.[2]
                              ) {
                                setSelectedContract((prevValues) => ({
                                  ...prevValues,
                                  [index]: prevValues[index]?.[1]
                                    ? null
                                    : {
                                        [1]: {
                                          strike:
                                            responseData?.[
                                              selectedOptionArray[index]
                                            ]?.[1]?.[0],
                                          quote:
                                            responseData?.[
                                              selectedOptionArray[index]
                                            ]?.[1]?.[1],
                                          qty: responseData?.[
                                            selectedOptionArray[index]
                                          ]?.[1]?.[2],
                                        },
                                      },
                                }));
                                subDataForBtoCreate.key = 1;
                                subDataForBtoCreate.index = index;
                              }
                            }}
                          >
                            {/* {item.quote} */}
                            {responseData?.[
                              selectedOptionArray[index]
                            ]?.[1]?.[1] ?? " -"}
                          </td>
                          <td className="w-full bg-[#424242] py-1 px-2 rounded-r-md">
                            {/* {item.qty} */}
                            {responseData?.[
                              selectedOptionArray[index]
                            ]?.[1]?.[2] ?? " -"}
                          </td>
                        </div>
                          )
                        }
                        
                      </td>
                      {/* OTM  */}
                      {/* <td className="py-1 w-[15%] p-2 rounded-md">{item.otm}</td> */}
                      <td className="py-2 p-2 text-center">
                        {(rowLoading[index] || balanceLoading)
                          ? (
                            <Skeleton baseColor="#424242" highlightColor="#202020" className="h-[30.6px] py-1 rounded-md" />
                          )
                          : (
                        <div className="flex h-[30.6px]">
                          <td className="w-[100%] bg-[#424242] py-1 px-2 rounded-l-md">
                            {/* {item.strike} */}
                            {responseData?.[
                              selectedOptionArray[index]
                            ]?.[2]?.[0] ?? " -"}
                          </td>
                          <td
                            id={`${index}-second-column`}
                            className={`w-full py-1 px-2 
                          
                          ${
                            responseData?.[selectedOptionArray[index]]?.[2]?.[2]
                              ? "cursor-pointer"
                              : "cursor-not-allowed"
                          } 
                          ${
                            wishlistLoading ? "pointer-events-none" : ""
                          }
                          ${
                              selectedContract[index]?.[2]
                                ? "text-tertiary bg-black "
                                : ""
                            }  
                          ${
                            wishlistData?.[selectedOptionArray[index]]?.[2] ===
                            "call"
                              ? responseData?.[
                                  selectedOptionArray[index]
                                ]?.[2]?.[0] <
                                responseData?.[
                                  selectedOptionArray[index]
                                ]?.[0]?.[0]
                                ? " bg-[#00F4FF] text-black border border-[#00F4FF]  font-semibold"
                                : responseData?.[
                                    selectedOptionArray[index]
                                  ]?.[2]?.[0] >
                                  responseData?.[
                                    selectedOptionArray[index]
                                  ]?.[0]?.[0]
                                ? " bg-[#FFE664] border border-[#FFE664]  text-black font-semibold"
                                : "bg-[#424242]  border  border-[#424242]  text-white"
                              : responseData?.[
                                  selectedOptionArray[index]
                                ]?.[2]?.[0] >
                                responseData?.[
                                  selectedOptionArray[index]
                                ]?.[0]?.[0]
                              ? " bg-[#00F4FF] text-black border border-[#00F4FF]  font-semibold"
                              : responseData?.[
                                  selectedOptionArray[index]
                                ]?.[2]?.[0] <
                                responseData?.[
                                  selectedOptionArray[index]
                                ]?.[0]?.[0]
                              ? " bg-[#FFE664] border border-[#FFE664]  text-black font-semibold"
                              : "bg-[#424242]  border  border-[#424242]  text-white"
                          }
                          `}
                            title={`${
                              !responseData?.[
                                selectedOptionArray[index]
                              ]?.[2]?.[2]
                                ? "Quantity is 0"
                                : ""
                            }`}
                            onClick={() => {
                              if (
                                responseData?.[
                                  selectedOptionArray[index]
                                ]?.[2]?.[2]
                              ) {
                                setSelectedContract((prevValues) => ({
                                  ...prevValues,
                                  [index]: prevValues[index]?.[2]
                                    ? null
                                    : {
                                        [2]: {
                                          strike:
                                            responseData?.[
                                              selectedOptionArray[index]
                                            ]?.[2]?.[0],
                                          quote:
                                            responseData?.[
                                              selectedOptionArray[index]
                                            ]?.[2]?.[1],
                                          qty: responseData?.[
                                            selectedOptionArray[index]
                                          ]?.[2]?.[2],
                                        },
                                      },
                                }));
                                subDataForBtoCreate.key = 2;
                                subDataForBtoCreate.index = index;
                              }
                            }}
                          >
                            {/* {item.quote} */}
                            {responseData?.[
                              selectedOptionArray[index]
                            ]?.[2]?.[1] ?? " -"}
                          </td>
                          <td className="w-[100%] bg-[#424242] py-1 px-2 rounded-r-md">
                            {/* {item.qty} */}
                            {responseData?.[
                              selectedOptionArray[index]
                            ]?.[2]?.[2] ?? " -"}
                          </td>
                        </div>
                          )}
                      </td>
                      {/* ITM  */}
                      {/* <td className="py-1 w-[15%] p-2 rounded-md">{item.itm}</td> */}
                      <td className="py-2 p-2 text-center">
                        {(rowLoading[index] || balanceLoading)
                          ? (
                            <Skeleton baseColor="#424242" highlightColor="#202020" className="h-[30.6px] py-1 rounded-md" />
                          )
                          : (
                        <div className="flex h-[30.6px]">
                          <td className="w-[100%] bg-[#424242] py-1 px-2 rounded-l-md">
                            {/* {item.strike} */}
                            {responseData?.[
                              selectedOptionArray[index]
                            ]?.[3]?.[0] ?? " -"}
                          </td>
                          <td
                            id={`${index}-third-column`}
                            className={`w-full py-1 px-2 
                          
                          ${
                            responseData?.[selectedOptionArray[index]]?.[3]?.[2]
                              ? "cursor-pointer"
                              : "cursor-not-allowed"
                          } ${
                              selectedContract[index]?.[3]
                                ? "text-tertiary bg-black "
                                : ""
                            }   
                          ${
                            wishlistData?.[selectedOptionArray[index]]?.[2] ===
                            "call"
                              ? responseData?.[
                                  selectedOptionArray[index]
                                ]?.[3]?.[0] <
                                responseData?.[
                                  selectedOptionArray[index]
                                ]?.[0]?.[0]
                                ? " bg-[#00F4FF] border border-[#00F4FF] text-black font-semibold"
                                : responseData?.[
                                    selectedOptionArray[index]
                                  ]?.[3]?.[0] >
                                  responseData?.[
                                    selectedOptionArray[index]
                                  ]?.[0]?.[0]
                                ? " bg-[#FFE664] border border-[#FFE664] text-black font-semibold"
                                : "bg-[#424242]  border border-[#424242]  text-white"
                              : responseData?.[
                                  selectedOptionArray[index]
                                ]?.[3]?.[0] >
                                responseData?.[
                                  selectedOptionArray[index]
                                ]?.[0]?.[0]
                              ? " bg-[#00F4FF] border border-[#00F4FF] text-black font-semibold"
                              : responseData?.[
                                  selectedOptionArray[index]
                                ]?.[3]?.[0] <
                                responseData?.[
                                  selectedOptionArray[index]
                                ]?.[0]?.[0]
                              ? " bg-[#FFE664] border border-[#FFE664] text-black font-semibold"
                              : "bg-[#424242]  border border-[#424242]  text-white"
                          }
                          `}
                            title={`${
                              !responseData?.[
                                selectedOptionArray[index]
                              ]?.[3]?.[2]
                                ? "Quantity is 0"
                                : ""
                            }`}
                            onClick={() => {
                              if (
                                responseData?.[
                                  selectedOptionArray[index]
                                ]?.[3]?.[2]
                              ) {
                                setSelectedContract((prevValues) => ({
                                  ...prevValues,
                                  [index]: prevValues[index]?.[3]
                                    ? null
                                    : {
                                        [3]: {
                                          strike:
                                            responseData?.[
                                              selectedOptionArray[index]
                                            ]?.[3]?.[0],
                                          quote:
                                            responseData?.[
                                              selectedOptionArray[index]
                                            ]?.[3]?.[1],
                                          qty: responseData?.[
                                            selectedOptionArray[index]
                                          ]?.[3]?.[2],
                                        },
                                      },
                                }));
                                subDataForBtoCreate.key = 3;
                                subDataForBtoCreate.index = index;
                              }
                            }}
                          >
                            {/* {item.quote} */}
                            {responseData?.[
                              selectedOptionArray[index]
                            ]?.[3]?.[1] ?? " -"}
                          </td>
                          <td className="w-[100%] bg-[#424242] py-1 px-2 rounded-r-md">
                            {/* {item.qty} */}
                            {responseData?.[
                              selectedOptionArray[index]
                            ]?.[3]?.[2] ?? " -"}
                          </td>
                        </div>)}
                      </td>
                      {/* ---- */}
                      <td className="py-2 p-2 text-center">
                      {(rowLoading[index] || balanceLoading)
                          ? (
                            <Skeleton baseColor="#424242" highlightColor="#202020" className="h-[30.6px] py-1 rounded-md" />
                          )
                          : (
                        <div className="flex h-[30.6px]">
                          <td className="w-[100%] bg-[#424242] py-1 px-2 rounded-l-md">
                            {/* {item.strike} */}
                            {responseData?.[
                              selectedOptionArray[index]
                            ]?.[4]?.[0] ?? " -"}
                          </td>
                          <td
                            id={`${index}-fourth-column`}
                            className={`w-full py-1 px-2 
                          
                          ${
                            responseData?.[selectedOptionArray[index]]?.[4]?.[2]
                              ? "cursor-pointer"
                              : "cursor-not-allowed"
                          } ${
                              selectedContract[index]?.[4]
                                ? "text-tertiary bg-black "
                                : ""
                            }  
                            
                          ${
                            wishlistData?.[selectedOptionArray[index]]?.[2] ===
                            "call"
                              ? responseData?.[
                                  selectedOptionArray[index]
                                ]?.[4]?.[0] <
                                responseData?.[
                                  selectedOptionArray[index]
                                ]?.[0]?.[0]
                                ? " bg-[#00F4FF] border border-[#00F4FF]  text-black font-semibold"
                                : responseData?.[
                                    selectedOptionArray[index]
                                  ]?.[4]?.[0] >
                                  responseData?.[
                                    selectedOptionArray[index]
                                  ]?.[0]?.[0]
                                ? " bg-[#FFE664] border border-[#FFE664] text-black font-semibold"
                                : "bg-[#424242]  border border-[#424242]  text-white"
                              : responseData?.[
                                  selectedOptionArray[index]
                                ]?.[4]?.[0] >
                                responseData?.[
                                  selectedOptionArray[index]
                                ]?.[0]?.[0]
                              ? " bg-[#00F4FF] border border-[#00F4FF]  text-black font-semibold"
                              : responseData?.[
                                  selectedOptionArray[index]
                                ]?.[4]?.[0] <
                                responseData?.[
                                  selectedOptionArray[index]
                                ]?.[0]?.[0]
                              ? " bg-[#FFE664] border border-[#FFE664] text-black font-semibold"
                              : "bg-[#424242]  border border-[#424242]  text-white"
                          }
                          `}
                            title={`${
                              !responseData?.[
                                selectedOptionArray[index]
                              ]?.[4]?.[2]
                                ? "Quantity is 0"
                                : ""
                            }
                          
                          `}
                            onClick={() => {
                              if (
                                responseData?.[
                                  selectedOptionArray[index]
                                ]?.[4]?.[2]
                              ) {
                                setSelectedContract((prevValues) => ({
                                  ...prevValues,
                                  [index]: prevValues[index]?.[4]
                                    ? null
                                    : {
                                        [4]: {
                                          strike:
                                            responseData?.[
                                              selectedOptionArray[index]
                                            ]?.[4]?.[0],
                                          quote:
                                            responseData?.[
                                              selectedOptionArray[index]
                                            ]?.[4]?.[1],
                                          qty: responseData?.[
                                            selectedOptionArray[index]
                                          ]?.[4]?.[2],
                                        },
                                      },
                                }));
                                subDataForBtoCreate.key = 4;
                                subDataForBtoCreate.index = index;
                              }
                            }}
                          >
                            {/* {item.quote} */}
                            {responseData?.[
                              selectedOptionArray[index]
                            ]?.[4]?.[1] ?? " -"}
                          </td>
                          <td className="w-[100%] bg-[#424242] py-1 px-2 rounded-r-md">
                            {/* {item.qty} */}
                            {responseData?.[
                              selectedOptionArray[index]
                            ]?.[4]?.[2] ?? " -"}
                          </td>
                        </div>)}
                      </td>
                      <td className={`text-black ${wishlistLoading ? "pointer-events-none" : ""}`}>
                        <Input
                          addonAfter={
                            <Tooltip
                              title={
                                !messageLoading[index] &&
                                selectedContract[index]
                                  ? `Click the button to send this message - ${message} to the above discord channel`
                                  : messageLoading[index]
                                  ? "Buying..."
                                  : "Please select the quantity"
                              }
                            >
                              {messageLoading[index] ? (
                                <AiOutlineLoading3Quarters className="text-[#5865F2]  text-lg animate-spin" />
                              ) : (
                                <IoIosSend
                                  onClick={() =>
                                    !messageLoading[index] &&
                                    selectedContract[index] &&
                                    handleSendMessage(message, index, key)
                                  }
                                  className={`text-[#5865F2]  text-lg ${
                                    !messageLoading[index] &&
                                    selectedContract[index]
                                      ? "cursor-pointer"
                                      : "cursor-not-allowed"
                                  } `}
                                />
                              )}
                            </Tooltip>
                          }
                          type="text"
                          value={quantities[index]}
                          style={{
                            width: "90px",
                          }}
                          className=" text-black  rounded-md"
                          onChange={(e) =>
                            setQuantities((prevValues) => ({
                              ...prevValues,
                              [index]: e.target.value,
                            }))
                          }
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div className="mt- pb-12 text-white flex items-center justify-center h-[250px]">
              <Loader />
            </div>
          )}

          {/* <div className="flex items-center gap-4 mt-4 pl-2">
            <div className="text-body">
              Create STC Row from BTO Alert {"("}does not send to Discord{")"}
            </div>
            <input
              type="text"
              id="bto-quantity"
              placeholder="Enter your quantity number"
              className="bg-[#424242] py-1 px-2 text-white rounded-md"
            />
            <button
              onClick={() => handleSendMessage(null)}
              className={`px-4 py-1 font-medium bg-tertiary rounded-md flex items-center gap-1 ${
                selectedContract[subDataForBtoCreate.index] && !generateLoading
                  ? "cursor-pointer opacity-100"
                  : "cursor-not-allowed opacity-40"
              }`}
              title={
                !selectedContract[subDataForBtoCreate.index]
                  ? "Please select the contract first"
                  : ""
              }
              disabled={generateLoading}
            >
              Generate{" "}
              {generateLoading ? (
                <AiOutlineLoading3Quarters className="animate-spin" />
              ) : (
                ""
              )}{" "}
            </button>
          </div> */}

                <BtoData />
              </div>
            </div>
            {/* Body for smaller screens  */}
            <div
              className="lg:hidden px-4 min-h-screen w-full bg-cover bg-center bg-no-repeat pt-4"
              style={{ backgroundImage: `url(${bgPhoneTop})` }}
              onClick={() => setIsOpen(false)}
            >
              {/* Checkbox and account selection  */}
              {/* <div className="flex ml-auto w-fit px- 4 gap-2 text-tertiary [#C4C4C4]  border border-[#646464] rounded-lg text-center px-2 py-1 mt-6">
                  Current balance:
                  <div className="text-white">{currentBalance}</div>
              </div> */}
              <DashHeader />

              <div className="flex flex-col gap-4 min-[1380px]:flex-row justify-between mt-6 mb-6">
                <div className="flex gap-2 font-medium text-[#7D7D7D]">
                  <div>
                    <div className="flex gap-4 mt-2">
                      <div className="w-[30% ]">Trade $ :</div>
                      <input
                        type="number"
                        className="bg-[#555555] px-2 py-[2px] outline-none text-white w-[130px] rounded-lg"
                        defaultValue={wishlistData?.balance}
                        value={tempBalance}
                        onChange={(e) => setTempBalance(e.target.value)}
                        onBlur={(e) => handleBalanceChange(e)}
                        onKeyDown={(e) => e.key === "Enter" && e.target.blur()}
                      />
                    </div>

              <DiscordInputs appendText={appendText} setAppendText={changeText}/>
            </div>
          </div>
        </div>

        {!dataLoading ? (
          sampleData.map((item, index) => {
            const key = selectedContract[index]
              ? Object.keys(selectedContract[index])[0]
              : null;
            const message = selectedContract[index]
              ? `${name}:- BTO ${
                  quantities[index] || selectedContract[index][key].qty
                } ${selectedOptionArray[index]} ${
                  selectedContract[index][key].strike
                }${contractType[index] || "C"} ${
                  wishlistData?.[selectedOptionArray[index]]?.[1]
                } @${selectedContract[index][key].quote}  ${appendText}`
              : "";

            return (
              <div
                className="bg-[#202020] text-base rounded-lg px-2 py-4 mt-4 text-white"
                key={index}
              >
                {/* Table Row 1 */}
                <div className="flex justify-between text-body">
                  <div className="w-[31%] text-center">Ticker</div>
                  <div className="w-[31%] text-center">Current Price</div>
                  <div className="w-[31%] text-center">Near</div>
                </div>
                <div className="flex justify-between mt-4">
                  <div className="w-[31%]">
                    <div
                      className="bg-[#424242] cursor-pointer text-center w-full text-white py-[2px] rounded-md"
                      // value={selectedOptionArray[index]}
                      onClick={() => handleTickerChange(index)}
                    >
                      {selectedOptionArray[index]}
                      {/* {tickerList?.map((value, tickerIndex) => (
                            <option value={value} key={tickerIndex}>
                              {value}
                            </option>
                          ))} */}
                    </div>
                  </div>
                  <div className="w-[31%]">
                    {((rowLoading[index] && tickerLoading) || balanceLoading)
                          ? (
                            <Skeleton baseColor="#424242" highlightColor="#202020" className="h-[30.6px] py-1 rounded-md" />
                          )
                          : (
                    <div
                      type="text"
                      // value={responseData?.[selectedOptionArray[index]] && responseData?.[selectedOptionArray[index]]?.[3] ?("$" + responseData?.[selectedOptionArray[index]]?.[0][0]) : "$  -"}
                      className={`px-2 w-full bg-[#424242] py-1 rounded-md text-center ${wishlistLoading ? "pointer-events-none" : ""}`}
                    >
                      {responseData?.[selectedOptionArray[index]] &&
                      responseData?.[selectedOptionArray[index]]?.[0]
                        ? responseData?.[selectedOptionArray[index]]?.[0][0]
                        : "-"}
                    </div>)}
                  </div>
                  <div className={`w-[31%] ${wishlistLoading ? "pointer-events-none" : ""}`}>
                    {((rowLoading[index] && tickerLoading) || balanceLoading)
                          ? (
                            <Skeleton baseColor="#424242" highlightColor="#202020" className="h-[30.6px] py-1 rounded-md" />
                          )
                          : (
                    <input
                      type="number"
                      id="near-price"
                      placeholder={
                        wishlistData?.[selectedOptionArray[index]]?.[0]
                      }
                      className="px-2 w-full bg-[#424242] py-1 rounded-md text-center"
                      step="any"
                      onBlur={(e) => handleNearChange(e, index)}
                      onKeyDown={(e) => e.key === "Enter" && e.target.blur()}
                      onInput={(e) => {
                        const inputValue = e.target.value;
                        const parsedValue = parseFloat(inputValue);
                        if (isNaN(parsedValue)) {
                          console.error(
                            "Invalid input. Please enter a valid number."
                          );
                        } else {
                        }
                      }}
                    />)}
                  </div>
                </div>

                {/* Table Row 2 */}
                <div className={`flex justify-center gap-4 text-body mt-6 ${wishlistLoading ? "pointer-events-none" : ""}`}>
                  <div className="w-[31%] text-center">Exp. Date</div>
                  <div className="w-[31%] text-center">Call | Put</div>
                  <div className="w-[31%] text-center">Qty</div>
                </div>

                <div className={`w-full flex justify-center gap-4 mt-4  ${wishlistLoading ? "pointer-events-none" : ""}`}>
                  <div className="w-[31%]">
                    {((rowLoading[index] && tickerLoading) || balanceLoading)
                          ? (
                            <Skeleton baseColor="#424242" highlightColor="#202020" className="h-[30.6px] py-1 rounded-md" />
                          )
                          : (
                    <select
                      onChange={(e) => handleDateChange(e.target.value, index)}
                      onKeyDown={(e) => e.key === "Enter" && e.target.blur()}
                      className="w-full bg-[#424242] text-white text-center py-1 rounded-md"
                    >
                      {expirationDatesOfTicker[selectedOptionArray[index]]
                        ?.filter(
                          (d) =>
                            new Date(d).getFullYear() ==
                            new Date().getFullYear()
                        )
                        .map((d, i) => (
                          <option value={d} key={i}>
                            {d}
                          </option>
                        ))}
                    </select>)}
                  </div>
                  <div className="w-[31%] flex items-center justify-center">
                    {((rowLoading[index] && tickerLoading) || balanceLoading)
                          ? (
                            <Skeleton baseColor="#424242" highlightColor="#202020" className="h-[30.6px] py-1 rounded-md" />
                          )
                          : (
                    <label className="inline-flex items-center cursor-pointer mx-auto z-0">
                      <input
                        type="checkbox"
                        className="sr-only peer"
                        onChange={(e) => handleCallPutChange(e, index)}
                      />
                      <div
                        className="
                      relative w-11 h-6 bg-[#5DBC55] peer-focus:outline-none peer-focus:ring-4 
                      peer-focus:ring-blue-300 dark:peer-focus:ring-white rounded-full peer 
                      dark:bg-[#5DBC55] peer-checked:after:translate-x-full 
                      rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white 
                      after:content-[''] after:absolute after:top-[2px] after:start-[2px] 
                      after:bg-white after:border-gray-300 after:border after:rounded-full 
                      after:h-5 after:w-5 after:transition-all dark:border-gray-600 
                      peer-checked:bg-[#E83838] z-0"
                      />
                    </label>
                          )}
                  </div>
                  <div className="w-[31%]">
                    {((rowLoading[index] && tickerLoading) || balanceLoading)
                          ? (
                            <Skeleton baseColor="#424242" highlightColor="#202020" className="h-[30.6px] py-1 rounded-md" />
                          )
                          : (
                    <Input
                      addonAfter={
                        <Tooltip
                          title={
                            !messageLoading[index] && selectedContract[index]
                              ? `Click the button to send this message - ${message} to the above discord channel`
                              : messageLoading[index]
                              ? "Buying.."
                              : "Please select the quantity"
                          }
                        >
                          {messageLoading[index] ? (
                            <AiOutlineLoading3Quarters className="text-[#5865F2]  text-lg animate-spin" />
                          ) : (
                            <IoIosSend
                              onClick={() =>
                                !messageLoading[index] &&
                                selectedContract[index] &&
                                handleSendMessage(message, index, key)
                              }
                              className={`text-[#5865F2]  text-lg ${
                                !messageLoading[index] &&
                                selectedContract[index]
                                  ? "cursor-pointer"
                                  : "cursor-not-allowed"
                              } `}
                            />
                          )}
                        </Tooltip>
                      }
                      type="text"
                      value={quantities[index]}
                      style={{
                        width: "90px",
                      }}
                      className=" text-black  rounded-md"
                      onChange={(e) =>
                        setQuantities((prevValues) => ({
                          ...prevValues,
                          [index]: e.target.value,
                        }))
                      }
                    />)}
                  </div>
                </div>

                <div className="flex flex-col gap-4 items-center mt-6 text-base">
                  <div className="w-full">
                    <div className="flex text-tertiary text-center">
                      <div className="w-1/3">Strike</div>
                      <div className="w-1/3">Quote</div>
                      <div className="w-1/3">Qty</div>
                    </div>
                    {(rowLoading[index] || balanceLoading)
                          ? (
                            <div className="px-2 mt-2 rounded-xl">
                              <Skeleton baseColor="#424242" highlightColor="#202020" className="h-[33.6px] py-1 mt- 2 " />
                            </div>
                          )
                          : (
                    <div className="flex items-center gap-1 text-center px-2 text-white mt-2">
                      <div className="w-1/3 py-1 bg-[#424242] rounded-l-md">
                        {responseData?.[selectedOptionArray[index]]?.[1]?.[0] ??
                          " -"}
                      </div>
                      <div
                        id={`${index}-first-column`}
                        className={`w-1/3 py-1 bg-[#424242]
                                              
                      ${
                        responseData?.[selectedOptionArray[index]]?.[1]?.[2]
                          ? "cursor-pointer"
                          : "cursor-not-allowed"
                      } ${
                          selectedContract[index]?.[1]
                            ? "text-tertiary bg-black "
                            : ""
                        } 
                      ${
                        wishlistData?.[selectedOptionArray[index]]?.[2] ===
                        "call"
                          ? responseData?.[
                              selectedOptionArray[index]
                            ]?.[1]?.[0] <
                            responseData?.[selectedOptionArray[index]]?.[0]?.[0]
                            ? " bg-[#00f2ff] border border-[#00F4FF] text-black font-semibold"
                            : responseData?.[
                                selectedOptionArray[index]
                              ]?.[1]?.[0] >
                              responseData?.[
                                selectedOptionArray[index]
                              ]?.[0]?.[0]
                            ? " bg-[#FFE664] border border-[#FFE664] text-black font-semibold"
                            : "bg-[#424242] border  border-[#424242]  text-white"
                          : responseData?.[
                              selectedOptionArray[index]
                            ]?.[1]?.[0] >
                            responseData?.[selectedOptionArray[index]]?.[0]?.[0]
                          ? " bg-[#00F4FF] border border-[#00F4FF] text-black font-semibold"
                          : responseData?.[
                              selectedOptionArray[index]
                            ]?.[1]?.[0] <
                            responseData?.[selectedOptionArray[index]]?.[0]?.[0]
                          ? " bg-[#FFE664] border border-[#FFE664] text-black font-semibold"
                          : "bg-[#424242] border  border-[#424242]  text-white"
                      }
                    `}
                        title={`${
                          !responseData?.[selectedOptionArray[index]]?.[1]?.[2]
                            ? "Quantity is 0"
                            : ""
                        }`}
                        onClick={() => {
                          if (
                            responseData?.[selectedOptionArray[index]]?.[1]?.[2]
                          ) {
                            setSelectedContract((prevValues) => ({
                              ...prevValues,
                              [index]: prevValues[index]?.[1]
                                ? null
                                : {
                                    [1]: {
                                      strike:
                                        responseData?.[
                                          selectedOptionArray[index]
                                        ]?.[1]?.[0],
                                      quote:
                                        responseData?.[
                                          selectedOptionArray[index]
                                        ]?.[1]?.[1],
                                      qty: responseData?.[
                                        selectedOptionArray[index]
                                      ]?.[1]?.[2],
                                    },
                                  },
                            }));
                          }
                        }}
                      >
                        {responseData?.[selectedOptionArray[index]]?.[1]?.[1] ??
                          " -"}
                      </div>
                      <div className="w-1/3 py-1 bg-[#424242] rounded-r-md">
                        {responseData?.[selectedOptionArray[index]]?.[1]?.[2] ??
                          " -"}
                      </div>
                    </div>)}
                  </div>
                  <div className="w-full">
                  {(rowLoading[index] || balanceLoading)
                          ? (
                            <div className="px-2 mt-2 rounded-xl">
                              <Skeleton baseColor="#424242" highlightColor="#202020" className="h-[33.6px] py-1 mt- 2 " />
                            </div>
                          )
                          : (
                    <div className="flex gap-1 text-center px-2 text-white mt-2">
                      <div className="w-1/3 py-1 bg-[#424242] rounded-l-md">
                        {responseData?.[selectedOptionArray[index]]?.[2]?.[0] ??
                          " -"}
                      </div>
                      <div
                        id={`${index}-second-column`}
                        className={`w-1/3 py-1 bg-[#424242]
                    
                    ${
                      responseData?.[selectedOptionArray[index]]?.[2]?.[2]
                        ? "cursor-pointer"
                        : "cursor-not-allowed"
                    } ${
                          selectedContract[index]?.[2]
                            ? "text-tertiary bg-black"
                            : ""
                        }  
                    ${
                      wishlistData?.[selectedOptionArray[index]]?.[2] === "call"
                        ? responseData?.[selectedOptionArray[index]]?.[2]?.[0] <
                          responseData?.[selectedOptionArray[index]]?.[0]?.[0]
                          ? " bg-[#00F4FF] border border-[#00F4FF] text-black font-semibold"
                          : responseData?.[
                              selectedOptionArray[index]
                            ]?.[2]?.[0] >
                            responseData?.[selectedOptionArray[index]]?.[0]?.[0]
                          ? " bg-[#FFE664] border border-[#FFE664] text-black font-semibold"
                          : "bg-[#424242] border  border-[#424242]  text-white"
                        : responseData?.[selectedOptionArray[index]]?.[2]?.[0] >
                          responseData?.[selectedOptionArray[index]]?.[0]?.[0]
                        ? " bg-[#00F4FF] border border-[#00F4FF] text-black font-semibold"
                        : responseData?.[selectedOptionArray[index]]?.[2]?.[0] <
                          responseData?.[selectedOptionArray[index]]?.[0]?.[0]
                        ? " bg-[#FFE664] border border-[#FFE664] text-black font-semibold"
                        : "bg-[#424242] border  border-[#424242]  text-white"
                    }
                    `}
                        title={`${
                          !responseData?.[selectedOptionArray[index]]?.[2]?.[2]
                            ? "Quantity is 0"
                            : ""
                        }`}
                        onClick={() => {
                          if (
                            responseData?.[selectedOptionArray[index]]?.[2]?.[2]
                          ) {
                            responseData?.[
                              selectedOptionArray[index]
                            ]?.[2]?.[2] &&
                              setSelectedContract((prevValues) => ({
                                ...prevValues,
                                [index]: prevValues[index]?.[2]
                                  ? null
                                  : {
                                      [2]: {
                                        strike:
                                          responseData?.[
                                            selectedOptionArray[index]
                                          ]?.[2]?.[0],
                                        quote:
                                          responseData?.[
                                            selectedOptionArray[index]
                                          ]?.[2]?.[1],
                                        qty: responseData?.[
                                          selectedOptionArray[index]
                                        ]?.[2]?.[2],
                                      },
                                    },
                              }));
                          }
                        }}
                      >
                        {responseData?.[selectedOptionArray[index]]?.[2]?.[1] ??
                          " -"}
                      </div>
                      <div className="w-1/3 py-1 bg-[#424242] rounded-r-md">
                        {responseData?.[selectedOptionArray[index]]?.[2]?.[2] ??
                          " -"}
                      </div>
                    </div>)}
                  </div>
                </div>

                <div className="flex flex-col gap-4 mt-4">
                {(rowLoading[index] || balanceLoading)
                          ? (
                            <div className="px-2 mt-2 rounded-xl">
                              <Skeleton baseColor="#424242" highlightColor="#202020" className="h-[33.6px] py-1 mt- 2 " />
                            </div>
                          )
                          : (
                  <div className="w-full">
                    <div className="flex gap-1 text-center px-2 text-white mt-2">
                      <div className="w-1/3 py-1 bg-[#424242] rounded-l-md">
                        {responseData?.[selectedOptionArray[index]]?.[3]?.[0] ??
                          " -"}
                      </div>
                      <div
                        id={`${index}-third-column`}
                        className={`w-1/3 py-1 bg-[#424242]
                    
                    ${
                      responseData?.[selectedOptionArray[index]]?.[3]?.[2]
                        ? "cursor-pointer"
                        : "cursor-not-allowed"
                    } ${
                          selectedContract[index]?.[3]
                            ? "text-tertiary bg-black"
                            : ""
                        }   
                    ${
                      wishlistData?.[selectedOptionArray[index]]?.[2] === "call"
                        ? responseData?.[selectedOptionArray[index]]?.[3]?.[0] <
                          responseData?.[selectedOptionArray[index]]?.[0]?.[0]
                          ? " bg-[#00F4FF] border border-[#00F4FF] text-black font-semibold"
                          : responseData?.[
                              selectedOptionArray[index]
                            ]?.[3]?.[0] >
                            responseData?.[selectedOptionArray[index]]?.[0]?.[0]
                          ? " bg-[#FFE664] border border-[#FFE664] text-black font-semibold"
                          : "bg-[#424242] border  border-[#424242]  text-white"
                        : responseData?.[selectedOptionArray[index]]?.[3]?.[0] >
                          responseData?.[selectedOptionArray[index]]?.[0]?.[0]
                        ? " bg-[#00F4FF] border border-[#00F4FF] text-black font-semibold"
                        : responseData?.[selectedOptionArray[index]]?.[3]?.[0] <
                          responseData?.[selectedOptionArray[index]]?.[0]?.[0]
                        ? " bg-[#FFE664] border border-[#FFE664] text-black font-semibold"
                        : "bg-[#424242] border  border-[#424242]  text-white"
                    }
                    `}
                        title={`${
                          !responseData?.[selectedOptionArray[index]]?.[3]?.[2]
                            ? "Quantity is 0"
                            : ""
                        }`}
                        onClick={() => {
                          if (
                            responseData?.[selectedOptionArray[index]]?.[3]?.[2]
                          ) {
                            responseData?.[
                              selectedOptionArray[index]
                            ]?.[3]?.[2] &&
                              setSelectedContract((prevValues) => ({
                                ...prevValues,
                                [index]: prevValues[index]?.[3]
                                  ? null
                                  : {
                                      [3]: {
                                        strike:
                                          responseData?.[
                                            selectedOptionArray[index]
                                          ]?.[3]?.[0],
                                        quote:
                                          responseData?.[
                                            selectedOptionArray[index]
                                          ]?.[3]?.[1],
                                        qty: responseData?.[
                                          selectedOptionArray[index]
                                        ]?.[3]?.[2],
                                      },
                                    },
                              }));
                          }
                        }}
                      >
                        {responseData?.[selectedOptionArray[index]]?.[3]?.[1] ??
                          " -"}
                      </div>
                      <div className="w-1/3 py-1 bg-[#424242] rounded-r-md">
                        {responseData?.[selectedOptionArray[index]]?.[3]?.[2] ??
                          " -"}
                      </div>
                    </div>
                  </div>)}
                  {(rowLoading[index] || balanceLoading)
                          ? (
                            <div className="px-2 mt-2 rounded-xl">
                              <Skeleton baseColor="#424242" highlightColor="#202020" className="h-[33.6px] py-1 mt- 2 " />
                            </div>
                          )
                          : (
                  <div className="w-full">
                    <div className="flex gap-1 text-center px-2 text-white mt-2">
                      <div className="w-1/3 py-1 bg-[#424242] rounded-l-md">
                        {responseData?.[selectedOptionArray[index]]?.[4]?.[0] ??
                          " -"}
                      </div>
                      <div
                        id={`${index}-fourth-column`}
                        className={`w-1/3 py-1 bg-[#424242]
                    
                    ${
                      responseData?.[selectedOptionArray[index]]?.[4]?.[2]
                        ? "cursor-pointer"
                        : "cursor-not-allowed"
                    } ${
                          selectedContract[index]?.[4]
                            ? "text-tertiary bg-black"
                            : ""
                        }  
                      
                    ${
                      wishlistData?.[selectedOptionArray[index]]?.[2] === "call"
                        ? responseData?.[selectedOptionArray[index]]?.[4]?.[0] <
                          responseData?.[selectedOptionArray[index]]?.[0]?.[0]
                          ? " bg-[#00F4FF] border border-[#00F4FF] text-black font-semibold"
                          : responseData?.[
                              selectedOptionArray[index]
                            ]?.[4]?.[0] >
                            responseData?.[selectedOptionArray[index]]?.[0]?.[0]
                          ? " bg-[#FFE664] border border-[#FFE664] text-black font-semibold"
                          : "bg-[#424242]  border  border-[#424242] text-white"
                        : responseData?.[selectedOptionArray[index]]?.[4]?.[0] >
                          responseData?.[selectedOptionArray[index]]?.[0]?.[0]
                        ? " bg-[#00F4FF] border border-[#00F4FF] text-black font-semibold"
                        : responseData?.[selectedOptionArray[index]]?.[4]?.[0] <
                          responseData?.[selectedOptionArray[index]]?.[0]?.[0]
                        ? " bg-[#FFE664] border border-[#FFE664] text-black font-semibold"
                        : "bg-[#424242] border border-[#424242]  text-white"
                    }
                    `}
                        title={`${
                          !responseData?.[selectedOptionArray[index]]?.[4]?.[2]
                            ? "Quantity is 0"
                            : ""
                        }
                    
                    `}
                        onClick={() => {
                          if (
                            responseData?.[selectedOptionArray[index]]?.[4]?.[2]
                          ) {
                            responseData?.[
                              selectedOptionArray[index]
                            ]?.[4]?.[2] &&
                              setSelectedContract((prevValues) => ({
                                ...prevValues,
                                [index]: prevValues[index]?.[4]
                                  ? null
                                  : {
                                      [4]: {
                                        strike:
                                          responseData?.[
                                            selectedOptionArray[index]
                                          ]?.[4]?.[0],
                                        quote:
                                          responseData?.[
                                            selectedOptionArray[index]
                                          ]?.[4]?.[1],
                                        qty: responseData?.[
                                          selectedOptionArray[index]
                                        ]?.[4]?.[2],
                                      },
                                    },
                              }));
                          }
                        }}
                      >
                        {responseData?.[selectedOptionArray[index]]?.[4]?.[1] ??
                          " -"}
                      </div>
                      <div className="w-1/3 py-1 bg-[#424242] rounded-r-md">
                        {responseData?.[selectedOptionArray[index]]?.[4]?.[2] ??
                          " -"}
                      </div>
                    </div>
                  </div>)}
                </div>
              </div>
            );
          })
        ) : (
          <div className="mt- pb-12 text-white flex items-center justify-center h-[250px]">
            <Loader />
          </div>
        )}
        <BtoData />
      </div>
    </div>
  );
};

export default Dashboard;
