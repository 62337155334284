import React from 'react';
import PropTypes from 'prop-types';
import PnLTileMap from './PnLTileMap';
import back from "../../assets/icons/back.png";
import next from "../../assets/icons/next.png";
import undo from "../../assets/icons/undo.png";

const PnlHistoryTiledPanelLarge = (props) => {

  const { pnlMeterType, setPnlMeterType, currentMonth, onPrevMonth, onNextMonth, onResetMonth} = props;
  return (
    <div className="relative bg-[#202020] rounded-3xl px-4 py-4 w-1/2">
      <div className="flex justify-between items-center gap-4">
        <div className="flex gap-4 items-center text-[#A1A1A1] pl-4 text-lg">
          <div>Daily PnL (large)</div>
          <div className="flex gap- text-base items-center">
            <div
              onClick={() => setPnlMeterType("percentage")}
              className={` rounded-l-md border w-16 text-center border-[#A1A1A1] cursor-pointer px-3 ${pnlMeterType === "percentage"
                ? "bg-tertiary text-black font-bold border-tertiary drop-shadow-xl"
                : "bg-[#]"
                }`}
            >
              PnL%
            </div>
            <div
              onClick={() => setPnlMeterType("pnl")}
              className={` rounded-r-md border w-16 text-center border-[#A1A1A1] cursor-pointer px-3 ${pnlMeterType === "pnl"
                ? "bg-tertiary text-black font-bold border-tertiary drop-shadow-xl"
                : "bg-[#]"
                }`}
            >
              PnL
            </div>
          </div>
        </div>
        <div className="flex flex-row text-[#A1A1A1]">
          <div className="flex pl-4 text-lg">{currentMonth}</div>
          <div className="controls flex flex-row gap-1 mx-2">
            <button
              disabled={currentMonth === 'January'}
              className="text-lg rounded-l-md rounded-r-md border text-center cursor-pointer px-3 bg-tertiary text-black font-bold border-tertiary drop-shadow-xl"
              onClick={onPrevMonth}>
                <img src={back} alt="back" className="size-5" />
            </button>
            <button 
              disabled={currentMonth === 'December'} 
              className="text-lg rounded-l-md rounded-r-md border text-center cursor-pointer px-3 bg-tertiary text-black font-bold border-tertiary drop-shadow-xl" 
              onClick={onNextMonth}>
                <img src={next} alt="next" className="size-5" />
            </button>
            <button 
              className="text-lg rounded-l-md rounded-r-md border text-center cursor-pointer px-3 bg-tertiary text-black font-bold border-tertiary drop-shadow-xl" 
              onClick={onResetMonth}>
                <img src={undo} alt="undo" className="size-5" />
            </button>
          </div>
        </div>
      </div>
      <div>
        <PnLTileMap {...props} />
      </div>

    </div>
  )
}

PnlHistoryTiledPanelLarge.propTypes = {
  pnlMeterType: PropTypes.oneOf(['percentage', 'pnl']).isRequired,
  setPnlMeterType: PropTypes.func.isRequired,
}

export default PnlHistoryTiledPanelLarge