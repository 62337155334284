import React, { useEffect, useState } from "react";
import Loader from "../../components/Loader";

import bgLeft from "../../assets/bgLeftRectangle.png";
import bgPhoneTop from "../../assets/bgPhoneTopRectangle.png";
import profile from "../../assets/defaultPicture.png";
import gold from "../../assets/Images/Medals/gold.png";
import bronze from "../../assets/Images/Medals/bronze.png";
import silver from "../../assets/Images/Medals/silver.png";
import honour from "../../assets/Images/Medals/honour.png";

import { userStore } from "../../store/user";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "../../components/Sidebar/Sidebar";
import axios from "axios";
import DashHeader from "../../components/DashHeader/DashHeader";

const Leaderboard = () => {
  const navigate = useNavigate();
  const [bigNavToggle, setbigNavToggle] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [leaderboardCategory, setLeaderBoardCategory] = useState("monthly");

  const [paperMonthLeaderboard, setPaperMonthLeaderboard] = useState([]);
  const [paperDayLeaderboard, setPaperDayLeaderboard] = useState([]);
  
  const [realMonthLeaderboard, setRealMonthLeaderboard] = useState([]);
  const [realDayLeaderboard, setRealDayLeaderboard] = useState([]);

  console.log("paperMonthLeaderboard", paperMonthLeaderboard)
  console.log("realMonthLeaderboard", realMonthLeaderboard)

  const [isLoading, setIsLoading] = useState(true);

  const [tradeType, setTradeType] = useState('realTrades'); // Toggle between 'realTrades' and 'paperTrades'

  const { userData } = userStore((state) => state);
  const {
    avatarUrl,
    discordServers = [],
    name,
    id: userId,
    role,
    currentBalance
  } = userData || {};
  const gentradeToken = localStorage.getItem("gentradeToken");
  const subDomainUrl = localStorage.getItem('baseURL')
  const serverId = localStorage.getItem("serverId");

  const currentDate = new Date();

  // Get the current month (0-11, so we add 1)
  const currentMonth = currentDate.getMonth() + 1;

  // Get the full month name (optional)
  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June', 
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  const currentMonthName = monthNames[currentDate.getMonth()];
  const currentYear = currentDate.getFullYear();
  const currentDay = currentDate.getDate();

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/pnl/leaderboard?serverId=${serverId ? serverId : process.env.REACT_APP_DISCORD_SERVER_ID}&useCache=false`
        );
        // setLeaderBoardData(response.data);
        const responseArray = response.data;
        // const responsePaperMonthArray = [...responseArray];
        // const responsePaperDayArray = [...responseArray];

        let responsePaperMonthArray = [];
        let responsePaperDayArray = [];

        responsePaperMonthArray = responseArray.map((item) => {
          return {
            user: item?.user,
            month: item?.paperTrades?.month
          }
        })

        responsePaperMonthArray.sort((a, b) => {
          return (
            Number(b?.month?.pnlPercentage?.split("%")?.[0]) -
            Number(a?.month?.pnlPercentage?.split("%")?.[0])
          );
        });
        setPaperMonthLeaderboard(responsePaperMonthArray);

        console.log("coming here 1")

        responsePaperDayArray = responseArray.map((item) => {
          return {
            user: item?.user,
            dayPnl: item?.paperTrades?.dayPnl
          }
        })
        responsePaperDayArray.sort((a, b) => {
          return (
            Number(b?.paperTrades?.dayPnl?.pnlPercentage?.split("%")?.[0]) -
            Number(a?.paperTrades?.dayPnl?.pnlPercentage?.split("%")?.[0])
          );
        });
        setPaperDayLeaderboard(responsePaperDayArray);

        console.log("coming here 2")

        //Real
        // const responseRealMonthArray = [...responseArray];
        // const responseRealDayArray = [...responseArray];
        let responseRealMonthArray = [];
        let responseRealDayArray = [];
        
        responseRealMonthArray = responseArray.map((item) => {
          return {
            user: item?.user,
            month: item?.realTrades?.month
          }
        })
        responseRealMonthArray.sort((a, b) => {
          return (
            Number(b?.realTrades?.month?.pnlPercentage?.split("%")?.[0]) -
            Number(a?.realTrades?.month?.pnlPercentage?.split("%")?.[0])
          );
        });
        setRealMonthLeaderboard(responseRealMonthArray);

        console.log("coming here 3")

        responseRealDayArray = responseArray.map((item) => {
          return {
            user: item?.user,
            dayPnl: item?.realTrades?.dayPnl
          }
        })
        responseRealDayArray.sort((a, b) => {
          return (
            Number(b?.realTrades?.dayPnl?.pnlPercentage?.split("%")?.[0]) -
            Number(a?.realTrades?.dayPnl?.pnlPercentage?.split("%")?.[0])
          );
        });
        setRealDayLeaderboard(responseRealDayArray);

        console.log("coming here 4", )


        setIsLoading(false);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  useEffect(() => {
    if (isOpen) {
      // Prevent scrolling of the body when the navbar is open
      document.body.style.overflow = "hidden";
    } else {
      // Restore scrolling when the navbar is closed
      document.body.style.overflow = "auto";
    }

    if (!gentradeToken) {
      if(subDomainUrl && gentradeToken){
        localStorage.clear();
        window.location.href = `${subDomainUrl}/signIn`
      }
      else{
        localStorage.clear();
        navigate("/signIn");
      }
    }
  }, [isOpen]);

  return (
    <div
      className="min-h-screen flex flex-col lg:flex-row bg bg-cover bg-center"
      // style={{backgroundImage: `url(${bgLeft})`}}
    >
      <Sidebar
        bigNavToggle={bigNavToggle}
        setbigNavToggle={setbigNavToggle}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        route={"leaderboard"}
      />

      {/* Body for larger screens  */}
      <div
        className={`hidden lg:block ${
          bigNavToggle ? "lg:w-[80%]" : "lg:w-[96%]"
        } lg:w-[80%] bg-cover bg-no-repeat bg-left px-12 py-8 text-[15px]`}
        style={{ backgroundImage: `url(${bgLeft})` }}
      >
        {/* <div className="flex justify-end gap-4">
          <div className="flex gap-2 text-tertiary [#C4C4C4]  border border-[#646464] rounded-lg text-center px-2 py-1">
            Current balance:
            <div className="text-white">{currentBalance}</div>
          </div>
          <div className="flex">
          {role == "admin" ? (
            <Link
              to={"/admin/userInfo"}
              className="text-tertiary px-2 py-1 transition-all border border-[#646464] hover:border-tertiary text-center rounded-lg"
            >
              Admin Portal
            </Link>
          ) : (
            ""
          )}
          <img
            src={avatarUrl || profile}
            alt="profile"
            className="ml-6 border border-white rounded-full size-[34px]"
          />
          </div>
        </div> */}

        <DashHeader />

        {/* Main Body  */}
        {isLoading ? (
          <div className="text-4xl h-full text-white pb-8 flex items-center justify-center">
            <Loader />
          </div>
        ) : (
          <div className="flex justify-center gap-8 px- mt-4 w-full mx-auto xl:h-[400px z-40">
            <div className="w-[50% w-full">
              
              <div className="rounded-3xl h-[670px] bg-[#202020] p- overflow-y-auto scrollbar">
                <div className="text-[#999999 text-black bg-tertiary text-2xl pt-4 px-4 font-bold text-center">
                  Leaderboard
                </div>
                <div className="bg-tertiary pt-2">  
                  <div className=" border-2 border-black rounded-full w-fit mx-auto flex gap- 2 justify-center bg- tertiary py- 4 px- 4 font-semibold">
                    <div
                      className={`w- [80px] text-center py-1  px-4 rounded-l-full  ${
                        tradeType === "realTrades"
                          ? "bg-black text-tertiary"
                          : " text-black"
                      }  cursor-pointer`}
                      onClick={() => setTradeType("realTrades")}
                    >
                      Real Trades
                    </div>
                    <div
                      className={`w- [80px] text-center py-1  px-3 rounded-r-full ${
                        tradeType === "paperTrades"
                          ? "bg-black text-tertiary"
                          : " text-black"
                      } cursor-pointer`}
                      onClick={() => setTradeType("paperTrades")}
                    >
                      Paper Trades
                    </div>
                  </div>
                </div>
                <div className="flex gap-2 justify-center bg-tertiary py-4 px-4 font-semibold">
                  <div
                    className={`w-[80px] text-center py-[2px] ${
                      leaderboardCategory === "monthly"
                        ? "bg-black text-tertiary"
                        : "border-2 border-black text-black"
                    } rounded-full cursor-pointer`}
                    onClick={() => setLeaderBoardCategory("monthly")}
                  >
                    Monthly
                  </div>
                  <div
                    className={`w-[80px] text-center py-[2px] ${
                      leaderboardCategory === "daily"
                        ? "bg-black text-tertiary"
                        : "border-2 border-black text-black"
                    } rounded-full cursor-pointer`}
                    onClick={() => setLeaderBoardCategory("daily")}
                  >
                    Daily
                  </div>
                </div>
                
                <div className=" mt-2 text-xl text-center text-[#C4C4C4] font-semibold">
                  {leaderboardCategory === "monthly"
                    ? `${currentMonthName}, ${currentYear}`
                    : `${currentMonthName} ${currentDay}, ${currentYear}`
                  }
                </div>
                {isLoading ? (
                  <div className="text-4xl text-white pt-8 flex items-center justify-center">
                    <Loader />
                  </div>
                ) : (
                  <div className="mt-4 px-4">
                    <div className="flex font-semibold justify-between mb-2 py-2 px-2 bg-[#111111] rounded-full text-[#C4C4C4  text-tertiary border- border-b-[#939393]">
                      <div className="w-[20%] md:w-[12%] pl-4">#</div>
                      <div className="w-[40%] md:w-[28%]">Name</div>
                      <div className="hidden md:block w-[15%]">Age</div>
                      <div className="w-[25%] md:w-[15%]">PnL</div>
                      <div className="hidden md:block w-[15%]">Percentage</div>
                    </div>
                    <div className="h-[450px] min-[380px]:h-[500px] overflow-y-scroll scrollbar">
                      {tradeType === "realTrades" 
                        ? leaderboardCategory === "monthly"
                          ? realMonthLeaderboard?.map((lbd, i) => (
                              <div
                                className="flex justify-between items-center py-1 px-2 text-[#C4C4C4]"
                                key={i}
                              >
                                <div className="w-[20%] md:w-[12%]">
                                  <img
                                    src={
                                      i === 0
                                        ? gold
                                        : i === 1
                                        ? silver
                                        : i === 2
                                        ? bronze
                                        : honour
                                    }
                                    className="size-12"
                                    alt="medal"
                                  />
                                </div>
                                <div className="w-[40%] md:w-[28%] pb-1">
                                  {lbd.user.name}
                                </div>
                                <div className="hidden md:block w-[15%] pb-1">
                                  {lbd.user.age} days
                                </div>
                                <div className="w-[25%] md:w-[15%] pb-1 text-tertiar text-white font-semibold">
                                  {lbd?.month?.pnl}
                                </div>
                                <div className="hidden md:block w-[15%] pb-1 text-tertiar text-white font-semibold">
                                  {lbd?.month?.pnlPercentage}
                                </div>
                              </div>
                            ))
                          : realDayLeaderboard.map((lbd, i) => (
                              <div
                                className="flex justify-between items-center py-1 px-2 text-[#C4C4C4]"
                                key={i}
                              >
                                <div className="w-[20%] md:w-[12%]">
                                  <img
                                    src={
                                      i === 0
                                        ? gold
                                        : i === 1
                                        ? silver
                                        : i === 2
                                        ? bronze
                                        : honour
                                    }
                                    className="size-12"
                                    alt="medal"
                                  />
                                </div>
                                <div className="w-[40%] md:w-[28%] pb-1">
                                  {lbd.user.name}
                                </div>
                                <div className="hidden md:block w-[15%] pb-1">
                                  {lbd.user.age} days
                                </div>
                                <div className="w-[25%] md:w-[15%] pb-1 text-tertiar text-white font-semibold">
                                  {lbd?.dayPnl?.pnl}
                                </div>
                                <div className="hidden md:block w-[15%] pb-1 text-tertiar text-white font-semibold">
                                  {lbd?.dayPnl?.pnlPercentage}
                                </div>
                              </div>
                            ))
                        : leaderboardCategory === "monthly"
                          ? paperMonthLeaderboard?.map((lbd, i) => (
                              <div
                                className="flex justify-between items-center py-1 px-2 text-[#C4C4C4]"
                                key={i}
                              >
                                <div className="w-[20%] md:w-[12%]">
                                  <img
                                    src={
                                      i === 0
                                        ? gold
                                        : i === 1
                                        ? silver
                                        : i === 2
                                        ? bronze
                                        : honour
                                    }
                                    className="size-12"
                                    alt="medal"
                                  />
                                </div>
                                <div className="w-[40%] md:w-[28%] pb-1">
                                  {lbd.user.name}
                                </div>
                                <div className="hidden md:block w-[15%] pb-1">
                                  {lbd.user.age} days
                                </div>
                                <div className="w-[25%] md:w-[15%] pb-1 text-tertiar text-white font-semibold">
                                  {lbd?.month?.pnl}
                                </div>
                                <div className="hidden md:block w-[15%] pb-1 text-tertiar text-white font-semibold">
                                  {lbd?.month?.pnlPercentage}
                                </div>
                              </div>
                            ))
                          : paperDayLeaderboard.map((lbd, i) => (
                              <div
                                className="flex justify-between items-center py-1 px-2 text-[#C4C4C4]"
                                key={i}
                              >
                                <div className="w-[20%] md:w-[12%]">
                                  <img
                                    src={
                                      i === 0
                                        ? gold
                                        : i === 1
                                        ? silver
                                        : i === 2
                                        ? bronze
                                        : honour
                                    }
                                    className="size-12"
                                    alt="medal"
                                  />
                                </div>
                                <div className="w-[40%] md:w-[28%] pb-1">
                                  {lbd.user.name}
                                </div>
                                <div className="hidden md:block w-[15%] pb-1">
                                  {lbd.user.age} days
                                </div>
                                <div className="w-[25%] md:w-[15%] pb-1 text-tertiar text-white font-semibold">
                                  {lbd?.dayPnl?.pnl}
                                </div>
                                <div className="hidden md:block w-[15%] pb-1 text-tertiar text-white font-semibold">
                                  {lbd?.dayPnl?.pnlPercentage}
                                </div>
                              </div>
                            ))
                      }
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Body for smaller screens  */}
      {isLoading ? (
        <div className="lg:hidden text-4xl h-[80vh] text-white pb-8 flex items-center justify-center">
          <Loader />
        </div>
      ) : (
        <div
          className="lg:hidden min-h-screen w-full bg-cover bg-center bg-no-repeat px-4 z-0 pt-4"
          style={{ backgroundImage: `url(${bgPhoneTop})` }}
          onClick={() => setIsOpen(false)}
        >
          
          <DashHeader />
          <div className="flex justify-center gap-8 px- mt-4 w-full mx-auto xl:h-[400px z-40">
            <div className="w-[50% w-full">
              <div className="rounded-3xl h-[720px] bg-[#202020] p- overflow-y-auto scrollbar">
                <div className="text-[#999999 text-black bg-tertiary text-2xl pt-4 px-4 font-bold text-center">
                  Leaderboard
                </div>
                <div className="bg-tertiary pt-2">  
                  <div className=" border-2 border-black rounded-full w-fit mx-auto flex gap- 2 justify-center bg- tertiary py- 4 px- 4 font-semibold">
                    <div
                      className={`w- [80px] text-center py-1  px-4 rounded-l-full  ${
                        tradeType === "realTrades"
                          ? "bg-black text-tertiary"
                          : " text-black"
                      }  cursor-pointer`}
                      onClick={() => setTradeType("realTrades")}
                    >
                      Real Trades
                    </div>
                    <div
                      className={`w- [80px] text-center py-1  px-3 rounded-r-full ${
                        tradeType === "paperTrades"
                          ? "bg-black text-tertiary"
                          : " text-black"
                      } cursor-pointer`}
                      onClick={() => setTradeType("paperTrades")}
                    >
                      Paper Trades
                    </div>
                  </div>
                </div>
                <div className="flex gap-2 justify-center bg-tertiary py-4 px-4 font-semibold">
                  <div
                    className={`w-[80px] text-center py-[2px] ${
                      leaderboardCategory === "monthly"
                        ? "bg-black text-tertiary"
                        : "border-2 border-black text-black"
                    } rounded-full cursor-pointer`}
                    onClick={() => setLeaderBoardCategory("monthly")}
                  >
                    Monthly
                  </div>
                  <div
                    className={`w-[80px] text-center py-[2px] ${
                      leaderboardCategory === "daily"
                        ? "bg-black text-tertiary"
                        : "border-2 border-black text-black"
                    } rounded-full cursor-pointer`}
                    onClick={() => setLeaderBoardCategory("daily")}
                  >
                    Daily
                  </div>
                </div>
                <div className="text-xl text-center text-[#C4C4C4] font-semibold mt-2">
                  {currentMonthName}
                </div>
                {isLoading ? (
                  <div className="text-4xl text-white pt-8 flex items-center justify-center">
                    <Loader />
                  </div>
                ) : (
                  <div className="mt-4 px-4">
                    <div className="flex font-semibold justify-between mb-2 py-2 px-2 bg-[#111111] rounded-full text-[#C4C4C4  text-tertiary border- border-b-[#939393]">
                      <div className="w-[20%] md:w-[12%] pl-4">#</div>
                      <div className="w-[40%] md:w-[28%]">Name</div>
                      <div className="hidden md:block w-[15%]">Age</div>
                      <div className="hidden md:block w-[15%]">PnL</div>
                      <div className="w-[27%] md:w-[15%]">Percentage</div>
                    </div>
                    <div className="h-[450px] min-[380px]:h-[500px] overflow-y-auto scrollbar">
                      {tradeType === "realTrades" 
                        ? leaderboardCategory === "monthly"
                          ? realMonthLeaderboard?.map((lbd, i) => (
                              <div
                                className="flex justify-between items-center py-1 px-2 text-[#C4C4C4]"
                                key={i}
                              >
                                <div className="w-[20%] md:w-[12%]">
                                  <img
                                    src={
                                      i === 0
                                        ? gold
                                        : i === 1
                                        ? silver
                                        : i === 2
                                        ? bronze
                                        : honour
                                    }
                                    className="size-12"
                                    alt="medal"
                                  />
                                </div>
                                <div className="w-[40%] md:w-[28%] pb-1">
                                  {lbd.user.name}
                                </div>
                                <div className="hidden md:block w-[15%] pb-1">
                                  {lbd.user.age} days
                                </div>
                                <div className="hidden md:block md:w-[15%] pb-1 text-tertiar text-white font-semibold">
                                  {lbd?.month?.pnl}
                                </div>
                                <div className="w-[25%] md:w-[15%] pb-1 text-tertiar text-white font-semibold">
                                  {lbd?.month?.pnlPercentage}
                                </div>
                              </div>
                            ))
                          : realDayLeaderboard.map((lbd, i) => (
                              <div
                                className="flex justify-between items-center py-1 px-2 text-[#C4C4C4]"
                                key={i}
                              >
                                <div className="w-[20%] md:w-[12%]">
                                  <img
                                    src={
                                      i === 0
                                        ? gold
                                        : i === 1
                                        ? silver
                                        : i === 2
                                        ? bronze
                                        : honour
                                    }
                                    className="size-12"
                                    alt="medal"
                                  />
                                </div>
                                <div className="w-[40%] md:w-[28%] pb-1">
                                  {lbd.user.name}
                                </div>
                                <div className="hidden md:block w-[15%] pb-1">
                                  {lbd.user.age} days
                                </div>
                                <div className="hidden md:block w-[15%] pb-1 text-tertiar text-white font-semibold">
                                  {lbd?.dayPnl?.pnl}
                                </div>
                                <div className="w-[25%] md:w-[15%] pb-1 text-tertiar text-white font-semibold">
                                  {lbd?.dayPnl?.pnlPercentage}
                                </div>
                              </div>
                            ))
                        : leaderboardCategory === "monthly"
                          ? paperMonthLeaderboard?.map((lbd, i) => (
                              <div
                                className="flex justify-between items-center py-1 px-2 text-[#C4C4C4]"
                                key={i}
                              >
                                <div className="w-[20%] md:w-[12%]">
                                  <img
                                    src={
                                      i === 0
                                        ? gold
                                        : i === 1
                                        ? silver
                                        : i === 2
                                        ? bronze
                                        : honour
                                    }
                                    className="size-12"
                                    alt="medal"
                                  />
                                </div>
                                <div className="w-[40%] md:w-[28%] pb-1">
                                  {lbd.user.name}
                                </div>
                                <div className="hidden md:block w-[15%] pb-1">
                                  {lbd.user.age} days
                                </div>
                                <div className="hidden md:block md:w-[15%] pb-1 text-tertiar text-white font-semibold">
                                  {lbd?.month?.pnl}
                                </div>
                                <div className="w-[25%] md:w-[15%] pb-1 text-tertiar text-white font-semibold">
                                  {lbd?.month?.pnlPercentage}
                                </div>
                              </div>
                            ))
                          : paperMonthLeaderboard.map((lbd, i) => (
                              <div
                                className="flex justify-between items-center py-1 px-2 text-[#C4C4C4]"
                                key={i}
                              >
                                <div className="w-[20%] md:w-[12%]">
                                  <img
                                    src={
                                      i === 0
                                        ? gold
                                        : i === 1
                                        ? silver
                                        : i === 2
                                        ? bronze
                                        : honour
                                    }
                                    className="size-12"
                                    alt="medal"
                                  />
                                </div>
                                <div className="w-[40%] md:w-[28%] pb-1">
                                  {lbd.user.name}
                                </div>
                                <div className="hidden md:block w-[15%] pb-1">
                                  {lbd.user.age} days
                                </div>
                                <div className="hidden md:block w-[15%] pb-1 text-tertiar text-white font-semibold">
                                  {lbd?.dayPnl?.pnl}
                                </div>
                                <div className="w-[25%] md:w-[15%] pb-1 text-tertiar text-white font-semibold">
                                  {lbd?.dayPnl?.pnlPercentage}
                                </div>
                              </div>
                            ))
                      }
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Leaderboard;
