import { Drawer, Select } from "antd";
import * as Yup from "yup";
import { useFormik } from "formik";
import { MdOutlineEmail } from "react-icons/md";
import { PiPhoneCallLight } from "react-icons/pi";
import { useState } from "react";
import { toast } from "react-toastify";
import emailjs from "@emailjs/browser";

const schema = Yup.object().shape({
  name: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  enquiry: Yup.object().required("Required"),
  note: Yup.string().required("Required"),
});

export const ContactUs = ({ setOpenContactUs, openContactUs }) => {
  // const isSmallScreen = useMediaQuery("(max-width:700px)");
  
  const [emailLoading, setEmailLoading] = useState(false)

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      inquiry: "",
      note: "",
    },
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
      console.log("submit data")
      // emailjs
      // .send(
      //   import.meta.env.VITE_SERVICE_ID,
      //   import.meta.env.VITE_TEMPLATE_ID,
      //   finalFormData,
      //   import.meta.env.VITE_CLIENT_ID
      // )
      // .then(() => {
      //   setFormData({
      //     first_name: "",
      //     last_name: "",
      //     phone: "",
      //     email: "",
      //     address: "",
      //     comments: "",
      //   });
      //   toast.success("Email sent successfully!");
      //   setEmailLoading(false)
      // })
      // .catch((err) => {
      //   toast.error("Failed to send email. Error: ", err);
      //   setEmailLoading(false)
      // });
    },
    validationSchema: schema,
  });

  const options = [
    { value: "Option 1", label: "opt1" },
    { value: "Option 2", label: "opt2" },
    { value: "Option 3", label: "opt3" },
  ];
  
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    inquiry: "",
    note: "",
  });  

  // Handle input changes
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const isFormComplete = Object.values(formData).every((value) => value.trim() !== "");

    if (!isFormComplete) {
      toast.error("Please fill out all fields.", {theme: "dark"});
      return;
    }
    
    setEmailLoading(true)
    
    emailjs
      .send(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        formData,
        process.env.REACT_APP_CLIENT_ID
      )
      .then(() => {
        setFormData({
          name: "",
          email: "",
          inquiry: "",
          note: "",
        });
        toast.success("Email sent successfully!");
        setEmailLoading(false)
      })
      .catch((err) => {
        toast.error("Failed to send email. Error: ", err);
        setEmailLoading(false)
      });
  };


  return (
    <Drawer
      title=""
      placement={"right"}
      width={"50%"}
      onClose={() => setOpenContactUs(false)}
      open={openContactUs}
    >
      <div className="flex flex-col w-full justify-evenly">
        <div>
          <div className="text-[#414141]">
            <h1 className="text-4xl font-bold text-[#0A0A0A]">Contact Us.</h1>
            <p className="text-base mt-4 leading-[24px] tracking-[-1]">
              You just made our day brighter! Get your free Alertsify quote to
              see how much you could save with a free, no-obligation Alertsify
              quote.
            </p>
          </div>
          <form
            onSubmit={handleSubmit}
            className="w-full flex flex-col my-8 gap-y-4"
          >
            <div className="w-full flex gap-x-4">
              <div className="flex flex-col gap-y-2 w-1/2">
                <label htmlFor="name">What’s your name?</label>
                <input
                  className="border border-[#E6E6E6] px-4 py-3 rounded-lg"
                  id="name"
                  name="name"
                  type="text"
                  onChange={handleChange}
                  value={formData.name}
                  placeholder="Enter name"
                />
              </div>
              <div className="flex flex-col gap-y-2 w-1/2">
                <label htmlFor="name">What’s your email?</label>
                <input
                  className="border border-[#E6E6E6] px-4 py-3 rounded-lg"
                  id="email"
                  name="email"
                  type="email"
                  onChange={handleChange}
                  value={formData.email}
                  placeholder="john@doe.com"
                />
              </div>
            </div>
            <div className="flex flex-col gap-y-2 w-full">
              <label htmlFor="inquiry">What type of inquiry?</label>
              <select name="inquiry" onChange={handleChange} className="block w-full p-3 text-base border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500">
                <option value="" disabled selected>
                  Select a person
                </option>
                {options.map((opt) => (
                  <option key={opt.value} value={opt.value}>
                    {opt.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col gap-y-2 w-full">
              <label htmlFor="name">Note</label>

              <textarea
                className="border border-[#E6E6E6] px-4 py-3 rounded-lg"
                id="note"
                name="note"
                type="text"
                onChange={handleChange}
                value={formData.note}
                placeholder="Type here"
                rows={4}
              />
            </div>
            <button
              type="submit"
              disabled={emailLoading}
              className="w-full bg-[#A4F720] disabled:bg-[#9ca88a] py-4 flex justify-center rounded-full"
            >
              Submit
            </button>
          </form>
        </div>
        <div className="w-full flex gap-x-1 items-center">
          <div className="w-1/2 border border-[#E6E6E6]" />
          <span className="font-light">OR</span>
          <div className="w-1/2 border border-[#E6E6E6]" />
        </div>
        <div className="flex flex-col lg:flex-row gap-y-4 lg:gap-x-4 my-4 ">
          <div className="flex gap-x-2 w-full lg:w-1/2 h-full border border-[#E6E6E6] p-4 rounded-xl bg-[#FAFAFA]">
            <div className="bg-[#A4F720] rounded-2xl h-12 w-12 items-center justify-center flex">
              <MdOutlineEmail size={24} />
            </div>
            <div>
              <h1 className="text-[#808080] text-sm">Email</h1>
              <p className="text-[#0A0A0A] text-base">hello@alertsify.com</p>
            </div>
          </div>
          <div className="flex gap-x-2  w-full lg:w-1/2 h-full border border-[#E6E6E6] p-4 rounded-xl bg-[#FAFAFA]">
            <div className="bg-[#A4F720] rounded-2xl h-12 w-12 items-center justify-center flex">
              <PiPhoneCallLight size={24} />
            </div>
            <div>
              <h1 className="text-[#808080] text-sm">Phone</h1>
              <p className="text-[#0A0A0A] text-base">+1 845 1456 585</p>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
};
