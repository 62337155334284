import React, { useEffect, useState } from "react";
import { userStore } from "../../../store/user";
import { Link, useLocation, useNavigate } from "react-router-dom";

import Sidebar from "../../../components/Sidebar/Sidebar";

import bgLeft from "../../../assets/bgLeftRectangle.png";
import profile from "../../../assets/defaultPicture.png";
import tradier from "../../../assets/icons/dashboard/tradier.png";
import Accordion from "../../../components/Accordion/Accordion";
import { toast } from "react-toastify";
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';

import tempResponse from "./tempNewsResponse";
import DashHeader from "../../../components/DashHeader/DashHeader";
import Loader from "../../../components/Loader";
import Skeleton from "react-loading-skeleton";

const DashboardHome = () => {
  let state = "";
  const navigate = useNavigate()
  const [bigNavToggle, setbigNavToggle] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  // const [btoStoreFilteredData, setBtoStoreFilteredData] = useState();
  const [recentTrades, setRecentTrades] = useState();
  const [btoStoreData, setBtoStoreData] = useState();
  const [isLoading, setIsLoading] = useState(true)
  // const [accountList, setAccountList] = useState();
  // const [selectedAccount, setSelectedAccount] = useState();

  const [newsData, setNewsData] = useState()
  
  const {
    userData,
    btoData,
    hasBrokerTokens,
    setHasBrokerTokens,
    selectedAccount,
    setSelectedAccount,
    accountList,
    setAccountList,
    setBrokerBalance,
    setIsPaperTrade,
    brokerBalance,
  } = userStore((state) => state);

  const {
    avatarUrl,
    discordServers = [],
    name,
    id: userId,
    role,
    currentBalance
  } = userData || {};

  const location = useLocation();

  // TODO: Needs to come from server - oauth has implementation
  const scopes = "read, write, trade, market, stream";


  const handleTradierAuth = async () => {
    state = uuidv4();
    localStorage.setItem('oauth-state', state);
    console.log(state);
    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/oauth/state`, {
      "apiSource": "Tradier", // TODO: make dynamic
      "state": state,
      "userId": localStorage.getItem('userId')
    });
    const callbackUrl = `${window.location.href}`;
    const target = `https://api.tradier.com/v1/oauth/authorize?client_id=${process.env.REACT_APP_TRADIER_CLIENT_ID}&scope=${scopes}&state=${state}&redirect_uri=${callbackUrl}`;
    window.location.href = target;
  };

  const gentradeToken = localStorage.getItem("gentradeToken");
  const subDomainUrl = localStorage.getItem('baseURL')
  
  useEffect(() => {
    if (!gentradeToken) {
      if(subDomainUrl && gentradeToken){
        localStorage.clear();
        window.location.href = `${subDomainUrl}/signIn`
      }
      else{
        localStorage.clear();
        navigate("/signIn");
      }
    }

    const getNews = async () => {
      await axios.get(
        `https://stocknewsapi.com/api/v1/category?section=general&items=8&page=1&token=${process.env.REACT_APP_NEWS_KEY}`
      )
      .then((response) => {
        setNewsData(response.data?.data?.splice(0,8))
      })
      .catch((error) => {
        toast.error(error?.data);
      })
    }
    getNews();
  }, []);
  console.log(newsData)

  useEffect(() => {
    const sendAuthCode = async (code) => {
      state = localStorage.getItem('oauth-state');
      await axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/oauth/authcode`, {
          "user_id": localStorage.getItem('userId'),
          "api_source": "Tradier", // TODO: Needs to become dynamic
          "code": code,
          "state": state,
          "scope": scopes
        })
        .then(async (res) => {
          if(res.status === 200) {
            toast("Successfully authenticated", { type: "success" });
            setHasBrokerTokens(true);
            console.log(hasBrokerTokens);
            await axios
              .get(`${process.env.REACT_APP_API_BASE_URL}/broker/accounts/` + localStorage.getItem('userId'))
                .then(res => {
                  console.log(res.data)
                  setAccountList(res.data.accountList)
                })
                .catch(err => console.error(err));
          } else {
            toast("Authentication Failed", { type: "error" });
          }
        })
        .catch(err => console.error(err));
    };

    const query = new URLSearchParams(location.search);
    const code = query.get("code");

    if (code) {
      console.log("Code", code); // send code to backend receive access token
      sendAuthCode(code);
      localStorage.removeItem('oauth-state');
    }
  }, [location]);

  
  useEffect(() => {
    if (btoData && btoData?.active && btoData?.expired && !btoStoreData) {
      console.log("BTO", btoData)
      let tempArray = btoData?.active?.map((item, index) => {
        const timestamp = new Date(item.created_at).getTime();
        return [timestamp, "active", item];
      });

      btoData?.expired?.map((item, index) => {
        const timestamp = new Date(item.created_at).getTime();
        tempArray = [...tempArray, [timestamp, "expired", item]];
      });

      btoData?.sold?.map((item, index) => {
        const timestamp = new Date(item.created_at).getTime();
        tempArray = [...tempArray, [timestamp, "sold", item]];
      });

      tempArray.sort((a, b) => new Date(b[0]) - new Date(a[0]));

      // Get the 5 most recent trades from the entire tempArray
      const mostRecentTrades = tempArray.slice(0, 5);
      setRecentTrades(mostRecentTrades);

      setIsLoading(false)

      // const currentDate = new Date().getTime();
      // let lastSevenDaysArray = tempArray?.filter((entry) => {
      //   Check if the difference between the current date and the entry's date is less than or equal to 7 days (in milliseconds)
      //   return currentDate - entry[0] <= 6 * 24 * 60 * 60 * 1000;
      // });
      // lastSevenDaysArray.sort((a, b) => new Date(b[0]) - new Date(a[0]));
      // setBtoStoreFilteredData(lastSevenDaysArray);
      setBtoStoreData(tempArray);
    }
  }, [btoData]);

  
  // useEffect(() => {
  //   switch (tableTimeScale) {
  //     case "year":
  //       if (btoStoreData) {
  //         let currentDate = new Date().getTime();
  //         let lastYearArray = btoStoreData?.filter((entry) => {
  //           // Check if the difference between the current date and the entry's date is less than or equal to 365 days (in milliseconds)
  //           return currentDate - entry[0] <= 360 * 24 * 60 * 60 * 1000;
  //         });
  //         lastYearArray.sort((a, b) => new Date(b[0]) - new Date(a[0]));
  //         setBtoStoreFilteredData(lastYearArray);
  //       }
  //       break;
  //     case "month":
  //       if (btoStoreData) {
  //         let currentDate = new Date().getTime();
  //         let lastMonthArray = btoStoreData?.filter((entry) => {
  //           // Check if the difference between the current date and the entry's date is less than or equal to 30 days (in milliseconds)
  //           return currentDate - entry[0] <= 29 * 24 * 60 * 60 * 1000;
  //         });
  //         lastMonthArray.sort((a, b) => new Date(b[0]) - new Date(a[0]));
  //         setBtoStoreFilteredData(lastMonthArray);
  //       }
  //       break;
  //     case "week":
  //       if (btoStoreData) {
  //         const currentDate = new Date().getTime();
  //         let lastSevenDaysArray = btoStoreData?.filter((entry) => {
  //           // Check if the difference between the current date and the entry's date is less than or equal to 7 days (in milliseconds)
  //           return currentDate - entry[0] <= 6 * 24 * 60 * 60 * 1000;
  //         });
  //         lastSevenDaysArray.sort((a, b) => new Date(b[0]) - new Date(a[0]));
  //         setBtoStoreFilteredData(lastSevenDaysArray);
  //       }
  //       break;
  //     case "all":
  //       if (btoStoreData) {
  //         let completeArray = btoStoreData.sort(
  //           (a, b) => new Date(b[0]) - new Date(a[0])
  //         );
  //         setBtoStoreFilteredData(completeArray); // Use all data for the graph
  //       }
  //       break;
  //   }
  // }, [tableTimeScale]);

  // console.log(brokerBalance)

  const handleBrokerLogout = async () => {
    setIsLoading(true)
    
    await axios.delete(
      "https://backend-ums-stage-797b8d335ec5.herokuapp.com/oauth/token/" + localStorage.getItem('userId')
    )
    .then((response) => {
      console.log(response.data)
      setHasBrokerTokens(false)
      setAccountList(null)
      setSelectedAccount(null)
      setBrokerBalance(null)
      setIsPaperTrade(true);
      setIsLoading(false)
    })
    .catch((error) => {
      console.log(error.data)
      setIsLoading(false)
    })
  }

  return (
    <div className="lg:h-screen flex flex-col lg:flex-row bg bg-cover bg-center">
      <Sidebar
        bigNavToggle={bigNavToggle}
        setbigNavToggle={setbigNavToggle}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        route={"dashboard"}
      />

      {hasBrokerTokens !== null && !isLoading ? (
        <div
          className={`hid den lg:block ${
            bigNavToggle ? "lg:w-[80%]" : "lg:w-[96%]"
          } bg-cover bg-no-repeat bg-left px-4 lg:px-12 py-8 text-[15px] overflow-y-scroll scrollbar`}
          style={{ backgroundImage: `url(${bgLeft})` }}
        >
          {/* Top bar only for bigger screens  */}

          <DashHeader />

          <div className="md:mt-6 overflow- scroll scrollbar">
            <div className="flex flex-col md:flex-row justify-between gap-4 md:h-[472px]">
              {hasBrokerTokens ? (
                <div className="flex flex-col w-full md:w-1/2 gap-4 md:h-[472px]">
                  <div className="bg-[#202020] p-6 rounded-[32px] md:h-1/2 border border-[#6c6c6c]">
                    <div className="flex gap-2 justify-between items-center text-[#A1A1A1] w-full">
                      <div className="flex items-center h-[23px]">
                        <div className="">Tradier :</div>
                        <div className="ml-2 mr-1 mt-[2px] rounded-full size-2 bg-[#28A263]" />
                        <div className="text-[#28A263]">Connected</div>
                      </div>
                      <div className="flex gap-2 items-center">
                        <div className="">Zach Cohen</div>
                        {/* Image  */}
                      </div>
                    </div>
                    <div className="mt-10 flex items-center justify-between">
                      <div className="text-[#9b9b9b] text-center">
                        <div className="text-[32px] md:text-[42px] leading-none font-bold">{brokerBalance?.totalEquity !== undefined ? "$" + brokerBalance?.totalEquity : <Skeleton  baseColor="#424242" highlightColor="#202020" width="80px" />}</div>
                        <div className="text-sm md:text-base mt-1">Total Value</div>
                      </div>
                      <div className="text-[#9b9b9b] text-center">
                        <div className="text-[32px] md:text-[42px] leading-none font-bold">{brokerBalance?.availableFunds !== undefined ? "$" + brokerBalance?.availableFunds : <Skeleton  baseColor="#424242" highlightColor="#202020" width="80px" />}</div>
                        <div className="text-sm md:text-base mt-1">Available Funds</div>
                      </div>
                      <div className="text-[#9b9b9b] text-center">
                        <div className="text-[32px] md:text-[42px] leading-none font-bold">{brokerBalance?.unsettledFunds !== undefined ? "$" + brokerBalance?.unsettledFunds : <Skeleton  baseColor="#424242" highlightColor="#202020" width="80px" />}</div>
                        <div className="text-sm md:text-base mt-1">Unsettled Funds</div>
                      </div>
                    </div>
                    <div className="flex justify-end mt-4 md:mt-6">
                      <button className="px-4 py-2 bg-red-600 text-white font-medium rounded-lg"
                        onClick={handleBrokerLogout}
                      >
                        Logout
                      </button>
                    </div>
                  </div> 
                  
                  <div className="flex flex-col bg-[#202020] p-6 rounded-[32px] md:h-1/2">
                    {/* Default papertrail component  */}
                    <div className="flex justify-between">
                      <div className="text-[#A1A1A1] text-lg">Paper Trades</div>
                      <div className="">
                        <div className="text-[32px] md:text-[40px] leading-none text-[#9B9B9B] font-bold">
                          {"$" + currentBalance || "$0.00"}
                        </div>
                        <div className="text-sm md:text-base text-tertiary">Current Balance</div>
                      </div>
                    </div>
                    <div className="text-sm md:text-base mt-4 text-[#A1A1A1]">
                      Start trading with real-time options insights for smarter,
                      faster decisions.
                    </div>
                    <div className="flex items-end justify-center mt-4 md:mt-auto">
                      <button onClick={() => navigate("/model")} className="border border-[#6E6E6E] rounded-lg py-2 px-3 text-tertiary">
                        Start Trading
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col gap-4 w-full md:w-1/2 h-full">
                  <div className="bg-[#202020] p-6 rounded-[32px] h-1/2">
                    {/* Tradier Auth  */}
                    <div className="flex items-center h-[23px]">
                      <div className="text-[#A1A1A1]">Brokerage Account :</div>
                      <div className="ml-2 mr-1 mt-[2px] rounded-full size-2 bg-[#F24E1E]" />
                      <div className="text-[#F24E1E]">Not Connected</div>
                    </div>
                    <div className="flex flex-grow h-full justify-center items-center py-6">
                      <div className="flex items-center gap-2 border border-[#6E6E6E] rounded-lg py-2 px-3">
                        <img src={tradier} className="size-6" />
                        <button
                          onClick={handleTradierAuth}
                          className="text-tertiary"
                        >
                          Sign In with Tradier
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col bg-[#202020] p-6 rounded-[32px] h-1/2">
                    {/* Default papertrail component  */}
                    <div className="flex justify-between">
                      <div className="text-[#A1A1A1] text-lg">Paper Trades</div>
                      <div className="">
                        <div className="text-[40px] leading-none text-[#9B9B9B] font-bold">
                          {"$" + currentBalance || "$0.00"}
                        </div>
                        <div className="text-tertiary">Current Balance</div>
                      </div>
                    </div>
                    <div className="mt-8 text-[#A1A1A1]">
                      Start trading with real-time options insights for smarter,
                      faster decisions.
                    </div>
                    {/* <div className="flex items-end mt-auto justify-center">
                      <button className="border border-[#6E6E6E] rounded-lg py-2 px-3 text-tertiary">
                        Start Trading
                      </button>
                    </div> */}
                  </div>
                </div>
              )}

              <div className="w-full md:w-1/2 bg-[#202020] p-6 rounded-[32px] gap-4 md: h-[472px]">
                <div className="text-[#A1A1A1] h-fit">Recent Updates</div>
                {newsData ? (
                  <div className="h-[94%] overflow-y-scroll scrollbar pr-4 mt-4">
                    {newsData?.map((item, index) => (
                      <div key={index} className="bg-[#1C1C1C] p-2 mb-4 flex gap-2 rounded-2xl">
                        <img src={item?.image_url} className="h-16"/>
                        <div className="flex flex-col justify-between w-full px-2">
                          <div className="text-[#9B9B9B] font-semibold text-[17px]">{item?.title}</div>
                          <a href={item?.news_url} target="blank" className="w-full text-right text-tertiary underline underline-offset-2">
                            Read More
                          </a>
                        </div>
                      </div>
                    ))}
                  </div>
                ): (
                  <div className="flex w-full h-full items-center justify-center">
                    <Loader />
                  </div>
                )}
              </div>
            </div>

            <div className="mt-4 bg-[#202020] p-6 rounded-[32px] h-[400px] ">
              <div className="flex justify-between items-center">
                <div className="text-lg text-[#A1A1A1]">Recent Trades</div>
                {/* <select
                  value={tableTimeScale}
                  onChange={(e) => setTableTimeScale(e.target.value)}
                  className="form-select bg-transparent border pr-4 pl-1 py-2 border-[#6E6E6E]  rounded-lg text-tertiary"
                >
                  <option className="bg-[#202020]" value="week">
                    Last 7 Days
                  </option>
                  <option className="bg-[#202020]" value="month">
                    Last Month
                  </option>
                  <option className="bg-[#202020]" value="year">
                    Last Year
                  </option>
                  <option className="bg-[#202020]" value="all">
                    All
                  </option>
                </select> */}
              </div>
              <div className="mt-6 overflow-y-scroll scrollbar h-[300px]">
                {/* {btoStoreFilteredData?.map((item, index) => ( */}
                {recentTrades?.map((item, index) => (
                  <div>
                    <Accordion index={index} item={item} page={"dash"}/>
                  </div>
                ))}
                {/* {btoStoreFilteredData?.length === 0 && ( */}
                {recentTrades?.length === 0 && (
                  <div className="flex w-full justify-center items-center h-48 text-[#7b7B7B] font-semibold text-xl">
                    No Bto's available
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="absolute left-1/2 top-1/2 transform -translate-x-[30%] -translate-y-[60%]">
          <Loader />
        </div>
      )}

    </div>
  );
};

export default DashboardHome;
