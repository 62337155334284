import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/alertsify.svg";
import discord from "../assets/icons/home/discord.png";
import { userStore } from "../store/user";
import { useState } from "react";
import { IoClose, IoMenuOutline } from "react-icons/io5";
import { BiCloset } from "react-icons/bi";

export const LandingHeader = ({source}) => {
  const [isOpen, setIsOpen] = useState(false);
  const gentradeToken = localStorage.getItem("gentradeToken");
  const subDomainUrl = localStorage.getItem("baseURL");
  const { setUserData } = userStore((state) => state);
  const navigate = useNavigate();

  const handleSignOut = () => {
    localStorage.clear();
    if (subDomainUrl && gentradeToken) {
      localStorage.clear();
      setUserData(null);
      window.location.href = `${subDomainUrl}/signIn`;
    } else {
      localStorage.clear();
      navigate("/signIn");
      setUserData(null);
    }
    setUserData(null);
  };
  const navItems = [
    { label: "Features", link: "#features", active: true },
    { label: "How It Works", link: "#working" },
    { label: "Pricing", link: "#pricing" },
    { label: "Testimonials", link: "#testimonials" },
  ];

  const handleNavClick = (link, val) => {
    const targetElement = document.querySelector(link);
    const navBarHeight = document.querySelector(".navbar").offsetHeight; // Adjust the selector to match your navbar class
    if (targetElement) {
      const targetPosition =
        targetElement.getBoundingClientRect().top +
        window.scrollY -
        navBarHeight;
      window.scrollTo({ top: targetPosition, behavior: "smooth" });
      setIsOpen(false);
    }
  };

  return (
    <div className="navbar bg-black [#101010] md:px-8 flex md:items-center mx-auto md:h-16 sticky top-0 z-50">
      <div className="hidden md:block relative w-[90%] mx-auto">
        <div className="absolute left-0 top-1/2 transform -translate-y-1/2 text-2xl text-white font-bold cursor-pointer">
          <img src={logo} onClick={() => navigate("/")} className="size-16 cursor-pointer" />
        </div>
        {
          source==="home" && (
            <div className="text-white absolute top-[-25px] w-full sm:w-fit left-1/2 transform -translate-x-1/2 lg:flex justify-center hidden">
              <div className="bg-[#FFFFFF0A]  flex gap-x-2 border border-[#FFFFFF12] rounded-full p-1 text-sm">
                {navItems.map((item, index) => (
                  <span
                    onClick={() => handleNavClick(item.link, item.label)}
                    key={item.link}
                    className={`${
                      item.label === "123"
                        ? "bg-[#FFFFFF0F] text-[#fff] font-semibold"
                        : "text-[#FFFFFFB2] font-light"
                    } py-2 px-4 rounded-full flex items-center gap-x-2 cursor-pointer`}
                  >
                    {item.label}
                  </span>
                ))}
              </div>
            </div>
          )
        }

        {!gentradeToken ? (
          <div className="absolute hidden md:flex right-0 top-1/2 transform -translate-y-1/2 text-[#AFAFAF]">
            <Link
              to="https://whop.com/tradeproelite/?%3Fpass=prod_oP4t99lFAfErW"
              className="flex justify-center gap-2 py-2 bg-[#FFFFFF0A] text-white tertiary border-2 border-[#FFFFFF3D] rounded-full px-2 w-[150px] text-center"
            >
              <img src={discord} className="size-6" />
              Join Discord
            </Link>
            <Link
              to="/signIn"
              className="bg-white [#DAFE6A] px-8 py-2 rounded-full font-semibold text-[#0A0A0A] black ml-4"
            >
              Sign In
            </Link>
          </div>
        ) : (
          <div className="absolute hidden md:flex right-0 top-1/2 transform -translate-y-1/2 text-[#AFAFAF]">
            {/* <Link
              to="https://whop.com/tradeproelite/?%3Fpass=prod_oP4t99lFAfErW"
              className="py-2 bg-[#FFFFFF0A] text-white border-2 border-[#FFFFFF3D] rounded-full px-2 w-[150px] text-center"
            >
              Discord Server
            </Link> */}
            <Link
              to="/dashboard"
              className="py-2 bg-[#FFFFFF0A] text-white border-2 border-[#FFFFFF3D] rounded-full px-2 w-[150px] text-center"
            >
              Dashboard
            </Link>
            <button
              onClick={handleSignOut}
              className="bg-white [#DAFE6A] px-8 py-2 rounded-full font-semibold text-[#0A0A0A] black ml-4"
            >
              Sign Out
            </button>
          </div>
        )}
      </div>

      <div className="md:hidden bg-[#000] px-12 flex items-center mx-auto h-16 w-full z-50">
        <div className="relative w-full ">
          <div
            onClick={() => {
              setIsOpen(false);
              navigate("/");
            }}
            className="absolute left-0 top-1/2 transform -translate-y-1/2 text-xl text-white font-bold"
          >
            <img src={logo} className="size-16" />
          </div>

          <div className="absolute right-0 top-1/2 transform -translate-y-1/2 flex text-[#AFAFAF]">
            {isOpen ? (
              <>
                <IoClose
                  size={30}
                  className=" "
                  onClick={() => setIsOpen(false)}
                />
              </>
            ) : (
              <IoMenuOutline
                size={30}
                className=" "
                onClick={() => setIsOpen(true)}
              />
            )}
          </div>
        </div>
      </div>

      {isOpen && (
        <div className="h-screen w-screen absolute top-0 ">
          <div className="mt-16 flex flex-col py-6 gap-y-4 bg-[#000]">
            <div className="flex flex-col items-start px-12 gap-y-4 p-1 text-sm ">
              {navItems.map((item, index) => (
                <span
                  onClick={() => handleNavClick(item.link, item.label)}
                  key={item.link}
                  className={`text-[#FFFFFFB2] font-light `}
                >
                  {item.label}
                </span>
              ))}
            </div>
            {!gentradeToken ? (
              <div className="text-white font-semibold w-full">
                <div className="flex flex-col gap-2 w-full text-[12.36px] rounded-lg px-4 py-2 mt-2 ">
                  {/* <LuHistory /> */}
                  <Link
                    to="https://whop.com/tradeproelite/?%3Fpass=prod_oP4t99lFAfErW"
                    className="border border-[#DAFE6A] bg-tertiary text-black text-center px-8 pb-[6px] pt-[5px] rounded-lg font-semibold "
                  >
                    Discord
                  </Link>
                  <Link
                    to="/signIn"
                    onClick={() => setIsOpen(false)}
                    className="border border-[#DAFE6A] text-center text-tertiary px-8 pb-[6px] pt-[5px] rounded-lg font-semibold "
                  >
                    Sign In
                  </Link>
                </div>
              </div>
            ) : (
              <div className="flex flex-col justify-between text-white w-[80%] mx-auto font-semibold bottom-20 absolute right-0">
                <div className="flex flex-col gap-2 items-center text-[12.36px] rounded-lg px-4 py-2 mt-2">
                  {/* <LuHistory /> */}
                  <Link
                    to="/dashboard"
                    onClick={() => setIsOpen(false)}
                    className="bg-[#DAFE6A px-2 w-full text-cente pb-[6px] pt-[5px] rounded-lg font-bold text-[#AFAFAF]"
                  >
                    Dashboard
                  </Link>
                  <Link
                    to="/statistics"
                    onClick={() => setIsOpen(false)}
                    className="bg-[#DAFE6A px-2 w-full text-cente pb-[6px] pt-[5px] rounded-lg font-bold text-[#AFAFAF]"
                  >
                    User History
                  </Link>
                </div>
                <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 text-center">
                  <Link
                    to="https://whop.com/tradeproelite/?%3Fpass=prod_oP4t99lFAfErW"
                    className=""
                  >
                    <button
                      onClick={() => {
                        setIsOpen(false);
                      }}
                      className="pb-[6px] pt-[6px] mx-auto text-tertiary border-2 border-tertiary rounded-lg px-2  w-full text-center"
                    >
                      Discord
                    </button>
                  </Link>
                  <button
                    onClick={() => {
                      setIsOpen(false);
                      handleSignOut();
                    }}
                    className="bg-tertiary px-4 w-[120px] text-center pb-[6px] pt-[5px] mt-4 rounded-lg font-bold text-black"
                  >
                    Sign Out
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {isOpen && (
        <div className="w-screen h-screen blur top-0 absolute right-0 z-[-1]" />
      )}
    </div>
  );
};
