import PropTypes from 'prop-types';
import React, { useMemo, useState, useCallback } from 'react';
import PnlHistoryTiledPanelLarge from './PnlHistoryTiledPanelLarge';
import PnlHistoryTiledPanelSmall from './PnlHistoryTiledPanelSmall';
import moment from 'moment';

const constructTileMatrix = (btoApiData, monthOffset) => {
    const tileMatrix = [];

    const now = moment();
    const formatedTodaysDate = now.format('YYYY-MM-DD');


    const currentMonth = now.month() + monthOffset; // Get current month index
    const currentYear = now.year(); // Get current year
    const startOfMonth = moment([currentYear, currentMonth]).startOf("month");
    const endOfMonth = moment([currentYear, currentMonth]).endOf("month");

    const daysInMonth = endOfMonth.date(); // Total number of days in the month

    const bufferDaysAtStart = startOfMonth.day(); // Number of days to pad at the start of the month
    const bufferDaysAtEnd = 7 - endOfMonth.day(); // Number of days to pad at the end of the month

    const totalTiles = bufferDaysAtStart + daysInMonth + bufferDaysAtEnd; // Total number of tiles in the tile matrix
    let currentRow = [];
    for (let i = 0; i < totalTiles; i++) {
        if (i < bufferDaysAtStart || i >= bufferDaysAtStart + daysInMonth) {
            currentRow.push(null);
        } else {
            const dayOfMonth = i - bufferDaysAtStart + 1;
            const currentDate = moment([currentYear, currentMonth, dayOfMonth]);
            const formattedCurrentDate = currentDate.format("YYYY-MM-DD");
            const formattedCurrentDay = currentDate.format("ddd");
            currentRow.push({
                isToday: formattedCurrentDate === formatedTodaysDate,
                date: formattedCurrentDate,
                day: formattedCurrentDay,
                paperTrades: {
                    pnl: btoApiData.paperTrades[formattedCurrentDate]?.pnl || 0,
                    pnlPercentage: btoApiData.paperTrades[formattedCurrentDate]?.pnlPercentage || 0,
                },
                realTrades: {
                    pnl: btoApiData.realTrades[formattedCurrentDate]?.pnl || 0,
                    pnlPercentage: btoApiData.realTrades[formattedCurrentDate]?.pnlPercentage || 0,
                },
            });
        }

        if (currentRow.length === 7) {
            tileMatrix.push(currentRow);
            currentRow = [];
        }
    }


    return tileMatrix;
}

const PnLHistoryTiledPanel = (props) => {
    const { size, btoApiData } = props;
    const [monthOffset, setMonthOffset] = useState(0);

    const computedTileMatrix = useMemo(() => {
        const tileMatrix = constructTileMatrix(btoApiData, monthOffset);
        return tileMatrix;
    }, [btoApiData, monthOffset]);

    const today = moment();
    const currentMonth = moment([ today.year(), today.month() + monthOffset]).format('MMMM');

    const prevMonthHandler = useCallback(() => { setMonthOffset(monthOffset - 1)}, [monthOffset, setMonthOffset]);
    const nextMonthHandler = useCallback(() => { setMonthOffset(monthOffset + 1)}, [monthOffset, setMonthOffset]);
    const resetMonthHandler = useCallback(() => { setMonthOffset(0)}, []);

    if (size === 'small') return <PnlHistoryTiledPanelSmall {...props} computedTileMatrix={computedTileMatrix} />;
    else return <PnlHistoryTiledPanelLarge {...props} computedTileMatrix={computedTileMatrix} currentMonth={currentMonth} onPrevMonth={prevMonthHandler} onNextMonth={nextMonthHandler} onResetMonth={resetMonthHandler} />;
}

PnLHistoryTiledPanel.propTypes = {
    size: PropTypes.oneOf(['small', 'large']).isRequired,
    btoApiData: PropTypes.object.isRequired,
}

export default PnLHistoryTiledPanel;