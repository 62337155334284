const tempResponse = [
    {
        "news_url": "https://www.zacks.com/stock/news/2355015/should-you-buy-tesla-etfs-ahead-of-q3-earnings?cid=CS-STOCKNEWSAPI-FT-etf_news_and_commentary-2355015",
        "image_url": "https://cdn.snapi.dev/images/v1/w/v/i/ev2ed-2483876-2708889.jpg",
        "title": "Should You Buy Tesla ETFs Ahead of Q3 Earnings?",
        "text": "Tesla saw a favorable earnings estimate revision for the to-be-reported quarter, which is generally a precursor to an earnings beat.",
        "source_name": "Zacks Investment Research",
        "date": "Tue, 22 Oct 2024 11:10:49 -0400",
        "topics": [],
        "sentiment": "Positive",
        "type": "Article",
        "tickers": [
            "TSLA"
        ]
    },
    {
        "news_url": "https://www.youtube.com/watch?v=rf9Ibg6bgoE",
        "image_url": "https://cdn.snapi.dev/images/v1/p/p/h/tsla20-2686924-2708761.jpg",
        "title": "\"I Still See Potential\" for TSLA, FSD Software \"Big Wild Card\"",
        "text": "Ahead of Tesla's (TSLA) earnings, @morningstar's Seth Goldstein points to three items he hopes to see in the company's report: automotive profit margins, an update on full-self driving, and a timeline for its more affordable vehicles. ======== Schwab Network ======== Empowering every investor and trader, every market day.",
        "source_name": "Schwab Network",
        "date": "Tue, 22 Oct 2024 10:38:05 -0400",
        "topics": [],
        "sentiment": "Positive",
        "type": "Video",
        "tickers": [
            "TSLA"
        ]
    },
    {
        "news_url": "https://www.forbes.com/sites/greatspeculations/2024/10/22/can-trump-take-tesla-stock-to-0/",
        "image_url": "https://cdn.snapi.dev/images/v1/x/y/9/can-trump-take-tesla-stock-to--2708642.jpg",
        "title": "Can Trump Take Tesla Stock To $0?",
        "text": "Yes, Donald Trump wants Tesla CEO Elon Musk to help him be the American President - again!",
        "source_name": "Forbes",
        "date": "Tue, 22 Oct 2024 10:26:03 -0400",
        "topics": [
            "paylimitwall"
        ],
        "sentiment": "Negative",
        "type": "Article",
        "tickers": [
            "TSLA"
        ]
    },
    {
        "news_url": "https://www.investors.com/news/tesla-stock-ev-giant-goes-all-in-with-latest-purchase-incentives/",
        "image_url": "https://cdn.snapi.dev/images/v1/y/n/m/tsla46-2681922-2708576.jpg",
        "title": "Tesla Goes All-In On Q4 Vehicle Deliveries With Latest Round Of Incentives",
        "text": "One month into Q4, Tesla is ramping up incentives in its quest for record deliveries.",
        "source_name": "Investors Business Daily",
        "date": "Tue, 22 Oct 2024 09:40:23 -0400",
        "topics": [],
        "sentiment": "Positive",
        "type": "Article",
        "tickers": [
            "TSLA"
        ]
    },
    {
        "news_url": "https://www.investors.com/news/tesla-stock-elon-musk-record-china-deliveries-bad-news-for-earnings/",
        "image_url": "https://cdn.snapi.dev/images/v1/f/7/x/tsla39-2689556-2708578.jpg",
        "title": "Are Record China Deliveries Bad News For Tesla Third-Quarter Earnings With Margins In Focus?",
        "text": "Tesla stock is down 16% heading into third-quarter earnings on Wednesday.",
        "source_name": "Investors Business Daily",
        "date": "Tue, 22 Oct 2024 09:38:28 -0400",
        "topics": [
            "earnings"
        ],
        "sentiment": "Negative",
        "type": "Article",
        "tickers": [
            "TSLA"
        ]
    },
    {
        "news_url": "https://finbold.com/analyst-revises-tesla-stock-price-target-ahead-of-earnings/?utm_source=snapi",
        "image_url": "https://cdn.snapi.dev/images/v1/l/w/d/tsla6-2689301-2708092.jpg",
        "title": "Analyst revises Tesla stock price target ahead of earnings",
        "text": "Electric vehicle pioneer Tesla (NASDAQ: TSLA) is in a precarious spot at the moment. After a lukewarm to bearish response to the company's October 10 Robotaxi day event, TSLA stock price dropped by 8.8% — from $238 to $217.",
        "source_name": "Finbold",
        "date": "Tue, 22 Oct 2024 08:20:31 -0400",
        "topics": [],
        "sentiment": "Negative",
        "type": "Article",
        "tickers": [
            "TSLA"
        ]
    },
    {
        "news_url": "https://www.benzinga.com/trading-ideas/technicals/24/10/41449625/tesla-or-google-who-will-steer-the-future-of-self-driving?utm_source=snapi",
        "image_url": "https://cdn.snapi.dev/images/v1/k/t/w/data-internet30-2708106.jpg",
        "title": "Tesla Or Google? Who Will Steer The Future Of Self-Driving?",
        "text": "In the autonomous vehicle battle, Tesla Inc TSLA and Alphabet Inc‘s GOOGL GOOG Google's Waymo are squaring off to dominate the roads, but each is taking a radically different path.",
        "source_name": "Benzinga",
        "date": "Tue, 22 Oct 2024 08:11:02 -0400",
        "topics": [
            "paywall"
        ],
        "sentiment": "Neutral",
        "type": "Article",
        "tickers": [
            "GOOG",
            "GOOGL",
            "TSLA"
        ]
    },
    {
        "news_url": "https://www.accesswire.com/viewarticle.aspx?id=934225&lang=en",
        "image_url": "https://cdn.snapi.dev/images/v1/6/s/g/press3-2708199.jpg",
        "title": "Levi & Korsinsky Investigates Possible Securities Fraud Violations by Tesla, Inc. (TSLA)",
        "text": "NEW YORK, NY / ACCESSWIRE / October 22, 2024 / Levi & Korsinsky notifies investors that it has commenced an investigation of Tesla, Inc. (\"Tesla, Inc.\") (NASDAQ:TSLA) concerning possible violations of federal securities laws. On October 10, 2024, Tesla unveiled the Company's Cybercab self-driving concept car at the Company's \"We, Robot\" event and announced plans to create a fleet of autonomous vehicles and robots, with the goal of starting production before 2027.",
        "source_name": "Accesswire",
        "date": "Tue, 22 Oct 2024 07:30:00 -0400",
        "topics": [
            "PressRelease",
            "Shareholder Alert"
        ],
        "sentiment": "Neutral",
        "type": "Article",
        "tickers": [
            "TSLA"
        ]
    },
    {
        "news_url": "https://www.proactiveinvestors.co.uk/companies/news/1058858?SNAPI",
        "image_url": "https://cdn.snapi.dev/images/v1/e/i/g/tsla46-2681922-2707902.jpg",
        "title": "Why, Robot? Tesla's flashy prototypes can't hide fundamental business concerns",
        "text": "If electric vehicle giant and fledgling humanoid robotics company Tesla Inc (NASDAQ:TSLA) was hoping to resuscitate its flailing share price ahead of tomorrow's third-quarter earnings call, its recent robotaxi unveiling seems to have missed the mark. Jefferies analysts believe Tesla's much-hyped ‘We, Robot' event, during which a beaming Elon Musk earlier this month unveiled a smorgasbord of flashy new prototypes, “mostly fell flat”.",
        "source_name": "Proactive Investors",
        "date": "Tue, 22 Oct 2024 07:17:47 -0400",
        "topics": [],
        "sentiment": "Negative",
        "type": "Article",
        "tickers": [
            "TSLA"
        ]
    },
    {
        "news_url": "https://www.marketwatch.com/story/tesla-earnings-are-the-next-big-thing-for-the-ev-maker-as-it-tries-to-be-magnificent-again-469d2112",
        "image_url": "https://cdn.snapi.dev/images/v1/k/q/z/tsla40-2686035-2707780.jpg",
        "title": "Tesla earnings are the ‘next big thing' for the EV maker as it tries to be magnificent again",
        "text": "Tesla's stock has traded lower after six of its last eight reports, with the average stock price swings of plus or minus 10% in the past eight post-earnings days.",
        "source_name": "Market Watch",
        "date": "Tue, 22 Oct 2024 06:00:00 -0400",
        "topics": [
            "earnings",
            "paylimitwall"
        ],
        "sentiment": "Neutral",
        "type": "Article",
        "tickers": [
            "TSLA"
        ]
    }
]

export default tempResponse