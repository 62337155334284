import { create } from "zustand";

export const userStore = create((set, get) => ({
  userData: null,
  loading: false,
  btoLoading: false,
  btoData: {},
  expirationDatesOfTicker: {},
  socket: null,
  wishlistData: null,
  uniqueString: "start",
  pnlData: {},
  selectedDiscordChannel: null,
  selectedOptionArray: ['SPY', 'AAPL', 'AMD', 'QQQ', 'META'],

  //configs
  savedConfig: {},

  //Model Data Auth
  hasDataBrokerTokens: null,
  dataAuthBroker: null,
  dataAuthBrokerId: null,
  // dataAccountList: null,
  // dataSelectedAccount: null,
  // brokerBalance: null,
  // dataBrokerInfo: null,

  
  //Order Placement Auth
  hasBrokerTokens: null,
  authBroker: null,
  authBrokerId: null,
  accountList: null,
  selectedAccount: null,
  brokerBalance: null,
  brokerInfo: null,

  isPaperTrade: true,



  setUserData: (values) => {
    set({
      userData: { ...values },
    });
  },
  setLoading: (value) => set({ loading: value }),
  setBtoData: (values) =>
    set({
      btoData: { ...get().btoData, ...values },
    }),
  setExpirationDatesOfTicker: (values) =>
    set({
      expirationDatesOfTicker: { ...get().expirationDatesOfTicker, ...values },
    }),
  setBtoLoading: (value) => set({ btoLoading: value }),
  setSocket: (value) => set({ socket: value }),
  setWishListData: (value) => set({ wishlistData: value }),
  setUniqueString: (value) => set({ uniqueString: value }),
  setPnlData: (value) => set({ pnlData: value}),
  setSelectedDiscordChannel: (channel) => set({ selectedDiscordChannel: channel}),
  setSelectedOptionArray: (newArray) => set({ selectedOptionArray: newArray }),

  //Configs
  setSavedConfig: (value) => set({ savedConfig: value }),
  
  //Model Data Auth
  setHasDataBrokerTokens: (value) => set({ hasDataBrokerTokens: value }),
  setDataAuthBroker: (value) => set({ dataAuthBroker: value }),
  setDataAuthBrokerId: (value) => set({ dataAuthBrokerId: value}),
  
  //Order Placement Auth
  setHasBrokerTokens: (value) => set({ hasBrokerTokens: value }),
  setAuthBroker: (value) => set({ authBroker: value }),
  setAuthBrokerId: (value) => set({ authBrokerId: value}),
  setAccountList: (newArray) => set({accountList: newArray}),
  setSelectedAccount: (value) => set({selectedAccount: value}),
  setBrokerBalance: (value) => set({brokerBalance: value}),

  setIsPaperTrade: (value) => set({ isPaperTrade: value }),
  setIsBrokerInfo: (value) => set({ brokerInfo: value })
}));
