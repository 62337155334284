import { useNavigate } from "react-router-dom";
import Loader from './Loader.js';
import logo from "../assets/alertsify.svg";

const DashModal = ({ isOpen }) => {
  const navigate = useNavigate();

  const redirectDash = () => {
    navigate('/dashboard');
  };
  
  if(isOpen === true) return null;

  if(isOpen === null) {
    return (  
      <main class="fixed inset-0 z-50 bg-opacity -50 bg-black /50 text-gray-200 font-sans">
      <div class="relative px-4 min-h-screen md:flex md:items-center md:justify-center">
        <div class="bg-black opacity-25 w-full h-full absolute z-10 inset-0"></div>
        <div class="bg-transparent gray-800 rounded-lg md:max-w-md md:mx-auto p-4 fixed inset-x-0 bottom-0 z-50 mb-4 mx-4 md:relative">
          <div class="md:flex items-center">
            <div class="px-5 md:mt-0 text-center md:text-left">
              <Loader />
            </div>
          </div>
        </div>
      </div>
    </main>
    );
  }

  return (
    <main class="fixed inset-0 z-50 bg-opacity-100 bg-black /50 text-gray-200 font-sans">
      <div class="relative px-4 min-h-screen md:flex md:items-center md:justify-center">
        <div class="bg-black opacity -25 w-full h-full absolute z-10 inset-0"></div>
        <div class="bg-[#111111] gray-800 border border-[#424242] rounded-lg md:max-w-md md:mx-auto p-4 fixed inset-x-0 bottom-0 z-50 mb-4 mx-4 md:relative">
          <div class="md:flex items-center">
            <div class="rounded-full border border-gray-300 flex items-center justify-center w-16 h-16 flex-shrink-0 mx-auto">
              <img src={logo} alt="" />
            </div>
            <div class="mt-4 md:mt-0 md:ml-6 text-center md:text-left">
              <p class="font-bold">Connect a Brokerage Account</p>
              <p class="text-sm text-gray-100 mt-1">A broker account is required to use our service</p>
            </div>
          </div>
          <div class="text-center md:text-right mt-4 md:flex md:justify-end">
            <button class="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 border border-tertiary green-800 bg-gray-950 text-tertiary green-600 rounded-lg font-semibold text-sm md:ml-2 md:order-2" onClick={redirectDash}>Proceed to Dashboard</button>
          </div>
        </div>
      </div>
    </main>
  );
};

export default DashModal;