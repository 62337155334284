import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

const PnLTileMap = (props) => {
  const { pnlMeterType, tradeType, computedTileMatrix } = props;
  const tradingDays = moment.weekdaysShort();



  const getColumnClasses = (column) => {
    const containerBaseClasses = 'flex-1 flex flex-col justify-center rounded-md py-4';
    const containerHoverClasses = 'hover:drop-shadow-md transition transform hover:-translate-y-1 motion-reduce:transition-none motion-reduce:hover:transform-none';
    const containerProfitClasses = 'bg-emerald-900';
    const containerLossClasses = 'bg-red-950';
    
    if (column === null) {
      return containerBaseClasses;
    }

    if (column[tradeType].pnl > 0) {
      return `${containerBaseClasses} ${containerProfitClasses} ${containerHoverClasses}`;
    }

    if (column[tradeType].pnl < 0) {
      return `${containerBaseClasses} ${containerLossClasses} ${containerHoverClasses}`;
    }

    return containerBaseClasses;
  }

  const getDateLabelClasses = (column) => {
    const dateLabelBaseClasses = 'inline-block text-white px-1 text-center text-xl font-extralight';
    const dateIsTopClasses = 'bg-neutral-600 rounded-full';
    
    if (column.isToday) {
      return `${dateLabelBaseClasses} ${dateIsTopClasses}`;
    }

    return dateLabelBaseClasses;
  }

  const getPnLLableClasses = (column) => {
    const pnlLabelBaseClasses = 'text-xl font-semibold';
    const pnlNoProfitNoLossClasses = 'text-gray-100';
    const pnlIsProfitClasses = 'text-emerald-400';
    const pnlIsLossClasses = 'text-red-400';
    
    if (column[tradeType].pnl > 0) {
      return `${pnlLabelBaseClasses} ${pnlIsProfitClasses}`;
    }

    if (column[tradeType].pnl < 0) {
      return `${pnlLabelBaseClasses} ${pnlIsLossClasses}`;
    }

    return `${pnlLabelBaseClasses} ${pnlNoProfitNoLossClasses}`;
  }

  return (
    <div className='flex-1'>

      <div className='flex justify-between py-4 gap-1'>
        {tradingDays.map((day, index) => (<div className='flex flex-1 justify-center font-xl text-white uppercase' key={index}>{day}</div>))}
      </div>

      <div className='flex flex-col justify-between gap-1'>
        {computedTileMatrix.map((columns, oIndex) => (
          <div key={oIndex} className='flex flex-row justify-between gap-1'>

            {columns.map((column, iIndex) => (
              <div key={iIndex} className={getColumnClasses(column)}>
                {column != null ? (
                  <div className='flex flex-col'>
                    <div className="flex justify-center mb-2">
                      <span className={getDateLabelClasses(column)}>
                        {`${moment(column.date).format('DD')}`}
                      </span>
                    </div>
                    <div className="flex justify-center">
                      <span className={getPnLLableClasses(column)}>
                        {/* TODO: formatting in case of pnl value. */}
                        {pnlMeterType === 'percentage' ? `${column[tradeType].pnlPercentage}%` : `$${column[tradeType].pnl}`}
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className='flex flex-col justify-center'>
                    <div className='text-gray-100 px-1 text-center text-xl font-light'></div>
                  </div>
                )}
              </div>
            ))}

          </div>
        ))}
      </div>

    </div>
  )
}

PnLTileMap.propTypes = {
  pnlMeterType: PropTypes.oneOf(['percentage', 'pnl']).isRequired,
  tradeType: PropTypes.oneOf(['realTrades', 'paperTrades']).isRequired,
  computedTileMatrix: PropTypes.arrayOf(PropTypes.array).isRequired,
}

export default PnLTileMap