import { BsStar, BsStarFill } from "react-icons/bs";

export const Testimonial3 = () => {
  return (
    <div className="text-white bg-[#141414] border border-[#292929] rounded-3xl lg:p-12 p-4 flex flex-col gap-y-8 h-full w-full">
      <div className="">
        <div></div>
        <div className="flex gap-x-4">
          <img className="w-12 h-12 rounded-full" src="/user.png" />
          <div>
            <h6 className="lg:text-xl text-base font-bold">Samuel</h6>

            <div className="flex text-xs text-[#BFBFBF] font-light gap-x-2">
              <p>Operations Lead at</p>
              <span>NexSolve</span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-y-4">
        <p className="md:text-2xl text-lg  font-bold">
          “Using Alertify has made project management effortless for our growing team”
        </p>
        <p className="md:text-base text-xs font-light text-[#BFBFBF]">
        The platform has been a lifesaver for keeping track of tasks and deadlines, even with remote teams. It’s intuitive, reliable, and exactly what we needed to scale our operations.
        </p>
      </div>
      <div className="flex flex-col gap-y-3">
        <div className="flex items-center gap-2 w-fit rounded-full px-4 py-1 text-[#A4F720] bg-[#A4F72014] border border-[#A4F7201F]">
          <BsStarFill />
          <div className="lg:text-sm text-xs">Favorite Features</div>
        </div>
        <div className="flex gap-x-4">
          <div className="flex flex-col bg-[#1F1F1F] border border-[#292929] rounded-xl p-4 gap-y-2">
            <h6 className="lg:text-base text-sm font-bold ">Task Automation</h6>
            <p className="lg:text-sm text-xs font-light text-[#BFBFBF]">
              Automating repetitive tasks has freed up our team’s time to focus on strategy.
            </p>
          </div>
          <div className="flex flex-col bg-[#1F1F1F] border border-[#292929] rounded-xl p-4 gap-y-2">
            <h6 className="text-base font-bold ">Seamless Integration</h6>
            <p className="text-sm font-light text-[#BFBFBF]">
              We love how easy it is to track invoice payments and send
              reminders.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
