import React, { useEffect, useState } from "react";
import axios from "axios";

import { useNavigate } from "react-router-dom";

import easyAlgo from "../../assets/Images/Home/easyAlgo.png";
import Korvato from "../../assets/Images/Home/Korvato.png";
import StockDad from "../../assets/Images/Home/StockDad.png";
import CryptoGalaxy from "../../assets/Images/Home/CryptoGalaxy.png";
import verified from "../../assets/Images/Home/verified.png";
import community1 from "../../assets/Images/Home/community1.png";
import community2 from "../../assets/Images/Home/community2.png";
import community3 from "../../assets/Images/Home/community3.png";
import progressLine from "../../assets/Images/Home/progressLine.png";
import pricingLogo from "../../assets/Images/Home/pricingLogo.png";
import verified2 from "../../assets/Images/Home/verified2.png";
import rightBracket from "../../assets/icons/home/rightBracket.png";
import rightArrow1 from "../../assets/icons/home/rightArrow1.png";
import rightArrow2 from "../../assets/icons/home/rightArrow2.png";

import model from "../../assets/Images/Home/model.png";
import statistics from "../../assets/Images/Home/stats.png";

import Slider from "react-slick";

import { Testimonial } from "../../components/Testimonial";
import { Testimonial2 } from "../../components/Testimonial2";
import { Testimonial3 } from "../../components/Testimonial3";
import { Testimonial4 } from "../../components/Testimonial4";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "../../customStyles/carousel.css";
import { PiPhone } from "react-icons/pi";
import { Faq } from "../../components/Faq";
import { ContactUs } from "../../components/ContactUs";
import { LandingLeaderBoard } from "../../components/LandingLeaderBoard/LeaderBoard";

const Home = () => {
  const navigate = useNavigate();
  const gentradeToken = localStorage.getItem("gentradeToken");
  const subDomainUrl = localStorage.getItem("baseURL");

  const [leaderboardCategory, setLeaderBoardCategory] = useState("monthly");
  const [paperMonthLeaderboard, setPaperMonthLeaderboard] = useState([]);
  const [paperDayLeaderboard, setPaperDayLeaderboard] = useState([]);

  const [realMonthLeaderboard, setRealMonthLeaderboard] = useState([]);
  const [realDayLeaderboard, setRealDayLeaderboard] = useState([]);

  const [tradeType, setTradeType] = useState("realTrades"); // Toggle between 'realTrades' and 'paperTrades'

  const [openContactUs, setOpenContactUs] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const working = [
    {
      id: 1,
      header: "Sign up with Discord",
      content: "Create an account and choose a plan.",
    },
    {
      id: 2,
      header: "Link your brokerage",
      content:
        "Alertsify links to multiple brokerages allowing you to auto-sync trade opens, modifications and closes.",
    },
    {
      id: 3,
      header: "Send alerts to Discord",
      content:
        "After placing your trade seamlessly send out the alert to your community.",
    },
    {
      id: 4,
      header: "Discord alert",
      content:
        "Alertsify automatically formats your trade to make it easy for your members to execute on the information",
    },
  ];

  useEffect(() => {
    if (subDomainUrl && gentradeToken) {
      navigate("/dashboard");
    }
    (async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/pnl/leaderboard?serverId=${process.env.REACT_APP_DISCORD_SERVER_ID}&useCache=false`
        );

        const responseArray = response.data;

        let responsePaperMonthArray = [];
        let responsePaperDayArray = [];

        responsePaperMonthArray = responseArray.map((item) => {
          return {
            user: item?.user,
            month: item?.paperTrades?.month,
          };
        });

        responsePaperMonthArray.sort((a, b) => {
          return (
            Number(b?.month?.pnlPercentage?.split("%")?.[0]) -
            Number(a?.month?.pnlPercentage?.split("%")?.[0])
          );
        });
        setPaperMonthLeaderboard(responsePaperMonthArray);

        console.log("coming here 1");

        responsePaperDayArray = responseArray.map((item) => {
          return {
            user: item?.user,
            dayPnl: item?.paperTrades?.dayPnl,
          };
        });
        responsePaperDayArray.sort((a, b) => {
          return (
            Number(b?.paperTrades?.dayPnl?.pnlPercentage?.split("%")?.[0]) -
            Number(a?.paperTrades?.dayPnl?.pnlPercentage?.split("%")?.[0])
          );
        });
        setPaperDayLeaderboard(responsePaperDayArray);

        console.log("coming here 2");

        //Real
        // const responseRealMonthArray = [...responseArray];
        // const responseRealDayArray = [...responseArray];
        let responseRealMonthArray = [];
        let responseRealDayArray = [];

        responseRealMonthArray = responseArray.map((item) => {
          return {
            user: item?.user,
            month: item?.realTrades?.month,
          };
        });
        responseRealMonthArray.sort((a, b) => {
          return (
            Number(b?.realTrades?.month?.pnlPercentage?.split("%")?.[0]) -
            Number(a?.realTrades?.month?.pnlPercentage?.split("%")?.[0])
          );
        });
        setRealMonthLeaderboard(responseRealMonthArray);

        console.log("coming here 3");

        responseRealDayArray = responseArray.map((item) => {
          return {
            user: item?.user,
            dayPnl: item?.realTrades?.dayPnl,
          };
        });
        responseRealDayArray.sort((a, b) => {
          return (
            Number(b?.realTrades?.dayPnl?.pnlPercentage?.split("%")?.[0]) -
            Number(a?.realTrades?.dayPnl?.pnlPercentage?.split("%")?.[0])
          );
        });
        setRealDayLeaderboard(responseRealDayArray);

        // console.log("coming here 4", )

        setIsLoading(false);
      } catch (err) {
        console.log(err);
      }
    })();
  }, [gentradeToken, subDomainUrl, navigate]);

  const settings = {
    dots: true,
    arrows: false,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerPadding: "100px",
  };

  return (
    <div className="text-black pb-24 bg-black flex flex-col ">
      {/* first SECTION */}
      <div
        className="sm:w-[100%] sm:mx-auto   relative flex flex-col sm:justify- center pt-[35%] sm:pt-0 sm:-mt-2 bg-cover bg-center z-40 overflow-hidden"
        // style={{ backgroundImage: `url(${heroBg})` }}
      >
        <div className="mt-12 text-heading text-[40px] leading-tight min-[470px]:text-5xl sm:text-6xl md:text-7xl lg:text-[64px] 8xl font-bold text-center lg:leading-[65px] px-2 z-40">
          Give Your Members
          <span
            className="ml-[14px] bg-clip-text text-transparent"
            style={{
              background:
                "linear-gradient(90deg, #A4F720 49.56%, #FDFB00 100%)",
              WebkitBackgroundClip: "text",
              backgroundClip: "text",
              color: "transparent",
            }}
          >
            Instant
          </span>
          <br />
          <span
            className="mr-[14px] bg-clip-text text-transparent"
            style={{
              background:
                "linear-gradient(90deg, #A4F720 49.56%, #FDFB00 100%)",
              WebkitBackgroundClip: "text",
              backgroundClip: "text",
              color: "transparent",
            }}
          >
            Trade Alerts
          </span>
          In Discord
        </div>
        <div className="flex flex-wrap justify-center items-center gap-2 text-body text-center text-[15.27px] min-[470px]:text-[16.04px] sm:text-xl font- medium mt-12 sm:mt-5 mb-12 px-8 sm:w-[450px] md:w-[528px] lg:w-[600px] mx-auto z-40">
          <div className="text-nowrap">Set up Alertsify</div>{" "}
          <img alt="rightArrow1" src={rightArrow1} className="w-4" />{" "}
          <div className="text-nowrap">Enter, modify or exit a trade</div>{" "}
          <img alt="rightArrow2" src={rightArrow2} className="size-4" />{" "}
          <div className="text-nowrap">
            Automatically notify your community.
          </div>
        </div>
        <div className="flex flex-col sm:flex-row pt-16 sm:pt-0 mx-auto sm:mx-0 sm:items-start sm:w-full gap-4 justify-center z-40">
          <button
            onClick={() => setOpenContactUs(true)}
            className="flex gap-2 justify-center items-center ] bg-[#A4F720] tertiary text-black text-sm lg:text-base rounded-full sm:px-7 py-4 px-8 sm:py-3 font-medium text-nowrap"
          >
            {/* <MdDasdhboard size={25} className="" /> */}
            Request A Demo{" "}
            <img
              alt="rightBracket"
              src={rightBracket}
              className="size- 4 ml-2"
            />
          </button>
          <button
            // onClick={() => window.open("https://discord.gg/7fs5xMSyB4")}
            className="bg-[#FFFFFF0F] border border-[#FFFFFF1F] text-white tertiary text-sm lg:text-base rounded-full sm:px-7 py-4 px-8 sm:py-3 font-medium text-nowrap"
          >
            View Pricing
          </button>
        </div>
        <div className="mt-8 flex justify-center">
          <div className="px-4 pt-4 w-full sm:h-[480px] sm:w-[70%] mx-auto">
            <iframe
              title="video"
              className="mx-auto"
              src="https://drive.google.com/file/d/1Z7o938dWv7KtIg-ecrPIg0sPPZqH9f5b/preview"
              width="100%"
              height="100%"
              allow="autoplay"
            ></iframe>
          </div>
        </div>
        <img
          alt="gradient"
          className="absolute bottom-0 z-[-1] lg:px-4 w-full"
          src="gradients/hero.png"
        />
      </div>

      {/* powering 20 */}
      <div className="flex flex-col items-center gap-6 text-white pt-16 relative md:w-[80%] md:mx-auto mx-4">
        <div className="text-xl font-bold">Powering 20+ Whop communities</div>
        <div className="flex justify-between gap-16  text-white p-4 border border-[#FFFFFF29] flex-wrap rounded-2xl">
          <div className="flex gap-4">
            <div className="border border-[#1F1F1F] p-2">
              <img alt="easyAlgo" src={easyAlgo} className="size-8" />
            </div>
            <div className="">
              <div className="flex items-center gap-2 font-medium">
                Easy Algo{" "}
                <img alt="verified" src={verified} className="size-4" />
              </div>
              <div className="text-sm mt-1 text-[#BFBFBF]">3,000+ members</div>
            </div>
          </div>
          <div className="flex gap-4">
            <div className="border border-[#1F1F1F] p-2">
              <img alt="easyAlgo" src={Korvato} className="size-8" />
            </div>
            <div className="">
              <div className="flex items-center gap-2 font-medium">
              Korvato{" "}
                <img alt="verified" src={verified} className="size-4" />
              </div>
              <div className="text-sm mt-1 text-[#BFBFBF]">3,000+ members</div>
            </div>
          </div>
          <div className="flex gap-4">
            <div className="border border-[#1F1F1F] p-2">
              <img alt="easyAlgo" src={StockDad} className="size-8" />
            </div>
            <div className="">
              <div className="flex items-center gap-2 font-medium">
                Stock Dad{" "}
                <img alt="verified" src={verified} className="size-4" />
              </div>
              <div className="text-sm mt-1 text-[#BFBFBF]">3,000+ members</div>
            </div>
          </div>
          <div className="flex gap-4">
            <div className="border border-[#1F1F1F] p-2">
              <img alt="easyAlgo" src={CryptoGalaxy} className="size-8" />
            </div>
            <div className="">
              <div className="flex items-center gap-2 font-medium">
                Crypto Galaxy{" "}
                <img alt="verified" src={verified} className="size-4" />
              </div>
              <div className="text-sm mt-1 text-[#BFBFBF]">3,000+ members</div>
            </div>
          </div>
          <div className="flex gap-4">
            <div className="border border-[#1F1F1F] p-2">
              <img alt="easyAlgo" src={easyAlgo} className="size-8" />
            </div>
            <div className="">
              <div className="flex items-center gap-2 font-medium">
                Easy Algo{" "}
                <img alt="verified" src={verified} className="size-4" />
              </div>
              <div className="text-sm mt-1 text-[#BFBFBF]">3,000+ members</div>
            </div>
          </div>
        </div>
        <img
          alt="gradients"
          className="absolute top-0 z- [-1]"
          src="gradients/hero2.png"
        />
      </div>

      {/* working */}
      <div id="working" className="mt-12 md:mt-24 md:w-[80%] md:mx-auto mx-4">
        <div className="flex flex-col items-center">
          <div className="flex items-center gap-2 w-fit rounded-full px-4 py-1 text-[#A4F720] bg-[#A4F72014] border border-[#A4F7201F]">
            <div className="size-2 bg-[#A4F720] rounded-full" />
            <div className="text-sm">PROCESS</div>
          </div>
        </div>
        <div className="mt-4 text-[48px] leading-none font-bold text-white text-center">
          How It Works
        </div>
        <div className="grid grid-cols-2 grid-rows-2 lg:flex lg:flex-nowrap justify-center bg-center gap-2 md:gap-4 mt-10">
          {working.map((item) => (
            <div
              key={item.id}
              className="bg-[#0A0A0A] border border-[#1F1F1F] py-2 px-4 md:p-6 rounded-2xl md:h-[316px] w-full lg:w-1/4"
            >
              <div
                className="leading-none text-[80px] md:text-[96px] font-black bg-clip-text text-transparent"
                style={{
                  background:
                    "linear-gradient(180deg, rgba(255, 255, 255, 0.44) 13.12%, rgba(255, 255, 255, 0.04) 86.25%)",
                  WebkitBackgroundClip: "text",
                  backgroundClip: "text",
                  color: "transparent",
                }}
              >
                {item.id}
              </div>
              <div className="mt-4 text-base md:text-xl font-bold text-white">
                {item.header}
              </div>
              <div className="mt-2 text-xs md:text-base text-[#BFBFBF] md:pb-12 w-4/5">
                {item.content}
              </div>
            </div>
          ))}
        </div>
        <img
          src={progressLine}
          className="w-[80%] mx-auto mt-4"
          alt="progressLine"
        />
        <div className="w-full flex justify-center mt-4">
          <button
            onClick={() => setOpenContactUs(true)}
            className="flex gap-2 justify-center items-center ] bg-[#A4F720] tertiary text-black text-sm lg:text-base rounded-full sm:px-7 py-4 px-8 sm:py-3 font-medium text-nowrap"
          >
            Request A Demo{" "}
            <img
              alt="rightBracket"
              src={rightBracket}
              className="size- 4 ml-2"
            />
          </button>
        </div>
      </div>

      {/* who it's for */}
      <div className="mt-12 md:mt-24 md:w-[80%] md:mx-auto mx-4">
        <div className="flex flex-col items-center">
          <div className="flex items-center gap-2 w-fit rounded-full px-4 py-1 text-[#A4F720] bg-[#A4F72014] border border-[#A4F7201F]">
            <div className="size-2 bg-[#A4F720] rounded-full" />
            <div className="text-sm">WHO IT'S FOR</div>
          </div>
        </div>
        <div className="mt-4 text-[32px] md:text-[48px] lg:text-[48px] leading-none font-bold text-white text-center">
          For communities who need...
        </div>
        <div className="flex flex-col lg:flex-row md:items-center flex- justify-center bg-center gap-4 mt-10">
          <div className="bg-[#0A0A0A] border border-[#1F1F1F] py-6 px-5 rounded-2xl h- [316px] w-full md:w-3/4 lg:w-1/3">
            <img
              alt="community1"
              src={community1}
              className="w-full h-[248px]"
            />
            <div className="mt-4 text-xl font-bold text-white">
              Alert delays eliminated
            </div>
            <div className="mt-2 text-[#BFBFBF] pb-6 w-4/5">
              Alertsify send outs instant alerts to your community to keep
              everyone up-to-date in real time.
            </div>
          </div>
          <div className="bg-[#0A0A0A] border border-[#1F1F1F] py-6 px-5 rounded-2xl h- [316px] w-full md:w-3/4 lg:w-1/3">
            <img
              alt="community2"
              src={community2}
              className="w-full h-[248px]"
            />
            <div className="mt-4 text-xl font-bold text-white">
              Accuracy in pricing
            </div>
            <div className="mt-2 text-[#BFBFBF] pb-6 w-4/5">
              Market movements are fast, so we make sure the most up-to-date
              information is sent in your trade alert based on real market data.
            </div>
          </div>
          <div className="bg-[#0A0A0A] border border-[#1F1F1F] py-6 px-5 rounded-2xl h- [316px] w-full md:w-3/4 lg:w-1/3">
            <img
              alt="community3"
              src={community3}
              className="w-full h-[248px]"
            />
            <div className="mt-4 text-xl font-bold text-white">
              Compete contract details
            </div>
            <div className="mt-2 text-[#BFBFBF] pb-6 w-4/5">
              Give your members the ability to easily read the full contract
              information for every alert.
            </div>
          </div>
        </div>
      </div>

      {/* leaderboard */}
      <div className="mt-12 md:mt-24 lg:mt-36 md:w-[80%] mx-4 md:mx-auto  bg-[#FFFFFF0A] border border-[#FFFFFF14] md:pt-8 rounded-[24px] flex flex-col gap-y-4">
        <div className="flex lg:flex-row flex-col gap-8  h-full  w-full">
          <div
            className="lg:w-1/2 w-full lg:h-full lg:rounded-tr-[24px] rounded-tr-[24px] relative"
            style={{
              background:
                "linear-gradient(186.7deg, #A4F720 56.35%, #FDFB00 94.74%)",
            }}
          >
            <div
              className="w-full h-full rounded-tr-[24px]  pr-2 pt-2 md:pr-8 md:pt-8"
              style={{
                background:
                  "radial-gradient(303.9% 205.56% at 50% -105.56%, rgba(0, 0, 0, 0) 56.88%, rgba(0, 0, 0, 0.4) 74.99%, #000000 100%)",
              }}
            >
              <LandingLeaderBoard
                leaderboardCategory={leaderboardCategory}
                paperMonthLeaderboard={paperMonthLeaderboard}
                paperDayLeaderboard={paperDayLeaderboard}
                realMonthLeaderboard={realMonthLeaderboard}
                realDayLeaderboard={realDayLeaderboard}
                tradeType={tradeType}
                setTradeType={setTradeType}
                setLeaderBoardCategory={setLeaderBoardCategory}
                isLoading={isLoading}
              />
            </div>
          </div>
          <div className="lg:w-1/2 w-full h-full flex flex-col justify-center p-4">
            <div>
              <div className="">
                <div className="flex items-center gap-2 w-fit rounded-full px-4 py-1 text-[#A4F720] bg-[#A4F72014] border border-[#A4F7201F]">
                  <div className="size-2 bg-[#A4F720] rounded-full" />
                  <div className="text-sm">LEADERBOARDS</div>
                </div>
              </div>
              <div className="mt-4 text-[48px] leading-none font-bold text-white text- center">
                Trading Leaderboards
              </div>

              <div className="mt-4 text-[#BFBFBF] pb- 12 w-4/5">
                Track the stats of all your Discord's members and create fun
                competitions.
              </div>
            </div>
            <div className="flex justify-start">
              <button
                onClick={() => setOpenContactUs(true)}
                className="flex gap-2 justify-center items-center mt-4 bg-[#A4F720] tertiary text-black text-sm lg:text-base rounded-full sm:px-7 py-4 px-8 sm:py-3 font-medium text-nowrap"
              >
                Request A Demo{" "}
                <img
                  alt="rightBracket"
                  src={rightBracket}
                  className="size- 4 ml-2"
                />
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* features */}
      <div
        id="features"
        className="mt-12 md:mt-24 md:w-[80%] p-4 md:p-0 mx-auto flex flex-col lg:flex-row lg:gap-x-4"
      >
        <div className="lg:w-1/2 w-full ">
          <div className="flex justify-between items-center">
            <div className="w-full">
              <div>
                <div className="flex items-center gap-2 w-fit rounded-full px-4 py-1 text-[#A4F720] bg-[#A4F72014] border border-[#A4F7201F]">
                  <div className="size-2 bg-[#A4F720] rounded-full" />
                  <div className="text-sm">FEATURES</div>
                </div>
              </div>
              <div className="w-full flex flex-col md:flex-row items-start md:items-center gap-y-4 md:gap-y-0 justify-between">
                <div className="mt-4 text-[48px] leading-none font-bold text-white text- center">
                  Why Alertsify?
                </div>
                <div className=" flex justify-end">
                  <button
                    onClick={() => setOpenContactUs(true)}
                    className="flex md:hidden gap-2 justify-center items-center ] bg-[#A4F720] tertiary text-black text-sm lg:text-base rounded-full sm:px-7 py-4 px-8 sm:py-3 font-medium text-nowrap"
                  >
                    Request A Demo{" "}
                    <img
                      alt="rightBracket"
                      src={rightBracket}
                      className="size- 4 ml-2"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="flex gap-8  h-[459px] mt-10">
            <div className="w-full h-full fl ex flex- col justify -center">
              <div className="">
                <div className="mt- text-xl font-bold text-white">
                  Instant Alerts
                </div>
                <div className="mt-2 text-[#BFBFBF] pb- 12 w- 4/5">
                  Give your members instant notifications for immediate trading
                  action.
                </div>
              </div>
              <div className="">
                <div className="mt-6 text-xl font-bold text-white">
                  Precision in pricing
                </div>
                <div className="mt-2 text-[#BFBFBF] pb- 12 w- 4/5">
                  Send your members accurate trade info to minimize errors and
                  maximize gains.
                </div>
              </div>
              <div className="">
                <div className="mt-6 text-xl font-bold text-white">
                  Detailed contract information
                </div>
                <div className="mt-2 text-[#BFBFBF] pb- 12 w- 4/5">
                  Alertsify automatically formats the contract details to make
                  sure your members have the info they need.
                </div>
              </div>
              <div className="">
                <div className="mt-6 text-xl font-bold text-white">
                  Seamless Integration
                </div>
                <div className="mt-2 text-[#BFBFBF] pb- 12 w- 4/5">
                  Setting up Alertsify in your community was made to be simple,
                  get started in 5min.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:w-1/2 w-full flex flex-col justify-between gap-y-8 h-[250px] md:h-[400px] lg:h- full">
          <div className="lg:flex hidden w-full justify-end">
            <button
              onClick={() => setOpenContactUs(true)}
              className="flex gap-2 justify-center items-center mt-4 bg-[#A4F720] tertiary text-black text-sm lg:text-base rounded-full sm:px-7 py-4 px-8 sm:py-3 font-medium text-nowrap"
            >
              Request A Demo{" "}
              <img
                alt="rightBracket"
                src={rightBracket}
                className="size- 4 ml-2"
              />
            </button>
          </div>
          <div
            className="w-full h-full rounded-t-[24px] relative "
            style={{
              background:
                "linear-gradient(186.7deg, #A4F720 56.35%, #FDFB00 94.74%)",
            }}
          >
            <div
              className="w-full h-full rounded-t-[24px] absolute top-0 pl-4 pt-4"
              style={{
                background:
                  "radial-gradient(303.9% 205.56% at 50% -105.56%, rgba(0, 0, 0, 0) 56.88%, rgba(0, 0, 0, 0.4) 74.99%, #000000 100%)",
              }}
            >
              <div className="bg-[#0A0A0A] rounded-l-[24px] overflow-hidden">
                <img alt="model" src={model} className="w-full" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* TESTIMONIAL SECTION -------TBd--------  */}
      <div
        id="testimonials"
        className="mt-12 md:mt-24 md:w-[80%] md:mx-auto mx-4"
      >
        <div className="flex justify-between items-center">
          <div className="">
            <div className="">
              <div className="flex items-center gap-2 w-fit rounded-full px-4 py-1 text-[#A4F720] bg-[#A4F72014] border border-[#A4F7201F]">
                <div className="size-2 bg-[#A4F720] rounded-full" />
                <div className="text-sm">Testimonials</div>
              </div>
              <div className="mt-4 text-[48px] leading-none font-bold text-white text- center">
                Don't take it from us
              </div>
            </div>
          </div>
        </div>
        <div className="flex gap-8 h-[459px] mt-10 flex-col lg:flex-row">
          <div className="lg:w-[55%] w-full h-full fl ex flex- col justify -center">
            <Slider {...settings} className="md:h-full ">
              <Testimonial />
              <Testimonial2 />
              <Testimonial3 />
              <Testimonial4 />
            </Slider>
          </div>

          <div
            className="w-[45%] h-[250px] hidden lg:flex md:h-[400px] lg:h-full rounded-t-[24px] relative"
            style={{
              background:
                "linear-gradient(186.7deg, #A4F720 56.35%, #FDFB00 94.74%)",
            }}
          >
            <div
              className="w-full h-full rounded-t-[24px] absolute top-0 pl-4 pt-4 overflow-hidden"
              style={{
                background:
                  "radial-gradient(303.9% 205.56% at 50% -105.56%, rgba(0, 0, 0, 0) 56.88%, rgba(0, 0, 0, 0.4) 74.99%, #000000 100%)",
              }}
            >
              <div className="bg-[#0A0A0A] rounded-l-[24px] overflow-hidden">
                <img alt="statistics" src={statistics} className="w-full" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* pricing */}
      <div
        id="pricing"
        className="lg:mt-24 mt-32 flex flex-col items-center md:w-[80%] md:mx-auto mx-4"
      >
        <div className="flex flex-col items-center">
          <div className="mx-auto flex items-center gap-2 w-fit rounded-full px-4 py-1 text-[#A4F720] bg-[#A4F72014] border border-[#A4F7201F]">
            <div className="size-2 bg-[#A4F720] rounded-full" />
            <div className="text-sm">MEMBERSHIP</div>
          </div>

          <div className="mt-4 text-[48px] leading-none font-bold text-white text-center">
            Community Pricing
          </div>
        </div>
        <div
          className="mt-10 w-full md:w-1/2 lg:w-[34%] relative bg-[#0A0A0A] border border-[#1F1F1F] rounded-[24px] p-6 text-white"
          style={{ backgroundImage: `url(gradients/pricing.png)` }}
        >
          <div className="flex gap-4 z-10">
            <div className="border border-[#1F1F1F] size-12 rounded-lg z-10">
              <img alt="pricingLogo" src={pricingLogo} className="" />
            </div>
            <div className="z-10">
              <div className="flex items-center gap-2 font-medium">
                Whitelabel
                {/* <img src={verified} className="size-4" /> */}
              </div>
              <div className="text-sm mt-1 text-[#BFBFBF]">
                $X,XXX <span className="text-[#BFBFBF]">/ year</span>
              </div>
            </div>
          </div>

          <div className="w-full h-[1px] bg-[#FFFFFF3D] mt-6 z-10" />

          <div className="mt-6 z-10">
            <div className="flex items-center gap-4 z-10">
              <img
                alt="verified2"
                src={verified2}
                className="size-[18px] z-10"
              />
              <div className=" z-10">Community Engagement</div>
            </div>
            <div className="flex items-center gap-4 mt-4 z-10">
              <img
                alt="verified2"
                src={verified2}
                className="size-[18px] z-10"
              />
              <div className=" z-10">Performance Monitoring Tools</div>
            </div>
            <div className="flex items-center gap-4 mt-4 z-10">
              <img
                alt="verified2"
                src={verified2}
                className="size-[18px] z-10"
              />
              <div className=" z-10">Increase Retention & Trust</div>
            </div>
            <div className="flex items-center gap-4 mt-4 z-10">
              <img
                alt="verified2"
                src={verified2}
                className="size-[18px] z-10"
              />
              <div className=" z-10">Customizable Alert Settings</div>
            </div>
            <div className="flex items-center gap-4 mt-4 z-10">
              <img
                alt="verified2"
                src={verified2}
                className="size-[18px] z-10"
              />
              <div className=" z-10">Dedicated Support & Updates</div>
            </div>
          </div>

          <button
            onClick={() => setOpenContactUs(true)}
            className="flex gap-2 w-full justify-center items-center mt-6 bg-[#A4F720] tertiary text-black text-sm lg:text-base rounded-full sm:px-7 py-4 px-8 sm:py-3 font-medium text-nowrap"
          >
            Request A Demo{" "}
            <img
              alt="rightBracket"
              src={rightBracket}
              className="size- 4 ml-2 z-10"
            />
          </button>
          {/* <img
            className="absolute top-0 left-0 object-none rounded-[24px] z-0 [-1] pointer-events-none"
            src="gradients/pricing.png"
          /> */}
        </div>
      </div>

      {/* FAQ SECTION -------TBd--------  */}
      <div className="mt-12 md:mt-24 md:w-[80%] md:mx-auto mx-4 flex flex-col gap-y-4 lg:flex-row items-start">
        <div className="flex justify-between items-center lg:w-1/2 w-full">
          <div className="flex flex-col items-center lg:items-start gap-y-4 w-full">
            <div className="mt-4 text-[48px] leading-none font-bold text-white lg:text-left text-center ">
              Got Questions?
              <p className="text-[#BFBFBF] text-xl font-light my-4">
                Everything you need to know
                <br /> before you become alert
              </p>
            </div>

            <button className="mt- 10 flex gap-2 justify-center items-center w-[180px] bg-[#A4F720] tertiary text-black text-sm lg:text-base rounded-full sm:px-7 p-4 sm:py-3 font-medium text-nowrap">
              <PiPhone />
              Contact Us
            </button>
          </div>
        </div>
        <div className="flex justify-end lg:w-1/2 w-full relative">
          <div
            className="w-full h-full rounded-t-[24px] p-4 bor der "
            style={{
              background:
                "linear-gradient(164.61deg, #A4F720 -29.34%, #000000 24.6%)",
              borderColor:
                "linear-gradient(0deg, #1F1F1F, #1F1F1F), linear-gradient(161.36deg, #A4F720 0%, rgba(164, 247, 32, 0) 40.84%)",
            }}
          >
            <Faq />
          </div>
        </div>
      </div>

      {/* Faq */}
      <div className="flex flex-col items-center mt-12 md:mt-24 md:w-[80%] pb-8 md:mx-auto mx-4 bg-[#0A0A0A] border border-[#1F1F1F]  rounded-3xl">
        <img alt="gradient" className="rounded-3xl" src="gradients/trade.png" />{" "}
        <div className="px-4 md:px-0 text-3xl lg:text-[48px]  leading-none text-white font-bold text-center">
          Get Professional Trading Alerts!
        </div>
        <div className="mt-4 px-4 md:px-0 text-[#BFBFBF] text-center">
          Discover how Alertsify can be tailored to meet your community’s needs.
        </div>
        <button className="flex gap-2  justify-center items-center mt-4 bg-[#A4F720] tertiary text-black text-sm lg:text-base rounded-full sm:px-7 py-4 px-8 sm:py-3 font-medium text-nowrap">
          Book A Demo{" "}
          <img alt="rightBracket" src={rightBracket} className="size- 4 ml-2" />
        </button>
      </div>

      {openContactUs && (
        <ContactUs
          openContactUs={openContactUs}
          setOpenContactUs={setOpenContactUs}
        />
      )}
    </div>
  );
};

export default Home;
